import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ForumCategory, UserAreaOrGroupId } from '../types';
import { AppThunk } from './index';
import { RootState } from './rootReducer';
import { getCategories } from '../api/forum-api';

export interface ForumCategoryState {
  categories: ForumCategory[] | null;
  isLoading: boolean;
  error: string | null;
}

const initialState = {
  categories: null,
  isLoading: false,
  error: null,
} as ForumCategoryState;

function startLoading(state: ForumCategoryState) {
  state.isLoading = true;
}

function loadingFailed(state: ForumCategoryState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const forumCategoriesSlice = createSlice({
  name: 'forumCategories',
  initialState: initialState,
  reducers: {
    getForumCategoriesStart: startLoading,
    getForumCategoriesSuccess(state, action: PayloadAction<ForumCategory[]>) {
      state.categories = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getForumCategoriesFailure: loadingFailed,
  },
});

export const {
  getForumCategoriesStart,
  getForumCategoriesSuccess,
  getForumCategoriesFailure,
} = forumCategoriesSlice.actions;

export default forumCategoriesSlice.reducer;

// Thunks
export const fetchForumCategories = ({
  userAreaId,
  groupId
}: UserAreaOrGroupId): AppThunk => async (dispatch) => {
  try {
    dispatch(getForumCategoriesStart());
    const categories = await getCategories({ userAreaId, groupId });
    dispatch(getForumCategoriesSuccess(categories));
  } catch (err: any) {
    dispatch(getForumCategoriesFailure(err.toString()));
  }
};

// Selectors
const selectForumCategoryState = (rootState: RootState) => rootState.forumCategories;

export const forumCategoriesIsLoadingSelector = createSelector(
  selectForumCategoryState,
  (forumCategoryState: ForumCategoryState) => forumCategoryState.isLoading
);

export const forumCategoriesSelector = createSelector(
  selectForumCategoryState,
  (forumCategoryState: ForumCategoryState) => forumCategoryState.categories
);
