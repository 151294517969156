import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCourseFromSlug,
  updateCourse,
  courseSelector,
  courseIsSavingSelector,
  updateSession,
} from '../../../store/editCourseSlice';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Course, Session, SimplifiedSession } from '../../../types';
import { fetchProviders } from '../../../store/providersSlice';
import { createSession, deleteSession } from '../../../api/course-api';
import AddSessionButton, { AddSession } from './AddSessionButton';
import {
  getEditCourseUrl,
  courseAdminDashboardUrl,
  getEditSessionUrl,
} from '../../../routes/paths';
import EditSessionSubPage from './EditSessionSubpage';
import Message from '../../../components/translation/Message';
import PageOrSectionHeader from '../../../components/typography/PageOrSectionHeader';
import BackButton from '../../../components/BackButton';
import EditIntroductionSubpage from './EditIntroductionSubpage';
import { ArrowBackOutlined } from '@material-ui/icons';
import SortableTextButton from './SortableTextButton';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { allCoursesSimplifiedSelector } from '../../../store/selectors';
import BorrowedSessionSubPage from './BorrowedSessionSubPage';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saveText: {
      position: 'absolute',
      top: theme.spacing(1),
      bottom: theme.spacing(1),
      right: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    sessionLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const EditCoursePage = () => {
  const course = useSelector(courseSelector);
  const isSaving = useSelector(courseIsSavingSelector);
  const allCourses = useSelector(allCoursesSimplifiedSelector);
  const [isEditingSessionOrder, setEditingSessionOrder] = useState(false);
  const [draggingSession, setDraggingSession] = useState<Session | null>(null);
  const [updating, setUpdating] = useState(false);
  const [items, setItems] = useState<Session[]>([]);
  const [order, setOrder] = useState<SimplifiedSession[]>([]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  let params: { slug: string; id: string } = useParams();
  let currentSession = course
    ? course.sessions.find((s) => s.id === params.id)
    : null;

  useEffect(() => {
    if (course === null || course.slug !== params.slug) {
      dispatch(fetchCourseFromSlug(params.slug));
      dispatch(fetchProviders());
    } else {
      if (course?.sessionOrder) {
        setOrder(course?.sessionOrder);
      }
      setItems(
        course ? [...course.sessions].sort((a, b) => a.index - b.index) : []
      );
    }
  }, [course, dispatch, params.slug]);

  const handleAddSession = ({ name, type }: AddSession) => {
    const courseToUpdate = course as Course;
    type CreateSession = Omit<Session, 'id'>;
    const sessionToAdd: CreateSession = {
      name,
      type,
      index: courseToUpdate.sessions.length,
      length: 0,
      documents: [],
      formattedText: '',
      vimeoUrl: '',
      liveStreamUrl: '',
      isFileDownloadable: true,
      questionAnswers: [],
      minimumPoints: 0,
      maximumPoints: 0,
      usedInCourses: [],
    };
    createSession(courseToUpdate.id, sessionToAdd).then((session) => {
      dispatch(
        updateCourse({
          ...courseToUpdate,
          sessions: [...courseToUpdate.sessions, session],
          sessionOrder: [
            ...(courseToUpdate.sessionOrder ?? []),
            { id: session.id, name: session.name },
          ],
        })
      );
    });
  };

  const isSessionBorrowed = (session: Session) => {
    if (course && session) {
      return session.usedInCourses?.includes(course.id);
    }
    return false;
  };

  const courseHasSessionIds = () => {
    return course?.sessionOrder && course?.sessionOrder?.length > 0;
  };

  const getBorrowedCourseSimplified = (sessionCourse: Session) => {
    if (allCourses && sessionCourse && sessionCourse.courseId) {
      return allCourses.find((q) => q.id === sessionCourse.courseId);
    }
  };

  const handleDeleteSession = (sessionId: string) => {
    const courseToUpdate = course as Course;
    deleteSession(sessionId).then(() => {
      dispatch(
        updateCourse({
          ...courseToUpdate,
          sessions: courseToUpdate.sessions.filter((s) => s.id !== sessionId),
          sessionOrder: courseToUpdate.sessionOrder?.filter(
            (s) => s.id !== sessionId
          ),
        })
      );
    });
    history.push(getEditCourseUrl(courseToUpdate.slug));
  };

  const handleDisconnectSession = (session: Session) => {
    const courseToUpdate = course as Course;
    const sessionToUpdate: Session = session;
    dispatch(
      updateSession({
        ...sessionToUpdate,
        usedInCourses: sessionToUpdate.usedInCourses?.filter(
          (c) => c !== courseToUpdate.id
        ),
      })
    );
    dispatch(
      updateCourse({
        ...courseToUpdate,
        sessions: courseToUpdate.sessions.filter((s) => s.id !== session.id),
        sessionOrder: courseToUpdate.sessionOrder?.filter(
          (s) => s.id !== session.id
        ),
      })
    );
  };

  const handleSortingEnd = async () => {
    const itemsToSave = order.map((item) => ({ id: item.id, name: item.name }));
    let courseToUpdate = course as Course;
    setUpdating(true);
    dispatch(
      updateCourse({
        ...courseToUpdate,
        sessionOrder: itemsToSave,
      })
    );

    setEditingSessionOrder(false);
    setUpdating(false);
  };

  const handleDragStart = (event: DragStartEvent) => {
    if (course) {
      const { active } = event;
      const sesh = course.sessions.find((s) => s.id === active.id);
      if (sesh) {
        setDraggingSession(sesh);
      }
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setOrder((order) => {
        const oldIndex = order.findIndex((item) => item.id === active.id);
        const newIndex = order.findIndex((item) => item.id === over.id);

        return arrayMove(order, oldIndex, newIndex);
      });
    }
    setDraggingSession(null);
  };
  useEffect(() => {}, [items]);

  if (!course || !allCourses) {
    return <CircularProgress size={64} />;
  }

  return (
    <Box
      p={4}
      display="flex"
      justifyContent="space-between"
      position="relative"
      height="auto"
    >
      <Typography className={classes.saveText}>
        {isSaving ? 'Saving...' : 'All changes are saved'}
      </Typography>

      <Box width={216} height="auto" mr={4} pr={2} borderRight="1px solid grey">
        <Box pb={2}>
          <BackButton
            linkTo={courseAdminDashboardUrl}
            messageId={'course.back'}
          />
        </Box>
        <Link
          to={getEditCourseUrl(course.slug)}
          className={classes.sessionLink}
        >
          <Typography
            variant="h5"
            component="h2"
            color={currentSession ? 'initial' : 'primary'}
          >
            <Message id="course.courseAdmin.editCoursePage.introduction" />
          </Typography>
        </Link>
        {course && course.sessions.length > 0 && (
          <>
            <Link
              to={`${getEditSessionUrl(course.slug, course.sessions[0].id)}`}
              className={classes.sessionLink}
            >
              <PageOrSectionHeader>
                <Message id="course.courseAdmin.editCoursePage.sessions" />
              </PageOrSectionHeader>
            </Link>
            <Box
              mb={2}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
            >
              {!updating &&
                !isEditingSessionOrder &&
                course.sessions.length > 1 && (
                  <Button
                    onClick={() => setEditingSessionOrder(true)}
                    color="primary"
                  >
                    <Message id="course.courseAdmin.editCoursePage.editOrder" />
                  </Button>
                )}
              {!updating &&
                isEditingSessionOrder &&
                course.sessions.length > 1 && (
                  <Button onClick={() => handleSortingEnd()} color="primary">
                    <Message id="course.courseAdmin.editCoursePage.done" />
                  </Button>
                )}
              {updating && (
                <CircularProgress size={18} style={{ marginLeft: 4 }} />
              )}
            </Box>
          </>
        )}
        {course && course.sessions.length === 0 && (
          <PageOrSectionHeader>
            <Message id="course.courseAdmin.editCoursePage.sessions" />
          </PageOrSectionHeader>
        )}
        <Box mb={8}>
          {course && isEditingSessionOrder && course.sessionOrder && (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={order}>
                {order.map((session) => (
                  <SortableTextButton id={session.id} name={session.name} />
                ))}
              </SortableContext>
              <DragOverlay>
                {draggingSession ? (
                  <Button endIcon={<ArrowBackOutlined />}>
                    {draggingSession.name}
                  </Button>
                ) : null}
              </DragOverlay>
            </DndContext>
          )}

          {course &&
            !isEditingSessionOrder &&
            courseHasSessionIds &&
            course.sessionOrder?.map((session) => {
              let sessionOfSimplified = course.sessions.find(
                (c) => c.id === session.id
              );

              if (isSessionBorrowed(sessionOfSimplified!)) {
                return (
                  <Box display={'flex'}>
                    <Link
                      to={`${getEditSessionUrl(course.slug, session.id)}`}
                      key={session.id}
                      className={classes.sessionLink}
                    >
                      <Typography
                        variant="body1"
                        gutterBottom
                        color={
                          session.id === currentSession?.id
                            ? 'primary'
                            : 'initial'
                        }
                      >
                        {session.name}
                      </Typography>
                    </Link>
                    <LinkRoundedIcon
                      color={'inherit'}
                      style={{ marginLeft: '4pt' }}
                    ></LinkRoundedIcon>
                  </Box>
                );
              } else {
                return (
                  <Link
                    to={`${getEditSessionUrl(course.slug, session.id)}`}
                    key={session.id}
                    className={classes.sessionLink}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      color={
                        session.id === currentSession?.id
                          ? 'primary'
                          : 'initial'
                      }
                    >
                      {session.name}
                    </Typography>
                  </Link>
                );
              }
            })}

          {course &&
            !isEditingSessionOrder &&
            !courseHasSessionIds &&
            items.map((session) => {
              if (isSessionBorrowed(session)) {
                return (
                  <Box display={'flex'}>
                    <Link
                      to={`${getEditSessionUrl(course.slug, session.id)}`}
                      key={session.id}
                      className={classes.sessionLink}
                    >
                      <Typography
                        variant="body1"
                        gutterBottom
                        color={
                          session.id === currentSession?.id
                            ? 'primary'
                            : 'initial'
                        }
                      >
                        {session.name}
                      </Typography>
                    </Link>
                    <LinkRoundedIcon
                      color={'inherit'}
                      style={{ marginLeft: '4pt' }}
                    ></LinkRoundedIcon>
                  </Box>
                );
              } else {
                return (
                  <Link
                    to={`${getEditSessionUrl(course.slug, session.id)}`}
                    key={session.id}
                    className={classes.sessionLink}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      color={
                        session.id === currentSession?.id
                          ? 'primary'
                          : 'initial'
                      }
                    >
                      {session.name}
                    </Typography>
                  </Link>
                );
              }
            })}
        </Box>
        <AddSessionButton onAdd={handleAddSession} courses={allCourses} />
      </Box>

      {currentSession && isSessionBorrowed(currentSession) && (
        <Box flexGrow={1} maxWidth="80%">
          <BorrowedSessionSubPage
            session={currentSession}
            course={getBorrowedCourseSimplified(currentSession)}
            onDisconnectSession={handleDisconnectSession}
          />
        </Box>
      )}

      {currentSession && !isSessionBorrowed(currentSession) && (
        <Box flexGrow={1} maxWidth="80%">
          <EditSessionSubPage
            session={currentSession}
            onDeleteSession={handleDeleteSession}
          />
        </Box>
      )}

      {!currentSession && (
        <Box flexGrow={1} maxWidth="80%">
          <EditIntroductionSubpage course={course} />
        </Box>
      )}
    </Box>
  );
};

export default EditCoursePage;
