import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { allCoursesSimplifiedSelector } from '../../../store/selectors';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import CustomChip from '../../../components/CustomChip';

import {
  courseSelector,
  updateCourse,
  updateSession,
} from '../../../store/editCourseSlice';
import { Course, Session, SimplifiedCourse } from '../../../types';
import Message from '../../../components/translation/Message';
import { getCourseFromSlug } from '../../../api/course-api';
import { fetchAllExamCoursesSimplified } from '../../../store/coursesSlice';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';

const useStyles = makeStyles((theme) => ({
  autocompleteInput: {
    '& input': {
      padding: '12px !important',
    },
  },
}));

const AddExistingSessionDialog = () => {
  const course = useSelector(courseSelector);
  const simplifiedCourses = useSelector(allCoursesSimplifiedSelector);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [parentCourse, setParentCourse] = useState<SimplifiedCourse>();
  const [parentSession, setParentSession] = useState<Session>();
  const [parentFullCourse, setParentFullCourse] = useState<Course>();

  const classes = useStyles();

  useEffect(() => {
    if (parentCourse && parentSession) {
      dispatch(fetchAllExamCoursesSimplified());
    }
  }, [dispatch, parentCourse, parentSession]);

  const parentCourseChanged = async (newCourse: SimplifiedCourse | null) => {
    if (newCourse) {
      setParentCourse(newCourse);
      // Get course/slug/newValue
      const courseFromSlug = await getCourseFromSlug(newCourse.slug);
      setParentFullCourse(courseFromSlug);
    }
  };

  const parentSessionChanged = (newSession: Session | null) => {
    if (newSession && parentCourse && course) {
      setParentSession(newSession);
    }
  };

  // Handle save, should update the session object's UsedInCourses with the id of the course you are currently in
  const handleSave = () => {
    if (
      parentSession &&
      parentCourse &&
      course &&
      !parentSession.usedInCourses?.includes(course.id)
    ) {
      const updatedSession = parentSession;
      const courseToUpdate = course as Course;
      updatedSession.usedInCourses?.push(course?.id);
      dispatch(updateSession(updatedSession));
      dispatch(
        updateCourse({
          ...courseToUpdate,
          sessionOrder: [
            ...(courseToUpdate.sessionOrder ?? []),
            { id: parentSession.id, name: parentSession.name },
          ],
        })
      );
    }
    handleClose();
  };

  const handleClose = () => {
    setParentSession(undefined);
    setParentCourse(undefined);
    setParentFullCourse(undefined);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} color="primary">
        <Message id={'course.courseAdmin.editCoursePage.connectSession'} />
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Box
          height="30vh"
          pt={4}
          pl={5}
          pr={5}
          display="flex"
          flexDirection="column"
        >
          <DialogTitle>
            <Message id={'course.courseAdmin.editCoursePage.connectOrder'} />
          </DialogTitle>
          <DialogContent>
            <Box mt={'10pt'}>
              <Message
                id={'course.courseAdmin.editCoursePage.availableCourses'}
              />
              <Autocomplete
                id="roleFilter"
                options={simplifiedCourses?.map((a) => a) ?? []}
                getOptionLabel={(option) => option.name}
                value={parentCourse}
                onChange={(e, newVal) => {
                  if (newVal) {
                    parentCourseChanged(newVal);
                  } else {
                    parentCourseChanged(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    classes={{ root: classes.autocompleteInput }}
                    required
                    placeholder={'Välj kurs'}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <CustomChip
                      {...getTagProps({ index })}
                      label={option.name}
                    />
                  ));
                }}
              />
            </Box>

            <Box mt={'10pt'} hidden={!parentCourse}>
              <Message
                id={'course.courseAdmin.editCoursePage.availableSessions'}
              />
              <Autocomplete
                id="roleFilter"
                options={
                  parentFullCourse?.sessions.filter(
                    (a) => !a.usedInCourses?.includes(course!.id)
                  ) ?? []
                }
                getOptionLabel={(option) => `${option.name}`}
                value={parentSession}
                onChange={(e, newVal) => {
                  if (newVal) {
                    parentSessionChanged(newVal);
                  } else {
                    parentSessionChanged(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    classes={{ root: classes.autocompleteInput }}
                    required
                    placeholder={`${getMessage(
                      'course.courseAdmin.editCoursePage.chooseSessionInCourse'
                    )}: ${parentCourse?.name}`}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <CustomChip
                      {...getTagProps({ index })}
                      label={option.name}
                    />
                  ));
                }}
              />
            </Box>
          </DialogContent>
        </Box>

        <DialogActions>
          <Box>
            <Button onClick={handleClose} color="primary">
              <Message id="handleUser.cancel" />
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              disabled={!parentSession}
            >
              <Message id={'course.courseAdmin.editCoursePage.chooseSession'} />
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddExistingSessionDialog;
