import {
  Button,
  Theme,
  createStyles,
  makeStyles,
  PropTypes,
} from '@material-ui/core';
import { ReactNode, forwardRef } from 'react';
import {
  fetchFilteredCourses,
  setCurrentCourseType,
} from '../../store/editCourseSlice';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useDispatch } from 'react-redux';
import { CourseType } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    courseTypeBtn: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

type CourseTypeButtonProps = {
  courseType: CourseType;
  color?: PropTypes.Color | undefined;
  style?: any;
  children?: ReactNode;
};

export const CourseTypeButton = forwardRef(
  ({ ...props }: CourseTypeButtonProps, ref) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
      <Button
        key={props.courseType.id}
        variant="contained"
        endIcon={!props.courseType.isActive && <VisibilityOffOutlinedIcon />}
        className={classes.courseTypeBtn}
        onClick={() => {
          dispatch(fetchFilteredCourses(props.courseType.id));
          dispatch(setCurrentCourseType(props.courseType));
        }}
        {...props}
      >
        {props.courseType.name}
      </Button>
    );
  }
);
