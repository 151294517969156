import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import { getSessionDocument } from '../../api/course-api';
import DocumentUploader from './DocumentUploader';
import Message from '../translation/Message';
import { Session, Document, isAdmin, isSuperAdmin } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import { userSelector } from '../../store/userSlice';
import { useSelector } from 'react-redux';
import { Backspace } from '@material-ui/icons';
import DeleteButton from '../DeleteButton';
import DocumentHistory from './DocumentHistory';
import { DocumentIcon } from './DocumentFileType';

const defaultProps = {
  bgcolor: 'secondary.main',
  borderColor: 'text.primary',
  p: 1,
  border: 1,
  flex: true,
  width: 'fit-content',
};

type DocumentSectionProps = {
  session: Session;
  onDocumentUploadFinish: (
    document: Document,
    isFileDownloadable: boolean
  ) => void;
  onDeleteAllDocuments: () => void;
  onDeleteCurrentDocument: () => void;
  isFileDownloadable: boolean;
  onChangeDownloadable: (isDownloadable: boolean) => void;
};
const DocumentSection = ({
  session,
  onDocumentUploadFinish,
  onDeleteAllDocuments,
  onDeleteCurrentDocument,
  isFileDownloadable,
  onChangeDownloadable,
}: DocumentSectionProps) => {
  const [file, setFile] = useState<Blob | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [latestDocument, setLatestDocument] = useState<Document | null>(
    session.documents[0]
  );

  const user = useSelector(userSelector);

  useEffect(() => {
    const getFile = async (documentId: string | undefined) => {
      setIsLoading(true);
      const doc = await getSessionDocument(session.id, documentId);
      setFile(doc);
      setIsLoading(false);
    };

    if (session.documents.length > 0) {
      setLatestDocument(session.documents[session.documents.length - 1]);
      getFile(latestDocument?.id);
    }
  }, [session, latestDocument]);

  const handleDownloadableChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeDownloadable(event.target.checked);
  };

  const handleUploadFinish = (document: Document) => {
    onDocumentUploadFinish(document, isFileDownloadable);
  };

  const getFileExtension = (name: string): string => {
    const nameSplit = name.split('.');
    return nameSplit[nameSplit.length - 1];
  };

  return (
    <Box mt={4} mb={6}>
      <Typography variant="subtitle2">
        <Message id="course.courseAdmin.editCoursePage.documents" />
      </Typography>
      {user && (
        <Box mt={1} mb={2} display="flex">
          <DocumentUploader
            session={session}
            onUploadFinish={handleUploadFinish}
            hasUpload={session.documents.length > 0}
            isFileDownloadable={isFileDownloadable}
            uploaderName={user?.name}
          />
          <Box mr={2} />
          {session.documents.length > 0 &&
            latestDocument != null &&
            isAdmin(user) &&
            isSuperAdmin(user) && (
              <Box display="flex">
                <Box mr={2}>
                  <DeleteButton
                    itemCategory={getMessage(
                      'course.courseAdmin.editCoursePage.deleteDocument'
                    )}
                    itemName={latestDocument?.name}
                    onDelete={onDeleteCurrentDocument}
                    button={
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Backspace />}
                        disabled={!isSuperAdmin(user)}
                      >
                        <Message
                          id={
                            'course.courseAdmin.editCoursePage.deleteLatestDocument'
                          }
                        />
                      </Button>
                    }
                  ></DeleteButton>
                </Box>

                <Box mr={2}>
                  <DeleteButton
                    itemCategory={getMessage(
                      'course.courseAdmin.editCoursePage.deleteAllDocuments'
                    )}
                    itemName={session.documents.map((d) => d.name).join(', ')}
                    onDelete={onDeleteAllDocuments}
                    button={
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        disabled={!isSuperAdmin(user)}
                      >
                        <Message id="course.courseAdmin.editCoursePage.deleteAllDocuments" />
                      </Button>
                    }
                  ></DeleteButton>
                </Box>
              </Box>
            )}
        </Box>
      )}
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={isFileDownloadable}
              onChange={handleDownloadableChange}
              name="download-document"
              color="primary"
            />
          }
          label={getMessage(
            'course.courseAdmin.editCoursePage.documentDownloadable'
          )}
        />
      </Box>
      <Box>
        {isLoading && (
          <Box mb={1}>
            <Alert severity="info">
              <Message
                id={'course.courseAdmin.editCoursePage.bigDocumentInfo'}
              />
            </Alert>
          </Box>
        )}
      </Box>

      {session.documents.length > 0 && !isLoading && file && latestDocument && (
        <Box mb={4} borderRadius={16} {...defaultProps}>
          {DocumentIcon(getFileExtension(latestDocument?.name))(
            latestDocument,
            file,
            null,
            session.name
          )}
        </Box>
      )}
      {session.documents.length > 0 && isLoading && (
        <CircularProgress size={64} />
      )}
      {file && latestDocument && session.documents.length >= 1 && (
        <Box>
          <DocumentHistory session={session}></DocumentHistory>
        </Box>
      )}
      {session.documents.length === 0 && (
        <Box>
          <Typography>
            <Message id="course.courseAdmin.editCoursePage.noDocument" />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DocumentSection;
