import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../types';
import { AppThunk } from './index';
import { RootState } from './rootReducer';
import {
    getNotifications as getNotificationsAPI,
    deleteNotification as deleteNotificationAPI,
    updateAsOpenedNotifications as updateAsOpenedNotificationsAPI
} from '../api/notification-api';

export interface NotificationsState {
    notifications: Notification[] | null;
    isLoadingNotifications: boolean;
    error: string | null;
}

const initialState = {
    notifications: null,
    isLoadingNotifications: false,
    error: null,
} as NotificationsState;

function startLoading(state: NotificationsState) {
    state.isLoadingNotifications = true;
}

function stopLoading(state: NotificationsState) {
    state.isLoadingNotifications = false;
}

function loadingFailed(state: NotificationsState, action: PayloadAction<string>) {
    state.isLoadingNotifications = false;
    state.error = action.payload;
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        getNotificationsStart: startLoading,
        getNotificationsStop: stopLoading,
        getNotificationsSuccess(state, action: PayloadAction<Notification[]>) {
            state.notifications = action.payload;
            state.error = null;
            state.isLoadingNotifications = false;
        },
        getNotificationsFailure: loadingFailed,
        removeNotification(state: NotificationsState, action: PayloadAction<Notification>) {
            if (!state.notifications) { return }
            state.notifications = state.notifications.filter((notification) => notification.id !== action.payload.id)
        },
        removeNotificationsForReferenceId(state: NotificationsState, action: PayloadAction<string>) {
            if (!state.notifications) { return }
            state.notifications = state.notifications.filter((notification) => notification.referenceId !== action.payload)
        }
    },
});

export const {
    getNotificationsStart,
    getNotificationsStop,
    getNotificationsSuccess,
    getNotificationsFailure,
    removeNotification,
    removeNotificationsForReferenceId,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

// Thunks
export const fetchNotifications = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(getNotificationsStart());
        const notifications = await getNotificationsAPI();

        const activeModules = getState().instance.instance?.activeModules;
        const instanceIsLoading = getState().instance.updateInstanceLoading;
        if (!instanceIsLoading && activeModules && activeModules?.includes('Notification')) {
            dispatch(getNotificationsSuccess(notifications));
        } else {
            dispatch(getNotificationsStop());
        }
    } catch (err: any) {
        dispatch(getNotificationsFailure(err.toString()));
    }
};

export const deleteNotification = (notification: Notification): AppThunk => async (dispatch) => {
    try {
        const removedNotification = await deleteNotificationAPI(notification.id);
        dispatch(removeNotification(removedNotification));
        dispatch(fetchNotifications());
    } catch (err: any) {
        console.error(err);
    }
};

export const updateAsOpenedNotifications = (notificationIds: string[]): AppThunk => async (dispatch) => {
    try {
        const updatedNotifications = await updateAsOpenedNotificationsAPI(notificationIds);
        dispatch(getNotificationsSuccess(updatedNotifications));
        dispatch(fetchNotifications());
    } catch (err: any) {
        console.error(err);
    }
};

// Selectors
const selectNotificationsState = (rootState: RootState) => rootState.notifications;

export const notificationsIsLoadingSelector = createSelector(
    selectNotificationsState,
    (notificationsState: NotificationsState) => notificationsState.isLoadingNotifications
);

export const notificationsSelector = createSelector(
    selectNotificationsState,
    (notificationsState: NotificationsState) => notificationsState.notifications
);
