import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Button,
  InputLabel,
  Switch,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import {
  UpdateInstance,
  toUpdateInstance,
  Module,
  SubModule,
} from '../../types';
import Message from '../../components/translation/Message';
import {
  updateInstance,
  instanceSelector,
  instanceUpdateLoading,
} from '../../store/instanceSlice';
import isEqual from 'lodash/isEqual';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';
import SaveDialog from './SaveDialog';
import TextInput from './TextInput';
import ModuleInfoDialog from './ModuleInfoDialog';
import InfoIcon from '../../components/icons/InfoIcon';
import { getModuleIcon } from '../../components/icons/moduleIcons';
import LimitsInfo from './LimitsInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wideLogo: {
      height: 80,
      objectFit: 'contain',
      marginTop: theme.spacing(2),
    },
    imageBox: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '600px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column',
        '& > div': {
          marginBottom: theme.spacing(4),
        },
      },
      '& > div': {
        paddingLeft: theme.spacing(1),
      },
    },
    upploadButton: {
      width: '25ch',
    },
    saveSettingsBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      '& > button': {
        marginLeft: '10px',
      },
    },
    moduleSection: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
    },
  })
);

const defaultInstance = {
  name: '',
  activeModules: [],
  activeSubModules: {
    Forum: [],
    Course: [],
    External: [],
    Notification: [],
    Chat: [],
  },
  externalLinks: {
    MainPageLink: '',
    SupportEmail: '',
  },
  openRegistration: true,
  metaDescription: '',
  chatApplicationData: '',
};

const SiteSettingsPage = () => {
  const instance = useSelector(instanceSelector);
  const updateInstanceLoading = useSelector(instanceUpdateLoading);
  const [newInstance, setNewInstance] =
    useState<UpdateInstance>(defaultInstance);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [infoModule, setInfoModule] = useState<string | null>(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (instance) {
      const getEmptyActiveSubModules = (): Record<Module, SubModule[]> => {
        let structuredActiveSubModules: any = {};
        if (instance && instance.availableModules) {
          Object.keys(instance.availableModules).map((module) => {
            return (structuredActiveSubModules[module] = []);
          });
        }
        return structuredActiveSubModules;
      };

      setNewInstance({
        name: instance.name,
        activeModules: instance.activeModules,
        activeSubModules:
          !instance.activeSubModules ||
          Object.keys(instance.activeSubModules).length === 0
            ? getEmptyActiveSubModules()
            : { ...instance.activeSubModules },
        externalLinks: { ...instance.externalLinks },
        openRegistration: instance.openRegistration,
        metaDescription: instance.metaDescription,
      });
    }
  }, [instance]);

  const handleOpenReg = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewInstance({
      ...newInstance,
      openRegistration: (event.target as HTMLInputElement).checked,
    });
  };

  const handleModules = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = (event.target as HTMLInputElement).value;

    let newActiveModules = [...newInstance.activeModules];

    if ((event.target as HTMLInputElement).checked) {
      newActiveModules = [...newInstance.activeModules, value as Module];
    } else {
      newActiveModules = newInstance.activeModules.filter(
        (module) => module !== value
      );
    }

    setNewInstance({
      ...newInstance,
      activeModules: newActiveModules,
    });
  };

  const handleChangeSubModules = (
    event: React.ChangeEvent<HTMLInputElement>,
    mainModule: Module
  ) => {
    const switchedSubModule: string = (event.target as HTMLInputElement).value;
    const hasBeenAdded = (event.target as HTMLInputElement).checked;
    let subModulesList = [...newInstance.activeSubModules[mainModule]];
    let newActiveSubModules: Record<Module, SubModule[]> = {
      ...newInstance.activeSubModules,
    };
    let newActiveModules = [...newInstance.activeModules];

    if (hasBeenAdded) {
      if (mainModule.toLocaleLowerCase().includes('chat')) {
        newActiveSubModules[mainModule] = [];
        newActiveSubModules[mainModule].push(switchedSubModule as SubModule);
      } else {
        newActiveSubModules[mainModule] = [
          ...subModulesList,
          switchedSubModule as SubModule,
        ];
      }
      if (subModulesList.length === 0) {
        newActiveModules.push(mainModule);
      }
    } else {
      newActiveSubModules[mainModule] = subModulesList.filter(
        (subModule: string) => subModule !== switchedSubModule
      );
    }

    if (newActiveSubModules[mainModule].length === 0) {
      newActiveModules = newActiveModules.filter((am) => am !== mainModule);
    }

    setNewInstance({
      ...newInstance,
      activeModules: newActiveModules,
      activeSubModules: newActiveSubModules,
    });
  };

  const handleSaveSettings = () => {
    if (
      newInstance.name.length === 0 ||
      !instance ||
      isEqual(toUpdateInstance(instance), newInstance)
    )
      return;

    setIsSaveModalOpen(true);
    dispatch(updateInstance(newInstance));
  };

  const noChanges = () => {
    if (!instance) return true;

    return (
      instance.name === newInstance.name &&
      instance.metaDescription === newInstance.metaDescription &&
      isEqual(instance.externalLinks, newInstance.externalLinks) &&
      isEqual(instance.openRegistration, newInstance.openRegistration) &&
      isEqual(instance.activeModules, newInstance.activeModules) &&
      isEqual(instance.activeSubModules, newInstance.activeSubModules)
    );
  };

  if (!instance) {
    return <CircularProgress size={64} />;
  }

  const isSubModuleActive = (subModule: SubModule, mainModule: Module) => {
    for (const [main, subList] of Object.entries(
      newInstance.activeSubModules
    )) {
      if (main === mainModule) {
        return subList.includes(subModule);
      }
    }
    return false;
  };

  const isSubModuleEnabled = (subModule: SubModule, mainModule: Module) => {
    for (const [main, subList] of Object.entries(instance.enabledModules)) {
      if (main === mainModule) {
        return subList.includes(subModule);
      }
    }
    return false;
  };

  return (
    <Box p={4}>
      <Box width="fit-content">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <PageOrSectionHeader>
            <Message id="nav.siteSettings" />
          </PageOrSectionHeader>
          <Box className={classes.saveSettingsBtn}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleSaveSettings()}
                disabled={noChanges()}
              >
                <Message id="siteSettings.save" />
              </Button>
              {updateInstanceLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </Box>
        <Box mt={3}>
          <TextInput
            title={<Message id="siteSettings.webTitle" />}
            value={newInstance.name}
            onInput={(e) =>
              setNewInstance({
                ...newInstance,
                name: (e.target as HTMLTextAreaElement).value,
              })
            }
          />

          <TextInput
            title={<Message id="siteSettings.siteDescription" />}
            value={newInstance.metaDescription}
            onInput={(e) =>
              setNewInstance({
                ...newInstance,
                metaDescription: (e.target as HTMLTextAreaElement).value,
              })
            }
          />

          <TextInput
            title={<Message id="siteSettings.externalLinkMain" />}
            value={newInstance.externalLinks.MainPageLink}
            onInput={(e) =>
              setNewInstance({
                ...newInstance,
                externalLinks: {
                  ...newInstance.externalLinks,
                  MainPageLink: (e.target as HTMLTextAreaElement).value,
                },
              })
            }
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">https://</InputAdornment>
              ),
            }}
          />

          <TextInput
            title={<Message id="siteSettings.externalSupportMail" />}
            value={newInstance.externalLinks.SupportEmail}
            onInput={(e) =>
              setNewInstance({
                ...newInstance,
                externalLinks: {
                  ...newInstance.externalLinks,
                  SupportEmail: (e.target as HTMLTextAreaElement).value,
                },
              })
            }
          />

          <Box mb={5} display="flex" flexDirection="column">
            <Typography variant="subtitle2" component="h4" gutterBottom>
              <Message id="siteSettings.modules.openRegistration" />
            </Typography>
            <ModuleSwitch
              moduleName={'openRegistration'}
              isChecked={newInstance?.openRegistration}
              isDisabled={false}
              onChange={handleOpenReg}
              onInfo={() => setInfoModule('openRegistration')}
            />
          </Box>

          <Box mb={5}>
            <PageOrSectionHeader>
              <Message id="siteSettings.activeModules" />
            </PageOrSectionHeader>
            <Box mt={1} mb={2}>
              <Typography>
                <Message id="siteSettings.modulesSaleText" />
              </Typography>
            </Box>
            {Object.entries(instance.availableModules).map(
              ([mainModule, subModules]: [string, string[]]) => (
                <Box
                  key={`section-${mainModule}`}
                  my={2}
                  p={2}
                  className={classes.moduleSection}
                >
                  <Box display="flex" alignItems="center" mb={1}>
                    <Box mr={1} display="flex" alignItems="center">
                      {getModuleIcon(mainModule as Module)}
                    </Box>
                    <Typography variant="subtitle2">
                      <Message id={`siteSettings.modules.${mainModule}`} />
                    </Typography>
                  </Box>
                  {subModules.length === 0 && (
                    <Box ml={3} key={'main-module-' + mainModule}>
                      <ModuleSwitch
                        moduleName={mainModule}
                        isChecked={newInstance.activeModules.includes(
                          mainModule as Module
                        )}
                        isDisabled={false || mainModule === 'External'}
                        onChange={handleModules}
                        onInfo={() => setInfoModule(mainModule)}
                      />
                    </Box>
                  )}
                  {subModules.map((subModule: string) => (
                    <Box ml={3} key={'sub-module-' + subModule}>
                      <ModuleSwitch
                        key={subModule}
                        moduleName={subModule}
                        isChecked={isSubModuleActive(
                          subModule as SubModule,
                          mainModule as Module
                        )}
                        isDisabled={
                          !isSubModuleEnabled(
                            subModule as SubModule,
                            mainModule as Module
                          ) || mainModule === 'External'
                        }
                        onChange={(event) =>
                          handleChangeSubModules(event, mainModule as Module)
                        }
                        onInfo={() => setInfoModule(subModule)}
                      />
                    </Box>
                  ))}
                </Box>
              )
            )}
          </Box>
          <Box mb={3}>
            <PageOrSectionHeader>
              <Message id="siteSettings.language" />
            </PageOrSectionHeader>
            {instance.instanceLanguage}
          </Box>

          <Box mb={5}>
            <LimitsInfo />
          </Box>

          <Box className={classes.saveSettingsBtn} mb={5}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleSaveSettings()}
                disabled={noChanges()}
              >
                <Message id="siteSettings.save" />
              </Button>
              {updateInstanceLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </Box>
      </Box>
      <SaveDialog
        isOpen={isSaveModalOpen}
        isLoading={updateInstanceLoading}
        onClose={() => setIsSaveModalOpen(false)}
      />
      {infoModule && (
        <ModuleInfoDialog
          isOpen={!!infoModule}
          onClose={() => setInfoModule(null)}
          infoModule={infoModule}
        />
      )}
    </Box>
  );
};

type ModuleSwitchProps = {
  moduleName: string;
  isChecked: boolean;
  isDisabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInfo: () => void;
};
const ModuleSwitch = ({
  moduleName,
  isChecked,
  isDisabled,
  onChange,
  onInfo,
}: ModuleSwitchProps) => {
  return (
    <Box key={`module-${moduleName}`} display="flex" alignItems="center">
      <InfoButton onClick={onInfo} />
      <InputLabel style={{ width: 220 }}>
        <Typography color={isDisabled ? 'textSecondary' : 'inherit'}>
          <Message id={`siteSettings.modules.${moduleName}`} />
        </Typography>
      </InputLabel>
      <Switch
        checked={isChecked}
        name={`module${moduleName}`}
        color="primary"
        onChange={(e) => onChange(e)}
        value={moduleName}
        disabled={isDisabled}
      />
    </Box>
  );
};

type InfoButtonProps = {
  onClick: () => void;
};
const InfoButton = ({ onClick }: InfoButtonProps) => (
  <Box
    display="flex"
    alignItems="center"
    mr={1}
    style={{ cursor: 'pointer' }}
    onClick={onClick}
  >
    <InfoIcon fontSize="small" />
  </Box>
);

export default SiteSettingsPage;
