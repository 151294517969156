import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group, GroupMember } from '../types';
import { AppThunk } from './index';
import { RootState } from './rootReducer';
import {
    getGroups as getGroupsAPI,
    getGroupMembers as getGroupMembersAPI,
    createGroup as createGroupAPI,
    deleteGroup as deleteGroupAPI,
    editGroupName as editGroupNameAPI,
    editGroupMembers as editGroupMembersAPI,
    editGroupAdmins as editGroupAdminsAPI,
    getGroupsCount as getGroupsCountAPI
} from '../api/user-api';
import { getMessage } from '../whitelabel-config/WhitelabelProvider';

export interface GroupState {
    groups: Group[] | null;
    currentGroup: Group | null;
    currentGroupMembers: GroupMember[] | null;
    isLoading: boolean;
    error: string;
    count: number
}

const initialState = {
    groups: null,
    currentGroup: null,
    currentGroupMembers: null,
    isLoading: false,
    error: ''
} as GroupState;

const groupSlice = createSlice({
    name: 'groups',
    initialState: initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        getGroupsSuccess(state, action: PayloadAction<Group[]>) {
            state.groups = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        getGroupMembersSuccess(state, action: PayloadAction<GroupMember[]>) {
            state.currentGroupMembers = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        setCurrentGroup(state, action: PayloadAction<Group | null>) {
            state.currentGroup = action.payload;
        },
        setGroupsCount(state, action: PayloadAction<number>) {
            state.count = action.payload;
        },
        resetGroupMembers(state) {
            state.currentGroupMembers = null;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isLoading = false;
        }
    },
});

export const {
    startLoading,
    stopLoading,
    getGroupsSuccess,
    getGroupMembersSuccess,
    setCurrentGroup,
    setGroupsCount,
    resetGroupMembers,
    setError
} = groupSlice.actions;

export default groupSlice.reducer;

// Thunks
export const fetchGroups = (): AppThunk => async (dispatch) => {
    try {
        dispatch(startLoading());
        const groups = await getGroupsAPI();
        dispatch(fetchGroupsCount());
        dispatch(getGroupsSuccess(groups));
    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
};

export const fetchGroupsCount = (): AppThunk => async (dispatch) => {
    try {
        const groupsCount = await getGroupsCountAPI();
        dispatch(setGroupsCount(groupsCount));
    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
};

export const fetchGroupMembers = (groupId: string): AppThunk => async (dispatch) => {
    try {
        dispatch(startLoading());
        const groupMembers = await getGroupMembersAPI(groupId);
        dispatch(getGroupMembersSuccess(groupMembers));
    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
};

export const createGroup = (authorId: string): AppThunk => async (dispatch) => {
    try {
        const newGroup = await createGroupAPI({
            name: getMessage('userArea.group.defaultGroupName'),
            members: [authorId],
            admins: [authorId]
        });
        dispatch(setCurrentGroup(newGroup));
        dispatch(fetchGroups());

    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
}

export const deleteGroup = (id: string): AppThunk => async (dispatch) => {
    try {
        await deleteGroupAPI(id);
        dispatch(fetchGroups());
    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
}

export const editGroupName = (id: string, name: string): AppThunk => async (dispatch) => {
    try {
        const editedGroup = await editGroupNameAPI(id, name);
        dispatch(setCurrentGroup(editedGroup));
    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
}

export const editGroupMembers = (id: string, members: string[]): AppThunk => async (dispatch) => {
    try {
        const editedGroup = await editGroupMembersAPI(id, members);
        dispatch(setCurrentGroup(editedGroup));
    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
}
export const editGroupAdmins = (id: string, admins: string[]): AppThunk => async (dispatch) => {
    try {
        const editedGroup = await editGroupAdminsAPI(id, admins);
        dispatch(setCurrentGroup(editedGroup));
    } catch (err: any) {
        dispatch(setError(err.toString()));
    }
}

// Selectors
const selectGroupState = (rootState: RootState) => rootState.groups;

export const groupsSelector = createSelector(
    selectGroupState,
    (groupState: GroupState) => groupState.groups
);

export const groupsCountSelector = createSelector(
    selectGroupState,
    (groupState: GroupState) => groupState.count
);

export const currentGroupSelector = createSelector(
    selectGroupState,
    (groupState: GroupState) => groupState.currentGroup
);

export const currentGroupMembersSelector = createSelector(
    selectGroupState,
    (groupState: GroupState) => groupState.currentGroupMembers
);

export const groupsIsLoadingSelector = createSelector(
    selectGroupState,
    (groupState: GroupState) => groupState.isLoading
);

export const groupErrorSelector = createSelector(
    selectGroupState,
    (groupState: GroupState) => groupState.error
);
