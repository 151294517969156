import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Button,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Message from '../../components/translation/Message';
import { Actor, ActorsUsersDetails, AddOrRemove } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import {
  actorsUsersDetailsListSelector,
  assignableActorsSelector,
  fetchAssignableActors,
  actorToUserErrorSelector,
  actorsSelector,
} from '../../store/actorsSlice';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';
import { roomLimitSelector } from '../../store/instanceSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actorList: {
      width: 1095,
      maxWidth: '100%',
    },
    saveBtn: {
      alignSelf: 'center',
      marginLeft: '25px',
    },
  })
);

type AddOrRemoveActorProps = {
  onAddOrRemove: (userId: string, actorId: string) => void;
  header: JSX.Element;
  isAdd: boolean;
  id: string;
  addOrRemove: AddOrRemove;
};
const AddOrRemoveActor = ({
  onAddOrRemove,
  header,
  isAdd,
  id,
  addOrRemove,
}: AddOrRemoveActorProps) => {
  const [fromUser, setFromUser] = useState<ActorsUsersDetails | null>(null);
  const [actorToUser, setActorToUser] = useState<Actor | null>(null);
  const actorToUserError = useSelector(actorToUserErrorSelector);

  const actorUsersList = useSelector(actorsUsersDetailsListSelector);
  const assignableActors = useSelector(assignableActorsSelector);
  const roomLimit = useSelector(roomLimitSelector);
  const actors = useSelector(actorsSelector);

  const dispatch = useDispatch();
  const classes = useStyles();

  const getAssignableActors = (): Actor[] => {
    if (addOrRemove === 'Add') {
      return assignableActors.filter(
        (actor) =>
          actor.assignableToUser &&
          !fromUser?.actors.find((a) => a.id === actor.id)
      );
    } else if (addOrRemove === 'Remove') {
      return assignableActors.filter(
        (actor) =>
          actor.assignableToUser &&
          fromUser?.actors.find((a) => a.id === actor.id)
      );
    }

    return assignableActors.filter((actor) => actor.assignableToUser);
  };

  const getActorUsersWithPage = () => {
    return actorUsersList?.filter((au) =>
      au.actors.find((a) => {
        const actor = actors?.find((actor) => actor.id === a.id);
        return actor?.hasPrivateRoom;
      })
    );
  };

  const hasReachedRoomLimit = () => {
    const actorUsersWithPage = getActorUsersWithPage();
    return !!(
      roomLimit &&
      actorUsersWithPage &&
      actorUsersWithPage?.length >= roomLimit
    );
  };

  const disableSelect = () => {
    if (assignableActors.length <= 0 || !fromUser) {
      return true as boolean;
    }
    return false as boolean;
  };

  return (
    <Box>
      {actorUsersList && actorUsersList.length > 0 && (
        <Box>
          <Box mt={8}>
            <PageOrSectionHeader>{header}</PageOrSectionHeader>
            <Box display="flex">
              <Autocomplete
                id={`from-user-${id}`}
                onChange={(e, value, reason) => {
                  if (reason === 'select-option' && value) {
                    setFromUser(value);
                    dispatch(fetchAssignableActors());
                    setActorToUser(null);
                  } else {
                    setFromUser(null);
                    setActorToUser(null);
                  }
                }}
                options={actorUsersList}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${getMessage('handleActors.fromUser')}`}
                    variant="outlined"
                  />
                )}
                value={fromUser}
              />

              <Autocomplete
                id={`actors-${id}`}
                options={getAssignableActors()}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                style={{ width: 300 }}
                disabled={disableSelect()}
                onChange={(e, value, reason) => {
                  if (reason === 'select-option' && value) {
                    setActorToUser(value);
                  } else {
                    setActorToUser(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${getMessage('handleActors.actor')}`}
                    variant="outlined"
                  />
                )}
                value={actorToUser}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.saveBtn}
                disabled={
                  disableSelect() ||
                  !actorToUser ||
                  (actorToUser?.hasPrivateRoom &&
                    hasReachedRoomLimit() &&
                    isAdd)
                }
                onClick={(e) => {
                  if (fromUser && actorToUser) {
                    onAddOrRemove(fromUser.id, actorToUser.id);
                  }
                  setActorToUser(null);
                  setFromUser(null);
                }}
              >
                <Message id="siteSettings.save" />
              </Button>
            </Box>
            <Box mt={1} maxWidth={600}>
              {actorToUser?.hasPrivateRoom &&
                roomLimit &&
                !hasReachedRoomLimit() &&
                isAdd && (
                  <Typography>
                    <Message id="handleActors.allowedRoomLimit" />
                    {getActorUsersWithPage()?.length + ' '}
                    <Message id="handleActors.of" />
                    {' ' + roomLimit}
                  </Typography>
                )}
              {actorToUser?.hasPrivateRoom &&
                hasReachedRoomLimit() &&
                isAdd && (
                  <Typography color="error">
                    <Message id="handleActors.allowedRoomLimitReached1" />
                    {roomLimit}
                    <Message id="handleActors.allowedRoomLimitReached2" />
                  </Typography>
                )}
              {actorToUserError && (
                <Box mt={1}>
                  <Typography variant="body2" component="p" color="error">
                    <Message id="handleActors.addActorError" />
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddOrRemoveActor;
