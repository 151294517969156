import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getIntro,
  addIntro as addIntroAPI,
  deleteIntro as deleteIntroAPI,
  getInstance as getInstanceAPI,
  setInstanceSettings as setInstanceSettingsAPI,
} from '../api/instance-api';
import { Intro, Instance, UpdateInstance } from '../types';
import { AppThunk } from './index';
import { RootState } from './rootReducer';

export interface InstanceState {
  intro: Intro | null;
  getIntroLoading: boolean;
  setIntroLoading: boolean;
  setIntroError: string | null;
  getIntroError: string | null;
  introBeenFetched: boolean;
  instance: Instance | null;
  updateInstanceLoading: boolean
}

const initialState = {
  intro: null,
  getIntroLoading: false,
  setIntroLoading: false,
  setIntroError: null,
  introBeenFetched: false,
  instance: null,
  updateInstanceLoading: false
} as InstanceState;

const instanceSlice = createSlice({
  name: 'instance',
  initialState: initialState,
  reducers: {
    getIntroStart(state) {
      state.getIntroLoading = true;
      state.introBeenFetched = true;
    },
    getIntroSuccess(state, action: PayloadAction<Intro>) {
      state.intro = action.payload;
      state.getIntroLoading = false;
    },
    introIsEmpty(state) {
      state.intro = null;
      state.getIntroLoading = false;
    },
    getIntroFailure(state, action: PayloadAction<string>) {
      state.getIntroError = action.payload;
      state.getIntroLoading = false;
    },
    setIntroStart(state, action: PayloadAction<boolean>) {
      state.setIntroLoading = action.payload;
    },
    setIntroFailure(state, action: PayloadAction<string>) {
      state.setIntroLoading = false;
      state.setIntroError = action.payload
    },
    updateIntro(state, action: PayloadAction<Intro>) {
      state.intro = action.payload;
      state.setIntroLoading = false;
    },
    removeIntro(state) {
      state.intro = null;
    },
    getInstance(state, action: PayloadAction<Instance>) {
      state.instance = action.payload;
    },
    setNewInstance(state, action: PayloadAction<Instance>) {
      if (state.instance === null) {
        return
      }

      state.instance = action.payload
    },
    setInstanceLoading(state, action: PayloadAction<boolean>) {
      state.updateInstanceLoading = action.payload;
    }
  }
});

export const {
  getIntroStart,
  getIntroSuccess,
  introIsEmpty,
  getIntroFailure,
  setIntroStart,
  setIntroFailure,
  updateIntro,
  removeIntro,
  getInstance,
  setInstanceLoading,
  setNewInstance
} = instanceSlice.actions;

export default instanceSlice.reducer;

export const fetchIntro = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getIntroStart());
    const intro = await getIntro();
    dispatch(getIntroSuccess(intro));
  } catch (err: any) {
    // we get the error 404 when there's nothing to fetch
    if (err?.response?.status === 404) {
      dispatch(introIsEmpty());
    } else {
      console.error('Unexpected error', err);
      dispatch(getIntroFailure('Something went wrong, try again later'));
    }
  }
};

export const addIntro = (introToAdd: Intro, onComplete: (success: boolean) => void): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(setIntroStart(true));
    const intro = await addIntroAPI(introToAdd);
    dispatch(updateIntro(intro));
    onComplete(true)
  } catch (err: any) {
    onComplete(false);
    dispatch(setIntroFailure(err.toString()));
  }
};

export const deleteIntro = (): AppThunk => async (dispatch) => {
  try {
    await deleteIntroAPI();
    dispatch(removeIntro());
  } catch (err: any) {
  }
};

export const fetchInstance = (): AppThunk => async (dispatch) => {
  try {
    const instance = await getInstanceAPI();
    dispatch(getInstance(instance));
  } catch (err: any) {
    console.log(err)
  }
};

export const updateInstance = (newInstanceSettings: UpdateInstance): AppThunk => async (dispatch) => {
  try {
    dispatch(setInstanceLoading(true));
    const newInstance = await setInstanceSettingsAPI(newInstanceSettings);
    dispatch(setNewInstance(newInstance));
  } catch (err: any) {
    console.log(err)
  }
  const checkAccessToAPI = () => {
    getInstanceAPI().then(() => { dispatch(setInstanceLoading(false)) }).catch(() => { setTimeout(test, 5000) })
  }
  setTimeout(checkAccessToAPI, 5000);
};

const selectInstanceState = (rootState: RootState) => rootState.instance;

export const introSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.intro
);

export const getIntroFailureSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.getIntroError
);

export const introLoadingSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.getIntroLoading
);

export const addNewIntroLoadingSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.setIntroLoading
);

export const showIntroSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.intro?.show
);

export const introBeenFetchedSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.introBeenFetched
);

export const instanceUpdateLoading = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.updateInstanceLoading
);

export const instanceNameSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.name
);

export const instanceIdSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.id
);

export const instanceModulesSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.activeModules
);

export const instanceExternalLinksSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.externalLinks
);

export const activeSubModulesSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.activeSubModules
);

export const enabledSubModulesSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.enabledModules
);

export const userLimitSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.userLimit
);

export const adminLimitSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.adminLimit
);

export const roomLimitSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.roomLimit
);

export const groupLimitSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.groupLimit
);

export const instanceSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance
);

export const chatApplicationIdSelector = createSelector(
  selectInstanceState,
  (instanceState: InstanceState) => instanceState.instance?.chatApplicationId
);


