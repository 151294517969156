import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import CourseCard from '../../components/courses/CourseCard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  fetchCoursePreviews,
  fetchCourseTypes,
} from '../../store/coursesSlice';
import {
  coursesIsLoadingSelector,
  courseTypesSelector,
} from '../../store/selectors';
import { notificationsSelector } from '../../store/notificationsSlice';
import Message from '../../components/translation/Message';
import { getCourseTypeName } from '../../utils';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';
import { CoursePreview } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    recommendedCourseList: {
      display: 'flex',
      overflow: 'scroll',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    allCoursesList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 0fr))',
      gridGap: '24px',
    },
  })
);

const AllCoursesPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(coursesIsLoadingSelector);
  const courseTypes = useSelector(courseTypesSelector);
  const notifications = useSelector(notificationsSelector);
  const classes = useStyles();
  const location = useLocation();

  const path = getCourseTypeName(location.pathname);
  let courseType =
    courseTypes?.find((type) => type.courseType.name === path) || null;

  useEffect(() => {
    if (path && !courseTypes) {
      dispatch(fetchCourseTypes());
    }
  }, [courseTypes, dispatch, path]);

  useEffect(() => {
    if (path && courseType && !courseType.hasBeenFetched) {
      dispatch(fetchCoursePreviews(courseType.courseType.id));
    }
  }, [courseType, courseType?.courseType.id, dispatch, path]);

  // Why is this code needed?
  // useEffect(() => {
  //   if (!notifications || (courseType && !courseType.hasBeenFetched)) {
  //     return;
  //   }
  //   const courseNotifs = notifications?.filter(
  //     (notif) => notif.notificationType === 'CoursePublished'
  //   );
  //   courseNotifs.forEach((courseNotif) => {
  //     if (
  //       courseNotif.secondReferenceId ===
  //         '00000000-0000-0000-0000-000000000000' &&
  //       !courseType
  //     ) {
  //       dispatch(deleteNotification(courseNotif));
  //     } else if (
  //       courseType &&
  //       courseNotif.secondReferenceId === courseType.courseType.id
  //     ) {
  //       dispatch(deleteNotification(courseNotif));
  //     }
  //   });
  //
  //   if (!path) {
  //     return;
  //   }
  //   const courseTypeNotifs = notifications?.filter(
  //     (notif) => notif.notificationType === 'CourseTypePublished'
  //   );
  //   courseTypeNotifs.forEach((courseTypeNotif) => {
  //     if (courseTypeNotif.referenceId === courseType?.courseType.id) {
  //       dispatch(deleteNotification(courseTypeNotif));
  //     }
  //   });
  // }, [notifications, courseType, path, dispatch]);

  const hasCoursetypePreviews = () => {
    return (
      courseType &&
      courseType?.coursePreview?.filter(
        (preview: CoursePreview) => preview.isActive
      )?.length > 0
    );
  };

  const isActive = courseType && courseType.courseType.isActive;

  const isLoadingOrFetching = () => {
    return (
      (courseType && (courseType.isLoading || !courseType.hasBeenFetched)) ||
      isLoading
    );
  };

  return (
    <Box p={{ xs: 1, md: 4 }}>
      <PageOrSectionHeader>
        <Message id="course.allCoursesPage.browse" />
      </PageOrSectionHeader>
      {isActive && isLoadingOrFetching() && <CircularProgress size={64} />}

      {!isActive && !isLoadingOrFetching() && !hasCoursetypePreviews() && (
        <NoActiveCoursesInType courseType={courseType?.courseType.name} />
      )}

      {isActive && !isLoadingOrFetching() && hasCoursetypePreviews() && (
        <Box className={classes.allCoursesList}>
          {courseType &&
            courseType.coursePreview.map(
              (coursePreview) =>
                coursePreview.isActive && (
                  <CourseCard
                    coursePreview={coursePreview}
                    key={coursePreview.id}
                  />
                )
            )}
        </Box>
      )}
    </Box>
  );
};

export default AllCoursesPage;

type NoActiveCoursesInTypeProps = {
  courseType?: string;
};

const NoActiveCoursesInType = ({ courseType }: NoActiveCoursesInTypeProps) => {
  return (
    <Box mt={4}>
      <Typography>
        <Message id="course.allCoursesPage.noCoursesInType" />
        {courseType && courseType}
        {!courseType && <Message id="nav.courses" />}.
      </Typography>
    </Box>
  );
};
