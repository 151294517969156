import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import Message from '../../../components/translation/Message';
import { Course, SimplifiedCourse } from '../../../types';
import AddExistingSessionDialog from './AddExistingSessionDialog';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';

export type AddSession = {
  name: string;
  type: 'text' | 'video';
};

type AddSessionButtonProps = {
  onAdd: ({ name, type }: AddSession) => void;
  courses: SimplifiedCourse[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'block',
      marginBottom: theme.spacing(6),
    },
  })
);

export default function AddSessionButton({
  onAdd,
  courses,
}: AddSessionButtonProps) {
  const [open, setOpen] = useState(false);
  const [isExistingSessionDialogOpen, setExistingSessionDialogOpen] =
    useState(false);
  const [sessionName, setSessionName] = useState('');
  const [sessionType] = useState('video');
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSessionNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSessionName((event.target as HTMLInputElement).value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onAdd({ name: sessionName, type: sessionType as 'text' | 'video' });
    setOpen(false);
    setSessionName('');
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <Message id="course.courseAdmin.editCoursePage.addSession" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Message id="course.courseAdmin.editCoursePage.addSession" />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={getMessage(
                'course.courseAdmin.editCoursePage.sessionName'
              )}
              fullWidth
              required
              className={classes.input}
              value={sessionName}
              onChange={handleSessionNameChange}
            />
          </DialogContent>
          <DialogActions>
            <AddExistingSessionDialog />
            <Button onClick={handleClose} color="primary">
              <Message id="handleUser.cancel" />
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={sessionName.length < 5}
            >
              <Message id="course.courseAdmin.editCoursePage.add" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
