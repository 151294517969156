import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import Message from '../../components/translation/Message';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import {
  createInstanceActors,
  actorsSelector,
  permissionsSelector,
  updateInstanceActor,
} from '../../store/actorsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Actor, SimplifiedCourse } from '../../types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomChip from '../../components/CustomChip';
import { fetchAllExamCoursesSimplified } from '../../store/coursesSlice';
import { allExamCoursesSimplifiedSelector } from '../../store/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      display: 'flex',
      alignItems: 'center',
    },
    autocompleteInput: {
      '& input': {
        padding: '12px !important',
      },
    },
  })
);

type AddActorsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  actor?: Actor | null;
};
const AddActorsDialog = ({ isOpen, onClose, actor }: AddActorsDialogProps) => {
  const [newActorName, setNewActorName] = useState<string>('');
  const [assignableToUserChecked, setAssignableToUserChecked] =
    useState<boolean>(false);
  const [assignableAsRelationshipChecked, setAssignableAsRelationshipChecked] =
    useState<boolean>(false);
  const [hasPrivateRoomChecked, setHasPrivateRoomChecked] =
    useState<boolean>(false);
  const [
    assignableAsRelationshipToActors,
    setAssignableAsRelationshipToActors,
  ] = useState<Actor[]>([]);
  const [actorPermissions, setActorPermissions] = useState<string[]>(['None']);
  const [canAssignActors, setCanAssignActors] = useState<Actor[]>([]);
  const [mandatoryCourses, setMandatoryCourses] = useState<SimplifiedCourse[]>(
    []
  );

  const actors = useSelector(actorsSelector);
  const permissions = useSelector(permissionsSelector);
  const allCourses = useSelector(allExamCoursesSimplifiedSelector);
  const dispatch = useDispatch();
  const classes = useStyles();

  const populateData = useCallback(
    (mActor: Actor | null | undefined, fromDropdown = false) => {
      if (mActor) {
        setNewActorName(fromDropdown ? '' : mActor.name);
        setAssignableToUserChecked(mActor.assignableToUser);
        setAssignableAsRelationshipChecked(mActor.assignableAsRelationship);
        setHasPrivateRoomChecked(mActor.hasPrivateRoom || false);
        setAssignableAsRelationshipToActors(
          actors?.filter((a) =>
            mActor.assignableAsRelationshipOnlyToActorIds.includes(a.id)
          ) || []
        );
        // Permissions can have either string or string[] type because of backend.
        if (typeof mActor.permissions === 'string') {
          setActorPermissions(mActor.permissions.split(', '));
        }
        if (typeof mActor.permissions === 'object') {
          setActorPermissions(mActor.permissions);
        }
        setCanAssignActors(
          actors?.filter((a) => mActor.canAssignActorIds.includes(a.id)) || []
        );
        setMandatoryCourses(mActor.mandatoryCourses || []);
      }
    },
    [actors]
  );

  useEffect(() => {
    populateData(actor);
  }, [actor, actors, populateData]);

  useEffect(() => {
    dispatch(fetchAllExamCoursesSimplified());
  }, [dispatch]);

  const resetLocalState = () => {
    setNewActorName('');
    setAssignableToUserChecked(false);
    setAssignableAsRelationshipChecked(false);
    setHasPrivateRoomChecked(false);
    setAssignableAsRelationshipToActors([]);
    setActorPermissions(['None']);
    setCanAssignActors([]);
    setMandatoryCourses([]);
  };

  const assignableAsRelationshipToActorsHasNotChanged = (): boolean => {
    if (!actor) return true;
    return (
      assignableAsRelationshipToActors.length ===
        actor.assignableAsRelationshipOnlyToActorIds.length &&
      assignableAsRelationshipToActors.every((a) =>
        actor.assignableAsRelationshipOnlyToActorIds.includes(a.id)
      )
    );
  };

  const permissionsHaveNotChanged = (): boolean => {
    if (!actor || !permissions) return true;
    const actorPermissionsString = actorPermissions.join(', ');
    return actorPermissionsString === actor.permissions;
  };

  const canAssignActorsHasNotChanged = (): boolean => {
    if (!actor) return true;
    return (
      canAssignActors.length === actor.canAssignActorIds.length &&
      canAssignActors.every((a) => actor.canAssignActorIds.includes(a.id))
    );
  };

  const mandatoryCoursesHaveNotChanged = (): boolean => {
    if (!actor) return true;
    return (
      actor.mandatoryCourses?.length === mandatoryCourses.length &&
      actor.mandatoryCourses.every((c) =>
        mandatoryCourses.some((mc) => mc.id === c.id)
      )
    );
  };

  const noChanges = (): boolean => {
    return (
      !!actor &&
      newActorName === actor.name &&
      assignableToUserChecked === actor.assignableToUser &&
      assignableAsRelationshipChecked === actor.assignableAsRelationship &&
      hasPrivateRoomChecked === !!actor.hasPrivateRoom &&
      assignableAsRelationshipToActorsHasNotChanged() &&
      permissionsHaveNotChanged() &&
      mandatoryCoursesHaveNotChanged() &&
      canAssignActorsHasNotChanged()
    );
  };

  const getRelevantPermissions = () => {
    if (!permissions) return [];
    if (!assignableToUserChecked || assignableAsRelationshipChecked)
      return permissions;
    return permissions.filter(
      (p) => !['ReadVideo', 'WriteVideo', 'PublishVideo'].includes(p)
    );
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewActorName((event.target as HTMLInputElement).value);
  };

  const handleSave = () => {
    if (actor) {
      const updatedActor = {
        id: actor.id,
        name: newActorName,
        assignableToUser: assignableToUserChecked,
        assignableAsRelationship: assignableAsRelationshipChecked,
        hasPrivateRoom: hasPrivateRoomChecked,
        assignableAsRelationshipOnlyToActorIds: assignableAsRelationshipChecked
          ? assignableAsRelationshipToActors.map((actor) => actor.id)
          : [],
        permissions: actorPermissions.join(', '),
        canAssignActorIds: canAssignActors.map((actor) => actor.id),
        mandatoryCourses,
      };
      dispatch(updateInstanceActor(updatedActor));
    } else {
      dispatch(
        createInstanceActors({
          actors: [
            {
              name: newActorName,
              assignableToUser: assignableToUserChecked,
              assignableAsRelationship: assignableAsRelationshipChecked,
              hasPrivateRoom: hasPrivateRoomChecked,
              assignableAsRelationshipOnlyToActorIds:
                assignableAsRelationshipChecked
                  ? assignableAsRelationshipToActors.map((actor) => actor.id)
                  : [],
              permissions: actorPermissions.join(', '),
              canAssignActorIds: canAssignActors.map((actor) => actor.id),
              mandatoryCourses,
            },
          ],
        })
      );
    }
  };

  const handleClose = () => {
    resetLocalState();
    onClose();
  };

  const nameTaken = () => {
    return (
      !actor &&
      actors
        ?.map((a) => a.name)
        .some((v) => v.toLowerCase() === newActorName.toLowerCase())
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <Box pt={1} />
      <DialogTitle>
        <Box display={'flex'} justifyContent={'space-between'}>
          {!!actor ? (
            <Message id="handleActors.editActor" />
          ) : (
            <Message id="handleActors.addNewActor" />
          )}
          {!actor && (
            <Autocomplete
              style={{ width: '30%' }}
              options={actors ?? []}
              getOptionLabel={(option) => option.name}
              onChange={(e, value, reason) => {
                if (reason === 'select-option') {
                  populateData(value, true);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={getMessage('handleActors.fromOther')}
                />
              )}
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Typography variant="subtitle2">
            <Message id="handleActors.name" />
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            required
            value={newActorName}
            onChange={handleNameChange}
          />
        </Box>

        <Box pt={4}>
          <SectionHeading messageId="handleActors.actorType" />
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={assignableToUserChecked}
                onChange={() =>
                  setAssignableToUserChecked(!assignableToUserChecked)
                }
              />
            }
            label={<Message id="handleActors.assignableToUser" />}
          />
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={assignableAsRelationshipChecked}
                onChange={() =>
                  setAssignableAsRelationshipChecked(
                    !assignableAsRelationshipChecked
                  )
                }
              />
            }
            label={<Message id="handleActors.assignableAsRelationship" />}
          />
        </Box>

        <Box pt={3}>
          <SectionHeading
            messageId="handleActors.shouldHavePrivateRoom"
            disabled={!assignableToUserChecked}
          />
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={hasPrivateRoomChecked}
                onChange={() =>
                  setHasPrivateRoomChecked(!hasPrivateRoomChecked)
                }
                disabled={!assignableToUserChecked}
              />
            }
            label={<Message id="handleActors.shouldHavePrivateRoomCheckbox" />}
          />
        </Box>
        {actors && (
          <Box pt={3}>
            <SectionHeading
              messageId="handleActors.assignableAsRelationshipToActors"
              disabled={!assignableAsRelationshipChecked}
            />
            <Box pt={1}>
              <Autocomplete
                id="assignableAsRelationshipToActors"
                multiple
                disabled={!assignableAsRelationshipChecked}
                options={actors}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={assignableAsRelationshipToActors}
                onChange={(e, values, reason) => {
                  setAssignableAsRelationshipToActors(values);
                }}
                renderInput={(params) => (
                  <TextField
                    error={false}
                    {...params}
                    classes={{ root: classes.autocompleteInput }}
                    required
                    placeholder={getMessage('handleActors.selectActors')}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <CustomChip
                      {...getTagProps({ index })}
                      label={option.name}
                    />
                  ));
                }}
                noOptionsText={getMessage('handleActors.noMatchingActors')}
              />
            </Box>
          </Box>
        )}
        {permissions && permissions.length > 0 && (
          <Box pt={4}>
            <SectionHeading messageId="handleActors.permissions" />
            <Autocomplete
              id="permissions"
              multiple
              options={getRelevantPermissions()}
              getOptionLabel={(option) =>
                getMessage(`handleActors.permission.${option}`)
              }
              value={actorPermissions}
              onChange={(e, values, reason) => {
                if (values.length === 0) {
                  setActorPermissions(['None']);
                } else {
                  setActorPermissions(values.filter((v) => v !== 'None'));
                }
              }}
              renderInput={(params) => (
                <TextField
                  error={false}
                  {...params}
                  classes={{ root: classes.autocompleteInput }}
                  required
                  placeholder={getMessage('handleActors.selectPermissions')}
                />
              )}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <CustomChip
                    {...getTagProps({ index })}
                    label={getMessage(`handleActors.permission.${option}`)}
                  />
                ));
              }}
              noOptionsText={getMessage('handleActors.noMatchingPermissions')}
            />
          </Box>
        )}
        <Box pt={4}>
          <SectionHeading messageId="handleActors.canAssignActors" />
          {actors && (
            <Autocomplete
              id="canAssignActors"
              multiple
              options={actors}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              value={canAssignActors}
              onChange={(e, values, reason) => {
                setCanAssignActors(values);
              }}
              renderInput={(params) => (
                <TextField
                  error={false}
                  {...params}
                  classes={{ root: classes.autocompleteInput }}
                  required
                  placeholder={getMessage('handleActors.selectActors')}
                />
              )}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <CustomChip {...getTagProps({ index })} label={option.name} />
                ));
              }}
              noOptionsText={getMessage('handleActors.noMatchingActors')}
            />
          )}
          {!actors && (
            <Box pt={1}>
              <Typography>
                <Message id="handleActors.noActorsToAssign" />
              </Typography>
            </Box>
          )}
        </Box>
        {/* TODO: Send to backend */}
        {allCourses && (
          <Box pt={4}>
            <SectionHeading messageId="handleActors.mandatoryCourses" />
            <Autocomplete
              id="hasMandatoryCourses"
              multiple
              disableCloseOnSelect
              options={allCourses}
              value={mandatoryCourses}
              onChange={(e, newValues) => setMandatoryCourses(newValues)}
              getOptionLabel={(option) =>
                `${option.courseType?.name} - ${option.name}`
              }
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <Box>
                  <TextField
                    {...params}
                    classes={{ root: classes.autocompleteInput }}
                    required
                    placeholder={getMessage('handleActors.selectCourses')}
                  />
                </Box>
              )}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <CustomChip
                    {...getTagProps({ index })}
                    label={`${option.courseType?.name} - ${option.name}`}
                  />
                ));
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <Button variant="contained" onClick={handleClose}>
            <Message id="handleActors.cancel" />
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave();
              handleClose();
            }}
            disabled={!newActorName || noChanges() || nameTaken()}
          >
            <Message id="handleActors.save" />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useHeadingStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: '1em',
    },
    disabled: {
      color: theme.palette.text.disabled,
    },
  })
);
type SectionHeadingProps = {
  messageId: string;
  disabled?: boolean;
};
const SectionHeading = ({ messageId, disabled }: SectionHeadingProps) => {
  const classes = useHeadingStyles();
  return (
    <Box display="flex" alignItems="center">
      <Tooltip
        title={getMessage(`handleActors.tooltip.${messageId}`)}
        classes={{ tooltip: classes.tooltip }}
        arrow
      >
        <InfoOutlinedIcon
          fontSize="small"
          color={disabled ? 'disabled' : 'inherit'}
        />
      </Tooltip>
      <Box mr={1} />
      <Typography
        variant="subtitle2"
        className={disabled ? classes.disabled : ''}
      >
        <Message id={messageId} />
      </Typography>
    </Box>
  );
};

export default AddActorsDialog;
