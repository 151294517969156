import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  Button,
} from '@material-ui/core';
import InstructorItem from '../../components/courses/InstructorItem';
import DownloadIcon from '@material-ui/icons/GetApp';
import SafeHtmlRenderer from '../../components/SafeHtmlRenderer';
import {
  Course,
  CourseResult,
  ExtendedResult,
  Session,
  Document,
} from '../../types';
import ReactPlayer from 'react-player';
import Message from '../../components/translation/Message';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';
import {
  getSessionDocument,
  getSessionDocumentPdf,
} from '../../api/course-api';
import { downloadFile } from '../../utils';
import { useSelector } from 'react-redux';
import { currentGroupSelector } from '../../store/groupSlice';
import { userAreaSelector } from '../../store/userAreaSlice';
import { activeSubModulesSelector } from '../../store/instanceSlice';
import { SessionQuestions } from '../../components/question/SessionQuestions';
import { userSelector } from '../../store/userSlice';
import { ViewResults } from './ViewResults';
import {
  courseResultsSelector,
  sessionResultsSelector,
} from '../../store/selectors';

import { Alert } from '@material-ui/lab';
import { DocumentIcon } from '../../components/documents/DocumentFileType';
import DocumentViewer from '../../components/documents/DocumentViewer';

type SessionProps = {
  session: Session;
  course: Course;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoContainer: {
      width: '100%',
      height: 800,
      [theme.breakpoints.down('sm')]: {
        height: 400,
      },
      [theme.breakpoints.down('xs')]: {
        height: 300,
      },
    },
    liveStreamContainer: {
      width: '100%',
      height: 800,
      [theme.breakpoints.down('sm')]: {
        height: 400,
      },
      [theme.breakpoints.down('xs')]: {
        height: 300,
      },
      display: 'flex',
    },
  })
);

const SessionSubpage = ({ session, course }: SessionProps) => {
  const [file, setFile] = useState<Blob | null>(null);
  const [filePdf, setFilePdf] = useState<Blob | null>(null);
  const [documentFetched, setDocumentFetched] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [latestDocument, setLatestDocument] = useState<Document | null>(null);

  const activeSubModules = useSelector(activeSubModulesSelector);
  const userArea = useSelector(userAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const user = useSelector(userSelector);
  const courseResults = useSelector(courseResultsSelector);
  const sessionResults = useSelector(sessionResultsSelector);
  const classes = useStyles();
  const [isResultViewOpen, setIsResultViewOpen] = useState<boolean>(false);

  const liveStreamIsOn = useCallback((): boolean => {
    return (
      !!activeSubModules &&
      ((!userArea &&
        !currentGroup &&
        activeSubModules.Course.includes('PublicLiveStream')) ||
        (!!userArea && activeSubModules.Course.includes('PrivateLiveStream')) ||
        (!!currentGroup && activeSubModules.Course.includes('GroupLiveStream')))
    );
  }, [activeSubModules, currentGroup, userArea]);

  const isExamOn = useCallback((): boolean => {
    return !!activeSubModules && activeSubModules.Course.includes('ExamCourse');
  }, [activeSubModules]);

  useEffect(() => {
    setFile(null);
    setFilePdf(null);
  }, [session, course]);

  useEffect(() => {
    getLatestDocument();
    const getDocument = async () => {
      if (latestDocument) {
        const fileFromBackend = await getSessionDocument(
          session.id,
          latestDocument.id
        );
        setFile(fileFromBackend);
        if (latestDocument.pdfUrl != '') {
          const fileAsPdf = await getSessionDocumentPdf(
            session.id,
            latestDocument.id
          );
          setFilePdf(fileAsPdf);
        }
      }
    };

    if (session.documents.length > 0 && !file) {
      getDocument();
    }
  }, [session, file, !latestDocument]);

  const getLatestDocument = () => {
    if (session.documents.length >= 1) {
      setLatestDocument(session.documents[session.documents.length - 1]);
    } else {
      setLatestDocument(session.documents[0]);
    }
  };

  const handleDownload = async () => {
    if (!file || !latestDocument) {
      return;
    }
    setIsDownloading(true);
    downloadFile(file, latestDocument?.name);
    setIsDownloading(false);
  };

  const canViewResults = () => {
    return (
      isExamOn() && user !== null && user.instanceRoles.includes('CourseAdmin')
    );
  };

  const getSessionResults = (
    courseResult?: CourseResult
  ): ExtendedResult | undefined => {
    const results = courseResult?.sessionResults.find(
      (s) => s.sessionId === session.id
    );

    const sessionResult = sessionResults[session.id];

    if (sessionResult && results) {
      return {
        userId: results.userId,
        username: results.username,
        answerResults: sessionResult.answerResults,
        maxPoints: sessionResult.maxPoints,
        pass: sessionResult.maxPoints === sessionResult.totalPoints,
        sessionId: session.id,
        sessionName: session.name,
        totalPoints: sessionResult.totalPoints,
        passes: results.passes,
        sessionIndex: session.index,
      };
    }

    return results?.answerResults.length === 0 ? undefined : results;
  };

  const getFileExtension = (name: string): string => {
    const nameSplit = name.split('.');
    return nameSplit[nameSplit.length - 1];
  };

  return (
    <Box py={4} px={4}>
      {canViewResults() && (
        <Box
          mb={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsResultViewOpen(true)}
          >
            <Message id="course.courseAdmin.seeResults" />
          </Button>
        </Box>
      )}
      {isExamOn() && (
        <ViewResults
          isOpen={canViewResults() && isResultViewOpen}
          sessionId={session.id}
          session={session}
          onClose={() => setIsResultViewOpen(false)}
        />
      )}
      <Box mb={4}>
        <PageOrSectionHeader>{session.name}</PageOrSectionHeader>
      </Box>

      {(session.vimeoUrl || (session.liveStreamUrl && liveStreamIsOn())) && (
        <Box
          className={
            session.liveStreamUrl
              ? classes.liveStreamContainer
              : classes.videoContainer
          }
          mb={8}
        >
          <ReactPlayer
            key={session.liveStreamUrl || session.vimeoUrl}
            url={session.liveStreamUrl || session.vimeoUrl}
            controls
            width="100%"
            height="100%"
          />
          {session.liveStreamUrl && (
            <Box>
              <iframe
                title={`Live Stream chat`}
                src={`${session.liveStreamUrl}/chat/`}
                width={340}
                height="100%"
                frameBorder="0"
              ></iframe>
            </Box>
          )}
        </Box>
      )}

      {/* Old sessions can have formattedText, new ones only have description */}
      {session.formattedDescription && (
        <Box mb={4}>
          <SafeHtmlRenderer html={session.formattedDescription || ''} />
        </Box>
      )}
      {session.formattedText && (
        <Box mb={4}>
          <SafeHtmlRenderer html={session.formattedText || ''} />
        </Box>
      )}

      {session.documents.length > 0 && (
        <Box>
          {session.isFileDownloadable && (
            <Box mb={1}>
              <Button
                variant="outlined"
                color="primary"
                disabled={!file}
                endIcon={
                  !file ? <CircularProgress size={20} /> : <DownloadIcon />
                }
                onClick={handleDownload}
              >
                <Message id="course.download" />
              </Button>
            </Box>
          )}

          {!file && (
            <Box mb={1}>
              <Alert severity="info">
                <Message
                  id={'course.courseAdmin.editCoursePage.bigDocumentInfo'}
                />
              </Alert>
            </Box>
          )}

          {file && latestDocument && (
            <Box mb={8}>
              {DocumentIcon(getFileExtension(latestDocument?.name))(
                latestDocument,
                file,
                filePdf,
                session.name
              )}
            </Box>
          )}
        </Box>
      )}

      {isExamOn() && session.questionAnswers?.length > 0 && (
        <SessionQuestions
          key={session.id}
          session={session}
          sessionResult={getSessionResults(courseResults[course.id])}
        />
      )}

      {course.instructors.length > 0 && (
        <Box mb={8}>
          <Box mb={1}>
            <Typography variant="subtitle2">
              <Message id="course.instructors" />
            </Typography>
          </Box>
          {course.instructors.map((instructor) => (
            <Box mb={1}>
              <InstructorItem
                instructor={instructor}
                showName={true}
                key={instructor.id}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SessionSubpage;
