import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  FormLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviders, providersSelector } from '../../store/providersSlice';
import { getEditCourseUrl } from '../../routes/paths';
import { createCourse } from '../../api/course-api';
import { useHistory } from 'react-router-dom';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';
import Message from '../../components/translation/Message';
import { CourseType } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'block',
      marginBottom: theme.spacing(6),
    },
  })
);

type AddCourseButtonProps = {
  courseType: CourseType | null;
};

export default function AddCourseButton({ courseType }: AddCourseButtonProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const providers = useSelector(providersSelector);
  const { getMessage } = useContext(WhitelabelContext);

  const [open, setOpen] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [providerId, setProviderId] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCourseNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCourseName((event.target as HTMLInputElement).value);
  };

  const handleProviderChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setProviderId(event.target.value as string);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!providerId) {
      setHelperText(getMessage('course.courseAdmin.addProviderHelper'));
      setError(true);
    } else {
      const newCourse = {
        name: courseName,
        providerId,
        formattedIntroText: 'Intro text',
        tags: [],
        instructorIds: [],
        featured: false,
        isActive: false,
        courseType: courseType ? courseType.id : '',
        repeatable: false,
      };
      createCourse(newCourse).then((course) => {
        history.push(getEditCourseUrl(course.slug));
      });
    }
  };

  useEffect(() => {
    if (!providers) {
      dispatch(fetchProviders());
    }
  }, [providers, dispatch]);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {courseType
          ? `${getMessage('course.courseAdmin.addCourse')} ${courseType?.name}`
          : `${getMessage('course.courseAdmin.addCourse')} ${getMessage(
              'course.course'
            )}`}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Message id="course.courseAdmin.addCourse" />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={`${getMessage(
                'course.courseAdmin.editCoursePage.courseName'
              )}`}
              fullWidth
              required
              className={classes.input}
              value={courseName}
              onChange={handleCourseNameChange}
            />
            <FormControl required error={error}>
              <FormLabel>
                <Message id="course.provider" />
              </FormLabel>
              <Select value={providerId} onChange={handleProviderChange}>
                {providers &&
                  providers.map((provider) => (
                    <MenuItem value={provider.id} key={provider.id}>
                      {provider.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              <Message id="handleUser.cancel" />
            </Button>
            <Button type="submit" color="primary">
              <Message id="course.courseAdmin.editCoursePage.add" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
