import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { WhitelabelContext } from '../whitelabel-config/WhitelabelProvider';
import Message from '../components/translation/Message';
import {
  addStaticContentImage,
  getStaticContentImage,
} from '../api/instance-api';
import { useSelector } from 'react-redux';
import { instanceSelector } from '../store/instanceSlice';

type ImageUploaderProps = {
  path: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none',
    },
    wideLogo: {
      height: 80,
      objectFit: 'contain',
      marginTop: theme.spacing(2),
    },

    upploadButton: {
      width: '25ch',
    },
  })
);

const ImageUploader = ({ path }: ImageUploaderProps) => {
  const { getImage, mergeStaticContent } = useContext(WhitelabelContext);
  const logoId = getImage(path);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [logoError, setLogoError] = useState<string>('');
  const classes = useStyles();
  const maxLogoFileSizeInBytes = 1000000;
  const instance = useSelector(instanceSelector);
  //const maxLogoSizeInKB = maxLogoFileSizeInBytes / 1000;

  useEffect(() => {
    if (logoId && logoId.length > 0) {
      updateImage(logoId, setLogoUrl);
    }
    setLogoError('');
  }, [logoId]);

  const handleLogoInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const imageInput = event.target as HTMLInputElement;
    const imageFile =
      imageInput.files?.length === 1 ? imageInput.files[0] : null;
    if (!imageFile) return true;
    if (checkErrors(imageFile)) return;

    const isFavicon = path === 'favicon' ? true : false;
    try {
      const response = await addStaticContentImage(path, imageFile, isFavicon);
      await mergeStaticContent(
        response,
        instance?.instanceLanguage ?? 'English'
      );
      setLogoError('');
    } catch (e: any) {
      if (e.response.status === 415) {
        setLogoError('siteSettings.faviconErrorSize');
      } else {
        setLogoError('siteSettings.errorText');
      }
    }
  };

  const updateImage = async (
    imageUrl: string,
    setImage: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const logoData = await getStaticContentImage(imageUrl);
    const imageSrcUrl = window.URL.createObjectURL(logoData);
    setImage(imageSrcUrl);
  };

  const checkErrors = (imageFile: File) => {
    if (path === 'favicon') {
      if (imageFile.type !== 'image/png') {
        setLogoError('siteSettings.imageErrorInvalid');
        return true;
      }
    } else {
      if (
        imageFile.type !== 'image/png' &&
        imageFile.type !== 'image/jpg' &&
        imageFile.type !== 'image/jpeg'
      ) {
        setLogoError('siteSettings.imageErrorInvalid');
        return true;
      }

      if (imageFile.size > maxLogoFileSizeInBytes) {
        setLogoError('siteSettings.imageErrorSize');
        return true;
      }
    }
  };

  return (
    <Box width={'215px'}>
      <Typography variant="body1" component="p" gutterBottom></Typography>
      <Box>
        <input
          accept={path === 'favicon' ? 'image/png' : 'image/*'}
          className={classes.input}
          id={`contained-button-file-${path}`}
          type="file"
          onInput={handleLogoInput}
        />
        <label htmlFor={`contained-button-file-${path}`}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.upploadButton}
          >
            <Message id="siteSettings.upploadImage" />
          </Button>
        </label>
      </Box>

      {logoUrl && (
        <img
          src={logoUrl}
          alt={`Uploaded ${path}`}
          className={classes.wideLogo}
        />
      )}
      {logoError && (
        <Typography variant="body2" component="p" color="error">
          <Message id={logoError} />
        </Typography>
      )}
    </Box>
  );
};

export default ImageUploader;
