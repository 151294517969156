import { useEffect, useContext } from 'react';
import {
  Box,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  FormLabel,
  DialogActions,
  InputLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import CourseCard from '../../../components/courses/CourseCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  courseTypesSelector,
  fetchCourseTypes,
  fetchFilteredCourses,
  changeCourseType,
  filteredCoursesSelector,
  loadingCoursesSelector,
  deleteCourseType,
  currentCourseTypeSelector,
  setCurrentCourseType,
  changeCourseTypeOrder,
} from '../../../store/editCourseSlice';
import { getEditCourseUrl } from '../../../routes/paths';
import AddCourseButton from '../AddCourseButton';
import Message from '../../../components/translation/Message';
import AddCourseTypeButton from '../AddCourseTypeButton';
import { useState } from 'react';
import { CourseType, IconsFromBackend } from '../../../types';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { WhitelabelContext } from '../../../whitelabel-config/WhitelabelProvider';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import MaterialIcon from '../../../components/icons/MaterialIcon';
import CourseIcon from '../../../components/icons/CourseIcon';
import PageOrSectionHeader from '../../../components/typography/PageOrSectionHeader';
import { getCourseTypeIconList as getCourseTypeIconListAPI } from '../../../api/course-api';
import { courseTheme } from '../../../theme';
import { TwitterPicker } from 'react-color';

import {
  DndContext,
  DragStartEvent,
  DragEndEvent,
  closestCenter,
  DragOverlay,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import SortableCourseTypeButton from '../../../components/courses/SortableCourseTypeButton';
import { CourseTypeButton } from '../../../components/courses/CourseTypeButton';
import { DoneOutline, SortOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allCoursesList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 0fr))',
      gap: theme.spacing(3),
    },
    gridSpace: {
      marginTop: theme.spacing(5),
    },
    input: {
      display: 'block',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    courseTypeBtn: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    categories: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(4),
    },
    editCourseType: {
      display: 'flex',
      justifyContent: 'end',
      marginBottom: theme.spacing(2),
    },
    editFolderButton: {
      marginRight: theme.spacing(2),
    },
    icons: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(6),
    },
    icon: {},
    iconChosen: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  })
);

const CourseAdminDashboardPage = () => {
  const classes = useStyles();
  const [editingOrder, setEditingOrder] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [draggingCourseType, setDraggingCourseType] =
    useState<CourseType | null>(null);
  const dispatch = useDispatch();
  const filteredCourses = useSelector(filteredCoursesSelector);
  const coursesIsLoading = useSelector(loadingCoursesSelector);
  const currentCourseType = useSelector(currentCourseTypeSelector);
  const courseTypes = useSelector(courseTypesSelector);

  const [items, setItems] = useState<CourseType[]>(
    courseTypes ? courseTypes : []
  );
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );
  useEffect(() => {
    if (!courseTypes) {
      dispatch(fetchCourseTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseTypes, currentCourseType]);

  useEffect(() => {
    if (!currentCourseType && courseTypes && courseTypes.length > 0) {
      dispatch(setCurrentCourseType(courseTypes[0]));
    }
  }, [currentCourseType, courseTypes, dispatch]);

  useEffect(() => {
    if (currentCourseType) {
      dispatch(fetchFilteredCourses(currentCourseType.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCourseType?.id]);

  useEffect(() => {
    if (items.length === 0 && courseTypes) {
      setItems(courseTypes);
    }
  }, [items, courseTypes]);
  const handleDragStart = (event: DragStartEvent) => {
    if (courseTypes) {
      const { active } = event;
      const ct = courseTypes.find((c) => c.id === active.id);
      if (ct) {
        setDraggingCourseType(ct);
      }
    }
  };
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items: CourseType[]) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setDraggingCourseType(null);
  };

  const handleSortingEnd = async () => {
    // Save order to backend.
    const itemsToSave = items.map((item, i) => {
      return {
        ...item,
        order: i,
      };
    });
    if (JSON.stringify(itemsToSave) !== JSON.stringify(items)) {
      setUpdating(true);
      dispatch(changeCourseTypeOrder(itemsToSave));
    }
    setEditingOrder(false);
    setUpdating(false);
  };

  return (
    <Box p={4}>
      <Box display="flex" flexDirection="column" maxWidth="max-content">
        <Box display="flex" flexDirection="row" alignItems="center">
          <PageOrSectionHeader>
            <Message id="course.courseAdmin.editCourses" />
          </PageOrSectionHeader>
          <div style={{ marginLeft: 10 }}>
            {updating && <CircularProgress size="30px" />}
            {!updating && !editingOrder && (
              <Button
                color="primary"
                onClick={() => setEditingOrder(true)}
                startIcon={<SortOutlined />}
              >
                <Message id="course.courseAdmin.editOrder" />
              </Button>
            )}
            {!updating && editingOrder && (
              <Button
                color="primary"
                onClick={handleSortingEnd}
                startIcon={<DoneOutline />}
              >
                <Message id="course.courseAdmin.editCoursePage.done" />
              </Button>
            )}
          </div>
        </Box>
        {items && items.length > 0 && editingOrder && (
          <Box pt={2} className={classes.categories}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={items}>
                {items.map((item: CourseType) => (
                  <SortableCourseTypeButton
                    id={item.id}
                    courseType={item}
                    color={
                      currentCourseType && currentCourseType.id === item.id
                        ? 'primary'
                        : 'default'
                    }
                  />
                ))}
              </SortableContext>
              <DragOverlay>
                {draggingCourseType ? (
                  <CourseTypeButton courseType={draggingCourseType} />
                ) : null}
              </DragOverlay>
            </DndContext>
          </Box>
        )}
        {courseTypes && !editingOrder && (
          <Box pt={2} className={classes.categories}>
            {courseTypes.map((type) => (
              <Button
                key={type.id}
                id={type.id}
                variant="contained"
                color={
                  currentCourseType && currentCourseType.id === type.id
                    ? 'primary'
                    : 'default'
                }
                endIcon={!type.isActive && <VisibilityOffOutlinedIcon />}
                className={classes.courseTypeBtn}
                onClick={() => {
                  dispatch(fetchFilteredCourses(type.id));
                  dispatch(setCurrentCourseType(type));
                }}
              >
                {type.name}
              </Button>
            ))}
            <AddCourseTypeButton />
          </Box>
        )}
      </Box>

      {currentCourseType && (
        <Box className={classes.editCourseType}>
          <DeleteCourseTypeButton
            courseType={currentCourseType}
            nrOfCourses={filteredCourses ? filteredCourses.length : 0}
          />
          <EditCourseTypeButton courseType={currentCourseType} />
          <AddCourseButton courseType={currentCourseType} />
        </Box>
      )}
      {coursesIsLoading ? (
        <CircularProgress size={64} />
      ) : (
        <Box>
          <Box className={`${classes.allCoursesList} ${classes.gridSpace}`}>
            {filteredCourses &&
              filteredCourses.map((filteredCourse) => (
                <CourseCard
                  coursePreview={filteredCourse}
                  isActive={filteredCourse.isActive}
                  key={filteredCourse.id}
                  linkTo={getEditCourseUrl(filteredCourse.slug)}
                />
              ))}
          </Box>
          {(!filteredCourses || filteredCourses.length === 0) &&
            currentCourseType && (
              <Box mt={4}>
                <Typography>
                  <Message id="course.allCoursesPage.noCoursesInType" />
                  {currentCourseType.name}.
                </Typography>
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
};

export default CourseAdminDashboardPage;

type EditCourseTypeButtonProps = {
  courseType: CourseType;
};

const EditCourseTypeButton = ({ courseType }: EditCourseTypeButtonProps) => {
  const dispatch = useDispatch();
  const { getMessage } = useContext(WhitelabelContext);
  const [open, setOpen] = useState(false);
  const [courseTypeName, setCourseTypeName] = useState<string>('');
  const [courseTypeActive, setCourseTypeActive] = useState<boolean>(true);
  const [courseTypeIcon, setCourseTypeIcon] = useState<string>('Default');
  const [iconNames, setIconsNames] = useState<string[]>([]);
  const [color, setColor] = useState(courseTheme.palette.primary.main);

  const classes = useStyles();

  useEffect(() => {
    setCourseTypeName(courseType.name);
    setCourseTypeActive(courseType.isActive);
    setCourseTypeIcon(courseType.icon);
    if (!courseType.color) {
      setColor(courseTheme.palette.primary.main);
    } else {
      setColor(courseType.color);
    }
  }, [courseType]);

  useEffect(() => {
    const getIconNames = async () => {
      await getCourseTypeIconListAPI()
        .then((icons) => {
          // Backend needs 'Icon' at the end of an icon name while frontend needs without 'Icon'
          const names = (icons as IconsFromBackend).icons.map(
            (iconName) => iconName.split('Icon')[0]
          );
          setIconsNames(names);
        })
        .catch((error: any) => {
          console.log(error);
        });
    };

    if (iconNames.length === 0) {
      getIconNames();
    }
  }, [iconNames]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCourseNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCourseTypeName((event.target as HTMLInputElement).value);
  };

  const handleActiveGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourseTypeActive(event.target.checked);
  };

  const handleColorChange = (newColor: any) => {
    setColor(newColor.hex);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      changeCourseType({
        ...courseType,
        name: courseTypeName,
        isActive: courseTypeActive,
        icon: courseTypeIcon,
        color: color,
      })
    );
    handleClose();
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        className={classes.editFolderButton}
        startIcon={<EditIcon />}
      >
        <Message id="course.courseAdmin.editCourseType" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Message id="course.courseAdmin.editCourseType" />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box display="flex" alignItems={'center'}>
              <InputLabel id="group-active">
                <Message id="course.courseAdmin.activeCourseType" />
              </InputLabel>
              <Switch
                checked={courseTypeActive}
                name="activategroup"
                color="primary"
                onChange={handleActiveGroup}
              />
            </Box>

            <TextField
              autoFocus
              margin="dense"
              label={`${getMessage('course.courseAdmin.courseType')}`}
              fullWidth
              required
              className={classes.input}
              value={courseTypeName}
              onChange={handleCourseNameChange}
            />

            <FormControl>
              <FormLabel>
                <Message id="course.courseAdmin.icon" />
              </FormLabel>
              <Box className={classes.icons}>
                <Button
                  className={
                    courseTypeIcon === 'Default'
                      ? classes.iconChosen
                      : classes.icon
                  }
                  onClick={() => setCourseTypeIcon('Default')}
                >
                  <CourseIcon />
                </Button>
                {iconNames.map((iconName) => {
                  return (
                    <Button
                      className={
                        courseTypeIcon === iconName + 'Icon'
                          ? classes.iconChosen
                          : classes.icon
                      }
                      key={iconName}
                      onClick={() => setCourseTypeIcon(iconName + 'Icon')}
                    >
                      <MaterialIcon fontSize="small" iconName={iconName} />
                    </Button>
                  );
                })}
              </Box>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Message id="course.courseAdmin.color" />
              </FormLabel>
              <Box
                mt={1}
                ml={2.5}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box
                  style={{
                    width: 90,
                    height: 90,
                    backgroundColor: color,
                    margin: 'auto',
                    borderRadius: 45,
                  }}
                />
                <TwitterPicker
                  triangle="hide"
                  onChange={handleColorChange}
                  width={276}
                  color={color}
                />
              </Box>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <Message id="handleUser.cancel" />
            </Button>
            <Button type="submit" color="primary">
              <Message id="siteSettings.save" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

type DeleteCourseTypeButtonProps = {
  courseType: CourseType;
  nrOfCourses: number;
};

const DeleteCourseTypeButton = ({
  courseType,
  nrOfCourses,
}: DeleteCourseTypeButtonProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    dispatch(deleteCourseType(courseType));
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        className={classes.editFolderButton}
        startIcon={<DeleteIcon />}
      >
        <Message id="course.courseAdmin.deleteCourseType" />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>
          <Message id="course.courseAdmin.deleteCourseType" />
        </DialogTitle>
        <DialogContent>
          {!nrOfCourses && (
            <Box>
              <Message id="siteSettings.deleteDialog" />
              <Box pt={2}>
                <Typography variant="subtitle2" component="span">
                  <Message id="course.courseAdmin.courseType" />
                  {': '}
                </Typography>
                <Typography component="span">{courseType.name}</Typography>
              </Box>
            </Box>
          )}

          {nrOfCourses > 0 && (
            <Typography variant="body2" component="p" color="error">
              <Message id="course.courseAdmin.deleteErrorCourseType" />
              {courseType.name}!
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            <Message id="handleUser.cancel" />
          </Button>
          <Button
            disabled={nrOfCourses > 0}
            onClick={handleOnClick}
            color="primary"
          >
            <Message id="course.homeAdmin.delete" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
