import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, CircularProgress } from '@material-ui/core';
import AddOrRemoveActor from './AddOrRemoveActor';
import Message from '../../components/translation/Message';
import { Actor, isAdmin, UserDetails } from '../../types';
import {
  actorsUsersDetailsListSelector,
  fetchActorsUsersDetailsList,
  fetchActors,
  actorsSelector,
  removeActorFromUser,
  setActorOnUser,
  deleteInstanceActor,
  permissionsSelector,
  fetchPermissions,
} from '../../store/actorsSlice';
import AddRelation from './AddRelation';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';
import RelationshipDialog from './RelationshipsDialog';
import ActorsUserList from './ActorsUserList';
import AddOrEditActorsDialog from './AddOrEditActorsDialog';
import ActorList from './ActorList';
import NoActors from './NoActors';
import RemoveActorDialog from './RemoveActorDialog';
import { userSelector } from '../../store/userSlice';
import { updateMandatoryCourses } from '../../api/course-api';

const ActorAdminPage = () => {
  const [isAddActorsDialogOpen, setIsAddActorsDialogOpen] =
    useState<boolean>(false);
  const [isRelationshipDialogOpen, setIsRelationshipDialogOpen] =
    useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [actorToEdit, setActorToEdit] = useState<Actor | null>(null);
  const [actorToRemove, setActorToRemove] = useState<Actor | null>(null);

  const actors = useSelector(actorsSelector);
  const actorUsersList = useSelector(actorsUsersDetailsListSelector);
  const permissions = useSelector(permissionsSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!actors) {
      dispatch(fetchActors());
    }
  }, [actors, dispatch]);

  useEffect(() => {
    if (!actorUsersList) {
      dispatch(fetchActorsUsersDetailsList());
    }
  }, [dispatch, actorUsersList]);

  useEffect(() => {
    if (!permissions || permissions.length === 0) {
      dispatch(fetchPermissions());
    }
  }, [dispatch, permissions]);

  const handleDeleteActor = () => {
    if (actorToRemove) {
      dispatch(deleteInstanceActor(actorToRemove.id));
    }
  };

  return (
    <Box pr={4} pb={4} pl={4}>
      {user && isAdmin(user) && (
        <Box pt={4}>
          <PageOrSectionHeader>
            <Message id="handleActors.actors" />
          </PageOrSectionHeader>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsAddActorsDialogOpen(true)}
          >
            <Message id="handleActors.addNewActor" />
          </Button>
          <AddOrEditActorsDialog
            isOpen={isAddActorsDialogOpen || !!actorToEdit}
            actor={actorToEdit}
            onClose={() => {
              setIsAddActorsDialogOpen(false);
              setActorToEdit(null);
            }}
          />
          {actors && actors.length > 0 && (
            <>
              <ActorList
                actors={actors}
                onEdit={setActorToEdit}
                onDelete={setActorToRemove}
              />
              <RemoveActorDialog
                actor={actorToRemove}
                onClose={() => setActorToRemove(null)}
                onDelete={handleDeleteActor}
              />
            </>
          )}
          {(!actors || actors?.length === 0) && <NoActors />}
        </Box>
      )}
      <Box mt={user && isAdmin(user) ? 8 : 4}>
        <PageOrSectionHeader>
          <Message id="handleActors.users" />
        </PageOrSectionHeader>
        {!actorUsersList && <CircularProgress size={64} />}
        {actorUsersList && (
          <ActorsUserList
            actorUsersList={actorUsersList}
            onOpenDialog={() => setIsRelationshipDialogOpen(true)}
            onSetUserDetails={(newUserDetails: UserDetails) =>
              setUserDetails(newUserDetails)
            }
          />
        )}
        <RelationshipDialog
          userDetails={userDetails}
          isOpen={isRelationshipDialogOpen}
          onClose={() => setIsRelationshipDialogOpen(false)}
        />
      </Box>
      {/*ADD Actor to user*/}
      {actorUsersList && actorUsersList.length > 0 && (
        <AddOrRemoveActor
          header={<Message id="handleActors.addActor" />}
          isAdd={true}
          onAddOrRemove={(userId: string, actorId: string) =>
            dispatch(setActorOnUser(userId, actorId))
          }
          id="handleActors.addActor"
          addOrRemove="Add"
        />
      )}
      {/*Remove Actor to user*/}
      {actorUsersList && actorUsersList.length > 0 && (
        <AddOrRemoveActor
          header={<Message id="handleActors.removeActorFromUser" />}
          isAdd={false}
          onAddOrRemove={(userId: string, actorId: string) =>
            dispatch(removeActorFromUser(userId, actorId))
          }
          id="handleActors.removeActorFromUser"
          addOrRemove="Remove"
        />
      )}
      {actorUsersList && actorUsersList.length > 0 && <AddRelation />}
    </Box>
  );
};

export default ActorAdminPage;
