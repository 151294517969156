import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';

import {
  ListRounded,
  Search,
  Sync,
  Tune,
  ViewModuleRounded,
} from '@material-ui/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../components/translation/Message';
import { updateAllUsersCoursesResults } from '../../store/allResultsSlice';
import { activeSubModulesSelector } from '../../store/instanceSlice';
import { resultStateSelector } from '../../store/selectors';
import { userSelector } from '../../store/userSlice';
import { InstanceRole, isAdmin } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { UserCoursesFilter } from './UserCoursesFilter';
import { UserCoursesTable } from './UserCoursesTable';
import { UserCoursesCardsView } from './UserCoursesCardsView';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  paperClass: {
    backgroundColor: 'white',
    marginBottom: 10,
  },
  refresh: {
    margin: 'auto',
  },
  spin: {
    margin: 'auto',
    animation: '$spin 1s 1',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const UserCoursesPage = () => {
  const user = useSelector(userSelector);
  const resultState = useSelector(resultStateSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState<string>('');
  const [showFilters, setShowFilter] = useState(false);
  const [showList, setShowList] = useState(true);

  // This needs to be more centralized
  const [roleFilter, setRoleFilter] = useState<string[]>([]);
  const dispatch = useDispatch();

  if (
    (user && !isAdmin(user)) ||
    !activeSubModules?.Course.includes('ExamCourse')
  ) {
    return <></>;
  }

  const handleRoleFilterChange = (value: string[]) => {
    setRoleFilter(value);
    setShowFilter(false);
  };

  const handleRefresh = () => {
    if (resultState.allUsersCourseResults) {
      dispatch(updateAllUsersCoursesResults());
    }
  };

  const listToRender = () => {
    const filteredByUsername =
      resultState.allUsersCourseResults?.filter(
        (userRes) =>
          userRes.username.toLowerCase().includes(searchInput.toLowerCase()) &&
          userRes.username !== ''
      ) ?? [];

    if (roleFilter.length > 0) {
      // Filter by role
      const filteredByRole = filteredByUsername.filter((userRes) => {
        const roles = userRes.roles as InstanceRole[];
        return roles.some((role) => roleFilter.includes(role));
      });
      return filteredByRole;
    }
    return filteredByUsername;
  };

  return (
    <Box p={{ xs: 1, md: 4 }} style={{ display: 'block' }}>
      <UserCoursesFilter
        isOpen={showFilters}
        onClose={() => setShowFilter(false)}
        roleFilters={roleFilter}
        handleRoleFilter={handleRoleFilterChange}
      />
      <Paper className={classes.paperClass}>
        <Toolbar>
          <Box
            display="flex"
            flexGrow={1}
            flex={1}
            flexDirection="horizontal"
            style={{ alignItems: 'center' }}
          >
            <Typography variant="h6" style={{ marginRight: 5 }}>
              <Message id="nav.handleUserCourses" />
            </Typography>
            <Typography variant="h6" color="textSecondary">
              ({listToRender().length})
            </Typography>
            {!resultState.isLoading && (
              <IconButton onClick={handleRefresh}>
                <Sync color="primary" />
              </IconButton>
            )}
            {!resultState.updatingUserCourseList && showList && (
              <IconButton onClick={() => setShowList(false)}>
                <ViewModuleRounded color="primary" />
              </IconButton>
            )}
            {!resultState.updatingUserCourseList && !showList && (
              <IconButton
                disabled={resultState.isLoading}
                onClick={() => setShowList(true)}
              >
                <ListRounded color="primary" />
              </IconButton>
            )}
            {resultState.updatingUserCourseList && (
              <Typography variant="body2">
                <Message id="handleUserCourses.updating" />
              </Typography>
            )}
          </Box>
          <IconButton>
            <Tune onClick={() => setShowFilter(true)} />
          </IconButton>
          <TextField
            variant="standard"
            type="search"
            style={{
              width:
                getMessage('course.courseAdmin.searchByUserName').length + 200,
            }}
            placeholder={getMessage('course.courseAdmin.searchByUserName')}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Search />
        </Toolbar>
        {showList && <UserCoursesTable listToRender={listToRender()} />}
      </Paper>
      {!showList && <UserCoursesCardsView listToRender={listToRender()} />}
      {resultState.isLoading && <CircularProgress color="primary" size="5em" />}
    </Box>
  );
};

export default UserCoursesPage;
