import {
    Box,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    makeStyles,
    createStyles,
    Theme,
} from '@material-ui/core';
import Message from '../../../components/translation/Message';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingBottom: '1em',
            width: '224px'
        },
        selectlabel: {
            position: 'absolute',
            top: '-7px',
            left: '14px'
        },
        select: {
            padding: '1em',
        },
    })
);

const TimeDropdown = ({ value, onChange }: any) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <FormControl fullWidth>
                <InputLabel className={classes.selectlabel}>
                    <Message id='statistics.showStatisticsFor' />
                </InputLabel>
                <Select
                    label={getMessage('statistics.showStatisticsFor')}
                    value={value}
                    onChange={(event) => onChange(event.target.value as string)}
                    variant='outlined'
                    classes={{ outlined: classes.select }}
                    displayEmpty
                >
                    <MenuItem value='statistics.thisMonth'>
                        <Message id='statistics.thisMonth' />
                    </MenuItem>
                    <MenuItem value='statistics.lastMonth'>
                        <Message id='statistics.lastMonth' />
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export default TimeDropdown;
