import { Box, Typography, TextField, Button, FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Message from "../../../components/translation/Message";
import { getMessage } from "../../../whitelabel-config/WhitelabelProvider";
import EditIcon from '@material-ui/icons/Edit';

type VideoUrlFieldProps = {
    url: string;
    onChange: (videoUrl: string) => void;
    liveStreamIsOn: boolean;
    isLiveStream: boolean;
    onChangeIsLiveStream: (value: boolean) => void;
    onDuration: (seconds: number) => void;
};
const VideoUrlSection = ({
    url,
    onChange,
    liveStreamIsOn,
    isLiveStream,
    onChangeIsLiveStream,
    onDuration
}: VideoUrlFieldProps) => {
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        setVideoUrl(url);
    }, [url]);

    const handleOnChange = () => {
        if (videoUrl && !ReactPlayer.canPlay(videoUrl)) {
            setError('Url is not valid');
        } else {
            setError('');
        }
        onChange(videoUrl);
        setEditing(false);
    };

    const handleLiveStreamChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeIsLiveStream(event.target.checked);
    }

    return (
        <Box>
            <Typography variant='subtitle2'>
                {isLiveStream
                    ? <Message id='course.courseAdmin.editCoursePage.liveStreamUrl' />
                    : <Message id='course.courseAdmin.editCoursePage.videoUrl' />
                }
            </Typography>
            {liveStreamIsOn &&
                <Box mt={1}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isLiveStream}
                                    onChange={handleLiveStreamChange}
                                    name="checkedLiveStreamVideo"
                                    color="primary"
                                    disabled={!editing}
                                />
                            }
                            label={
                                <Typography>
                                    <Message id='course.courseAdmin.editCoursePage.liveStreamUrl' />
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Box>
            }
            {videoUrl &&
                <Box
                    mt={1}
                    maxWidth={800}
                    display='flex'
                >
                    <ReactPlayer
                        url={videoUrl}
                        controls
                        width="100%"
                        height='500px'
                        onDuration={onDuration}
                    />
                    {isLiveStream &&
                        <Box>
                            <iframe
                                title={`Live Stream chat`}
                                src={`${videoUrl}/chat/`}
                                width={340}
                                height="100%"
                                frameBorder="0"
                            >
                            </iframe>
                        </Box>
                    }
                </Box>
            }
            <Box mt={1} display='flex' flexDirection='column' maxWidth={300}>
                <TextField
                    label={videoUrl && getMessage('course.courseAdmin.editCoursePage.videoUrl')}
                    placeholder={getMessage('course.courseAdmin.editCoursePage.noVideo')}
                    size="small"
                    fullWidth
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleOnChange()}
                    error={Boolean(error)}
                    helperText={error}
                    disabled={!editing}
                />
                <Box mt={2} ml={0}>
                    {editing ?
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOnChange}
                        >
                            <Message id="course.courseAdmin.editCoursePage.done" />
                        </Button>
                        :
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => setEditing(true)}
                        >
                            <Message id="course.courseAdmin.editCoursePage.edit" />
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default VideoUrlSection;
