/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { matchSorter } from 'match-sorter';
import { Instructor } from '../types';
import { WhitelabelContext } from '../whitelabel-config/WhitelabelProvider';

type InstructorAutoCompleteProps = {
  value?: InstructorOption;
  instructors: InstructorOption[];
  onAdd: (name: string) => void;
  onChange: (instructor: Instructor) => void;
};

interface InstructorOption extends Instructor {
  inputValue?: string;
}

export default function InstructorAutoComplete({
  instructors,
  onAdd,
  onChange,
}: InstructorAutoCompleteProps) {
  const [value, setValue] = React.useState<InstructorOption | null>(null);
  const { getMessage } = React.useContext(WhitelabelContext);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          onAdd(newValue);
          setValue(null);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onAdd(newValue.inputValue);
          setValue(null);
        } else if (newValue) {
          onChange(newValue);
          setValue(null);
        }
      }}
      filterOptions={(options, { inputValue }) => {
        const filtered = matchSorter(options, inputValue, {
          keys: ['name'],
        });

        // Suggest the creation of a new value
        if (inputValue !== '') {
          filtered.push({
            id: 'new',
            inputValue: inputValue,
            name: `Create "${inputValue}"`,
            title: null,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={instructors}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Add ${getMessage('course.instructor').toLocaleLowerCase()}`}
        />
      )}
    />
  );
}
