import { courseHttpClient as httpClient } from './httpClient';
import {
  Course,
  CoursePreview,
  CourseDTO,
  Provider,
  Instructor,
  Session,
  Document,
  CourseType,
  IconsFromBackend,
  Tab,
  TabDTO,
  VideoData,
  UserAreaOrGroupId,
  CourseCreate,
  AnswerResult,
  UserAnswer,
  ExtendedResult,
  CourseResult,
  UserCourseResult,
  SimplifiedCourse,
} from '../types';
import { AxiosResponse } from 'axios';

export const getCoursePreviews = async (
  courseTypeId: string = ''
): Promise<CoursePreview[]> => {
  const response = await httpClient.get(`course?courseTypeId=${courseTypeId}`);
  return response.data;
};

export const getFeaturedCourses = async (): Promise<CoursePreview[]> => {
  const response = await httpClient.get(`course/featured`);
  return response.data;
};

export const getCoursePreviewsByIds = async (
  courseIds: string[]
): Promise<CoursePreview[]> => {
  let path = '';
  if (courseIds.length > 0) {
    path = path.concat('?');
    courseIds.forEach((id, index) => {
      if (index > 0) path = path.concat('&');
      path = path.concat(`courseIds=${id}`);
    });
  }

  const response = await httpClient.get(`course/ids${path}`);
  return response.data;
};

export const getPrivateCoursePreviews = async (
  privateCourseUserId: string = ''
): Promise<CoursePreview[]> => {
  const response = await httpClient.get(
    `course?privateCourseUserId=${privateCourseUserId}`
  );
  return response.data;
};

export const getCourse = async (id: string): Promise<Course> => {
  const response = await httpClient.get(`course/${id}`);
  return response.data;
};

export const getAllCourses = async (): Promise<SimplifiedCourse[]> => {
  const response = await httpClient.get('course/all');
  return response.data;
};

export const getAllExamCourses = async (): Promise<SimplifiedCourse[]> => {
  const response = await httpClient.get('course/exam/all');
  return response.data;
};

export const getCourseFromSlug = async (slug: string): Promise<Course> => {
  const response = await httpClient.get(`course/slug/${slug}`);
  return response.data;
};

export const createCourse = async (course: CourseCreate): Promise<Course> => {
  const response = await httpClient.post(`course`, course);
  return response.data;
};

export const updateCourse = async (
  courseId: string,
  course: CourseDTO
): Promise<Course> => {
  const response = await httpClient.put(`course/${courseId}`, course);
  return response.data;
};

export const assignCourses = async (
  courseIds: string[],
  privateCourseUserId: string
): Promise<string[]> => {
  const response = await httpClient.put(`course/assigncourses`, {
    courseIds,
    privateCourseUserId,
  });
  return response.data;
};

export const deleteCourse = async (courseId: string) => {
  const response = await httpClient.delete(`course/${courseId}`);
  return response.data;
};

export const uploadPreviewImage = async (
  courseId: string,
  imageFile: File
): Promise<Course> => {
  const formData = new FormData();
  formData.append('image', imageFile);
  const response = await httpClient.post(
    `course/${courseId}/previewImage`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
};

// PROVIDERS
export const getProviders = async (): Promise<Provider[]> => {
  const response = await httpClient.get('course/Provider');
  return response.data;
};

export const createProvider = async (name: string): Promise<Provider> => {
  const response = await httpClient.post('course/Provider', { name });
  return response.data;
};

export const updateProvider = async (provider: Provider): Promise<Provider> => {
  const response = await httpClient.put(
    `course/Provider/${provider.id}`,
    provider
  );
  return response.data;
};

export const deleteProvider = async (providerId: string) => {
  const response = await httpClient.delete(`course/Provider/${providerId}`);
  return response.data;
};

export const getInstructors = async (): Promise<Instructor[]> => {
  const response = await httpClient.get('course/Instructor');
  return response.data;
};

// INSTRUCTORS
export const createInstructor = async (
  providerId: string,
  name: string,
  title: string | null
): Promise<Instructor> => {
  const response = await httpClient.post(`course/Instructor/${providerId}`, {
    name,
    title,
  });
  const updatedProvider: Provider = response.data;
  return updatedProvider.instructors.find((i) => i.name === name) as Instructor;
};

export const updateInstructor = async (
  providerId: string,
  instructor: Instructor
): Promise<Provider> => {
  const response = await httpClient.put(
    `course/Instructor/${providerId}/${instructor.id}`,
    instructor
  );
  return response.data;
};

export const deleteInstructor = async (
  providerId: string,
  instructorId: string
) => {
  const response = await httpClient.delete(
    `course/Instructor/${providerId}/${instructorId}`
  );
  return response.data;
};

// SESSIONS
export const createSession = async (
  courseId: string,
  session: Omit<Session, 'id'>
): Promise<Session> => {
  const response = await httpClient.post(
    `course/Session/${courseId}/${session.type}`,
    session
  );
  return response.data;
};

export const updateSession = async (session: Session): Promise<Session> => {
  const response = await httpClient.put(
    `course/Session/${session.type}/${session.id}`,
    session
  );
  return response.data;
};

export const deleteSession = async (sessionId: string) => {
  const response = await httpClient.delete(`course/Session/${sessionId}`);
  return response.data;
};

export const getSessionDocument = async (
  sessionId: string,
  documentId: string | undefined
): Promise<Blob> => {
  const response = await httpClient.get<Blob>(
    `course/SessionDocument/${sessionId}/document/${documentId}`,
    {
      responseType: 'blob',
    }
  );
  return response.data;
};

export const getSessionDocumentPdf = async (
  sessionId: string,
  documentId: string | undefined
): Promise<Blob> => {
  const response = await httpClient.get<Blob>(
    `course/SessionDocument/${sessionId}/document/${documentId}/pdf`,
    {
      responseType: 'blob',
    }
  );
  return response.data;
};

export const updateSessionDocument = async (
  sessionId: string,
  document: Document
): Promise<Document> => {
  const response = await httpClient.put(
    `course/SessionDocument/${sessionId}/document/${document.id}?isFileDownloadable=${document.isFileDownloadable}`,
    { name: document.name }
  );
  return response.data;
};

export const deleteSessionDocument = async (
  sessionId: string,
  documentId: string
) => {
  const response = await httpClient.delete(
    `course/SessionDocument/${sessionId}/document/${documentId}`
  );
  return response.data;
};

// TABS
export const getTabs = async ({
  userAreaId,
  groupId,
}: UserAreaOrGroupId): Promise<Tab[]> => {
  let path = '';

  if (userAreaId) {
    path = `?privateUserId=${userAreaId}`;
  }

  if (groupId) {
    path = `?groupId=${groupId}`;
  }

  const response = await httpClient.get(`course/Tab${path}`);
  return response.data;
};

export const createTab = async (
  createObj: TabDTO,
  userOrGroupId: UserAreaOrGroupId
): Promise<Tab[]> => {
  let path = '';
  if (userOrGroupId.userAreaId) {
    path = `?privateUserId=${userOrGroupId.userAreaId}`;
  }
  if (userOrGroupId.groupId) {
    path = `?groupId=${userOrGroupId.groupId}`;
  }

  const response = await httpClient.post(`course/Tab${path}`, createObj);
  return response.data;
};

export const updateTab = async (
  tabId: string,
  updateObj: TabDTO
): Promise<Tab> => {
  const response = await httpClient.put(`course/Tab/${tabId}`, updateObj);
  return response.data;
};

export const deleteTab = async (tabId: string): Promise<Tab> => {
  const response = await httpClient.delete(`course/Tab/${tabId}`);
  return response.data;
};

// TAB VIDEOS
export const getTabVideos = async (tabId: string): Promise<VideoData[]> => {
  const response = await httpClient.get(`course/Tab/videometadata/${tabId}`);
  return response.data;
};

export const getVideoBlob = async (videoUrl: string): Promise<Blob> => {
  const response = await httpClient.get<Blob>(`${videoUrl}`, {
    responseType: 'blob',
  });
  return response.data;
};

export const addTabVideo = async (
  video: File,
  tabId: string,
  privateUserId?: string,
  groupId?: string
): Promise<string> => {
  const formData = new FormData();
  formData.append('video', video);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await httpClient.post(
    `course/Tab/uploadvideo?${
      privateUserId ? 'privateUserId=' + privateUserId : ''
    }${groupId ? 'groupId=' + groupId : ''}&tabId=${tabId}`,
    formData,
    config
  );
  return response.data;
};

export const publishTabVideo = async (publishObj: {
  videoId: string;
  tabId: string;
  publish: boolean;
}): Promise<Tab> => {
  const response = await httpClient.put(`course/Tab/publish`, publishObj);
  return response.data;
};

export const deleteTabVideo = async (
  videoId: string,
  tabId: string
): Promise<boolean> => {
  const response = await httpClient.delete(
    `course/Tab/video/${videoId}?tabId=${tabId}`
  );
  return response.data;
};

// COURSETYPES
export const createCourseType = async (
  name: string,
  icon: string
): Promise<CourseType> => {
  const response = await httpClient.post(`course/courseType`, { name, icon });
  return response.data;
};

export const getCourseTypes = async (): Promise<CourseType[]> => {
  const response = await httpClient.get(`course/courseType`);
  return response.data;
};

export const updateCourseType = async (
  courseType: CourseType
): Promise<CourseType> => {
  const response = await httpClient.put(
    `course/courseType/${courseType.id}`,
    courseType
  );
  return response.data;
};

export const updateCourseTypeOrder = async (
  courseTypes: CourseType[]
): Promise<void> => {
  await httpClient.put(`course/courseType/order`, courseTypes);
};

export const deleteCourseType = async (
  courseTypeId: string
): Promise<CourseType> => {
  const response = await httpClient.delete(`course/courseType/${courseTypeId}`);
  return response.data;
};

export const getCourseTypeIconList = async (): Promise<IconsFromBackend> => {
  const response = await httpClient.get(`course/courseType/icons`);
  return response.data;
};

// EXAM
export const fetchMandatoryCourses = async (
  userId?: string
): Promise<SimplifiedCourse[]> => {
  let url = 'course/mandatory';
  if (userId) {
    url += `?userId=${userId}`;
  }
  const response = await httpClient.get<SimplifiedCourse[]>(url);
  return response.data;
};

export const updateMandatoryCourses = async (
  mandatoryCourses: SimplifiedCourse[],
  userId?: string
): Promise<AxiosResponse<SimplifiedCourse[]>> => {
  let url = 'course/mandatory';
  if (userId) {
    url += `?userId=${userId}`;
  }
  return await httpClient.put<SimplifiedCourse[]>(url, mandatoryCourses);
};

export const submitAnswers = async (
  sessionId: string,
  userAnswers: UserAnswer[]
): Promise<ExtendedResult> => {
  const response = await httpClient.put<ExtendedResult>(
    `course/exam/submit/${sessionId}`,
    userAnswers
  );
  return response.data;
};

export const fetchResultsBySession = async (
  sessionId: string
): Promise<AnswerResult | null> => {
  const response = await httpClient.get<AnswerResult>(
    `course/exam/results/${sessionId}`
  );
  return response.status === 200 ? response.data : null;
};

export const fetchResultsByCourse = async (
  courseId: string
): Promise<CourseResult | null> => {
  const response = await httpClient.get<CourseResult>(
    `course/exam/results/course/${courseId}`
  );
  return response.status === 200 ? response.data : null;
};

export const fetchAllUserResultsByCourse = async (
  courseId: string
): Promise<CourseResult[] | null> => {
  const response = await httpClient.get<CourseResult[]>(
    `course/exam/results/course/${courseId}/all`
  );
  return response.status === 200 ? response.data : null;
};

export const fetchAllResultsBySession = async (
  sessionId: string
): Promise<AxiosResponse<ExtendedResult[]>> => {
  return await httpClient.get<ExtendedResult[]>(
    `course/exam/results/${sessionId}/all`
  );
};

export const fetchAllUserCourseResults = async (): Promise<
  AxiosResponse<CourseResult[]>
> => {
  return await httpClient.get<CourseResult[]>('course/exam/results/course/all');
};

export const fetchCourseResultsForAllUsers = async (): Promise<
  AxiosResponse<UserCourseResult[]>
> => {
  return await httpClient.get<UserCourseResult[]>(
    `course/exam/results/course/allUsers`
  );
};

export const correctFreeTextRequest = async (
  sessionId: string,
  userId: string,
  pass: boolean,
  questionIndex: number
): Promise<void> => {
  return await httpClient.put(
    `course/exam/${sessionId}/correct/${userId}/freeText?pass=${pass}&index=${questionIndex}`
  );
};

export const uploadOptionImage = async (
  sessionId: string,
  imageFile: File
): Promise<string | null> => {
  const formData = new FormData();
  formData.append('image', imageFile);
  const response = await httpClient
    .post(`course/exam/${sessionId}/answerImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((err: any) => {
      return { data: null };
    });
  return response.data;
};

export const deleteOptionImage = async (fileName: string): Promise<boolean> => {
  const response = await httpClient.delete(
    `course/exam/${fileName}/answerImage`
  );
  return response.data;
};

export const deleteUsersAnswer = async (
  sessionId: string,
  index: number
): Promise<void> => {
  const response = await httpClient.delete(
    `course/exam/${sessionId}/question/${index}`
  );
  return response.data;
};
