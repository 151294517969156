import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Button,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Message from '../../components/translation/Message';
import { Actor, ActorsUsersDetails } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import {
  actorsUsersDetailsListSelector,
  assignableActorsSelector,
  assignableUserForRelationshipSelector,
  fetchAssignableActors,
  fetchAssignableUsersBasedOnRelationship,
  addRelationshipErrorSelector,
  setRelationship,
  setHasRelationshipError
} from '../../store/actorsSlice';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actorList: {
      width: 1095,
      maxWidth: '100%',
    },
    saveBtn: {
      alignSelf: 'center',
      marginLeft: '25px',
    },
  })
);

const AddRelation = () => {
  const [fromUser, setFromUser] = useState<ActorsUsersDetails | null>(null);
  const [actorToUser, setActorToUser] = useState<Actor | null>(null);
  const [toUser, setToUser] = useState<ActorsUsersDetails | null>(null);

  const actorUsersList = useSelector(actorsUsersDetailsListSelector);
  const assignableActors = useSelector(assignableActorsSelector);
  const assignableUsers = useSelector(assignableUserForRelationshipSelector);
  const addRelationshipError = useSelector(addRelationshipErrorSelector);

  const dispatch = useDispatch();
  const classes = useStyles();

  const disableSelect = () => {
    if (assignableActors.length <= 0 || !fromUser || !actorToUser) {
      return true as boolean;
    }
    return false as boolean;
  };

  const handleSave = () => {
    if (fromUser && toUser && actorToUser) {
      dispatch(setRelationship(fromUser, toUser, actorToUser));
      setFromUser(null);
      setActorToUser(null);
      setToUser(null);
    }
  }

  return (
    <Box>
      {actorUsersList && actorUsersList.length > 0 && (
        <Box mt={8}>
          <PageOrSectionHeader>
            <Message id='handleActors.addRelationship' />
          </PageOrSectionHeader>
          <Box display={'flex'}>
            <Autocomplete
              id="from-user-relation"
              onChange={(e, value, reason) => {
                if (reason === 'select-option' && value) {
                  setFromUser(value);
                  dispatch(fetchAssignableActors());
                  dispatch(setHasRelationshipError(false));
                  setActorToUser(null);
                } else {
                  setFromUser(null);
                  setActorToUser(null);
                }
              }}
              options={actorUsersList}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${getMessage('handleActors.fromUser')}`}
                  variant="outlined"
                />
              )}
              value={fromUser}
            />

            <Autocomplete
              id="actors-relation"
              options={assignableActors.filter(
                (actor) => actor.assignableAsRelationship
              )}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              style={{ width: 300 }}
              disabled={!fromUser}
              onChange={(e, value, reason) => {
                if (reason === 'select-option' && value) {
                  setActorToUser(value);
                  dispatch(fetchAssignableUsersBasedOnRelationship(value.id));
                } else {
                  setActorToUser(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${getMessage('handleActors.actor')}`}
                  variant="outlined"
                />
              )}
              value={actorToUser}
            />
            <Autocomplete
              id="to-user-relation"
              options={assignableUsers}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              style={{ width: 300 }}
              disabled={disableSelect()}
              onChange={(e, value, reason) => {
                if (reason === 'select-option' && value) {
                  setToUser(value);
                } else {
                  setToUser(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${getMessage('handleActors.toUser')}`}
                  variant="outlined"
                />
              )}
              value={toUser}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.saveBtn}
              disabled={disableSelect() || !toUser}
              onClick={handleSave}
            >
              <Message id="siteSettings.save" />
            </Button>
          </Box>
          {addRelationshipError && (
            <Typography variant="body2" component="p" color="error">
              <Message id="handleActors.relationAlreadyExistError" />
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AddRelation;