import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  Tooltip,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridRowModel,
} from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSelector, useDispatch } from 'react-redux';
import {
  usersSelector,
  usersIsLoadingSelector,
  fetchUsers,
  rolesSelector,
  fetchRoles,
  rolesIsLoadingSelector,
  inviteUserIsLoadingSelector,
  inviteUserErrorSelector,
  usersErrorSelector,
  inviteUser,
  updateUser,
  deleteUser,
  userSelector,
  fetchUser,
  fetchUserProfile,
  currentUserProfileSelector,
  currentUserProfileLoadingSelector,
  updateProfile,
  setCurrentUserProfile,
} from '../../store/userSlice';
import { InstanceRole, InstanceUser, isAdmin, isSuperAdmin } from '../../types';
import Message from '../../components/translation/Message';
import AccountCircleIcon from '../../components/icons/AccountCircleIcon';
import UserProfile from '../../components/UserProfile';
import DataGridHeader from '../../components/DataGridHeader';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import DeleteButton from '../../components/DeleteButton';
import { resendInvitations } from '../../api/user-api';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';
import {
  adminLimitSelector,
  userLimitSelector,
} from '../../store/instanceSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userList: {
      height: 680,
      width: 1022,
      maxWidth: '100%',
    },
    inviteInput: {
      width: '18em',
    },
    inviteRoles: {
      minWidth: '18em',
    },
    inviteButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    invite: {
      maxWidth: 'fit-content',
      minWidth: '25em',
      border: '1px solid rgba(224, 224, 224, 1)',
      borderRadius: '4px',
      padding: theme.spacing(1.5),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      '& > button': {
        marginLeft: '10px',
      },
    },
    rolesCell: {
      cursor: 'pointer',
      width: '175px',
    },
    tableCellTrucate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inherit',
    },
    errorBtn: {
      marginLeft: theme.spacing(1),
    },
    removeUser: {
      color: 'red',
      display: 'block',
      cursor: 'pointer',
    },
    profileAvatar: {
      display: 'block',
      cursor: 'pointer',
    },
    disabledBackground: {
      backgroundColor: theme.palette.grey[100],
    },
  })
);

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: '13px',
  },
}))(Tooltip);

const HandleUsers = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(userSelector);
  const users = useSelector(usersSelector);
  const usersLoading = useSelector(usersIsLoadingSelector);
  const roles = useSelector(rolesSelector);
  const nonSuperAdminRoles = useSelector(rolesSelector)?.filter(
    (obj) => obj.toLowerCase() !== 'superadmin'
  );
  const rolesLoading = useSelector(rolesIsLoadingSelector);
  const inviteUserLoading = useSelector(inviteUserIsLoadingSelector);
  const inviteUserError = useSelector(inviteUserErrorSelector);
  const usersError = useSelector(usersErrorSelector);
  const currentUserProfile = useSelector(currentUserProfileSelector);
  const currentUserProfileLoading = useSelector(
    currentUserProfileLoadingSelector
  );
  const userLimit = useSelector(userLimitSelector);
  const adminLimit = useSelector(adminLimitSelector);

  const [userRoles, setUserRoles] = useState<InstanceRole[]>(['User']);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const [userToUpdate, setUserToUpdate] = useState<InstanceUser>();
  const [chosenUserProfile, setChosenUserProfile] = useState<InstanceUser>();

  const classes = useStyles();

  useEffect(() => {
    if (!usersLoading && !users && !usersError) {
      dispatch(fetchUsers());
    }
  }, [dispatch, users, usersError, usersLoading]);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!rolesLoading && !roles) {
      dispatch(fetchRoles());
    }
  }, [dispatch, roles, rolesLoading]);

  const rows = useMemo(() => {
    if (!users) {
      return [];
    }
    return users.map((user) => ({
      ...user,
      roles: user.roles.join(', '),
      emailConfirmed: user.emailConfirmed === true ? 'Yes' : 'No',
    }));
  }, [users]);

  const hasReachedUserLimit = () => {
    return !!(users && userLimit && users.length >= userLimit);
  };

  const hasReachedAdminLimit = () => {
    const adminUsers = getAdminUsers();
    return !!(adminUsers && adminLimit && adminUsers.length >= adminLimit);
  };

  const getAdminUsers = () => {
    return users?.filter((u) => u.roles.includes('admin')) || [];
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail((event.target as HTMLInputElement).value);
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName((event.target as HTMLInputElement).value);
  };

  const handleRoles = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (
      hasReachedAdminLimit() &&
      (event.target.value as string[]).includes('Admin')
    )
      return;
    setUserRoles(event.target.value as InstanceRole[]);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const onComplete = (success: boolean) => {
      if (success) {
        enqueueSnackbar(<Message id="handleUser.userInvitedSucces" />, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(<Message id="handleUser.userInvitedFailure" />, {
          variant: 'error',
        });
      }
    };
    const newUser = {
      email: userEmail,
      name: userName,
      roles: userRoles,
    };
    dispatch(inviteUser(newUser, onComplete));
    clearInvite();
  };

  const clearInvite = () => {
    setUserName('');
    setUserEmail('');
    setUserRoles(['User']);
  };

  const showUser = (user: GridRowModel) => {
    const userExist = users?.find((u) => u.id === user.id);
    if (userExist && roles) {
      const copyUser = {
        ...userExist,
        roles: userExist.roles.map((role) =>
          roles.find((r) => r.toLowerCase() === role)
        ),
      } as InstanceUser;
      setUserToUpdate(copyUser);
      setOpen(true);
    }
  };

  const onDelete = (userExternalId: string) => {
    dispatch(deleteUser(userExternalId));
  };

  const handleChangeRoles = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (
      hasReachedAdminLimit() &&
      (event.target.value as string[]).includes('Admin')
    )
      return;
    const updateUser = {
      ...userToUpdate,
      roles: event.target.value as InstanceRole[],
    } as InstanceUser;
    setUserToUpdate(updateUser);
  };

  const handleUpdateUser = () => {
    setOpen(false);
    if (userToUpdate) {
      const updateUserRoles = {
        email: userToUpdate.email,
        name: userToUpdate.name,
        roles: userToUpdate.roles.filter((r) => r),
      };
      dispatch(updateUser(updateUserRoles));
    }
  };

  const handleCancelUpdate = () => {
    setOpen(false);
    setUserToUpdate(undefined);
  };

  const handleResendInvitations = () => {
    resendInvitations()
      .then((usersThatHaveReceivedNewEmail) =>
        enqueueSnackbar(<Message id="handleUser.resentInvitations" />, {
          variant: 'success',
        })
      )
      .catch((error) =>
        enqueueSnackbar(<Message id="handleUser.userInvitedFailure" />, {
          variant: 'error',
        })
      );
  };

  const columns: GridColDef[] = [
    {
      field: 'profile',
      renderHeader: () => <Box pt={4} pb={4} />,
      width: 62,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div
          onClick={() => {
            dispatch(fetchUserProfile(params.row.id));
            setChosenUserProfile(params.row as InstanceUser);
            setOpenProfile(true);
          }}
        >
          <AccountCircleIcon className={classes.profileAvatar} />
        </div>
      ),
    },
    {
      field: 'name',
      renderHeader: () => (
        <DataGridHeader text={getMessage('handleUser.name')} />
      ),
      flex: 1,
    },
    {
      field: 'email',
      renderHeader: () => (
        <DataGridHeader text={getMessage('handleUser.email')} />
      ),
      flex: 1,
    },
    {
      field: 'roles',
      renderHeader: () => (
        <DataGridHeader text={getMessage('handleUser.roles')} />
      ),
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <div onClick={() => showUser(params.row)} className={classes.rolesCell}>
          <HtmlTooltip title={params.value as string}>
            <span className={classes.tableCellTrucate}>{params.value}</span>
          </HtmlTooltip>
        </div>
      ),
    },
    {
      field: 'emailConfirmed',
      renderHeader: () => (
        <DataGridHeader text={getMessage('handleUser.confirmedEmail')} />
      ),
      width: 152,
    },
    {
      field: 'removeUser',
      renderHeader: () => <Box pt={4} pb={4} />,
      sortable: false,
      width: 63,
      renderCell: (params: GridCellParams) => (
        <Box display="flex" justifyContent="center" width="100%">
          {user && isAdmin(user) && (
            <DeleteButton
              itemCategory={getMessage('handleUser.user')}
              itemName={params.row.name}
              dialogTitle={getMessage('handleUser.deleteUser')}
              onDelete={() => onDelete(params.row.id)}
              icon={<HighlightOffIcon />}
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box p={4}>
      <PageOrSectionHeader>
        <Message id="handleUser.users" />
      </PageOrSectionHeader>
      {(usersLoading || (!users && !usersError)) && (
        <CircularProgress size={64} />
      )}
      {users && (
        <Box className={classes.userList}>
          <DataGrid rows={rows} columns={columns} pageSize={10} />
        </Box>
      )}
      {usersError && !usersLoading && (
        <Box my={1} display="flex" alignItems="center">
          <Typography color="error" variant="subtitle2">
            {usersError}
          </Typography>{' '}
          <Button onClick={() => dispatch(fetchUsers())}>Try again</Button>
        </Box>
      )}
      <Box pt={4}>
        <PageOrSectionHeader>
          <Message id="handleUser.inviteUsers" />
        </PageOrSectionHeader>
        <Box mb={1}>
          {hasReachedUserLimit() && (
            <Typography color="error">
              <Message id="handleUser.allowedUserLimitReached1" />
              {userLimit}
              <Message id="handleUser.allowedUserLimitReached2" />
            </Typography>
          )}
          <Box>
            {userLimit && !hasReachedUserLimit() && (
              <Typography>
                <Message id="handleUser.allowedUserLimit" />
                {users?.length + ' '}
                <Message id="handleUser.of" />
                {' ' + userLimit}.
              </Typography>
            )}
            {hasReachedAdminLimit() && (
              <Typography color="error">
                <Message id="handleUser.allowedAdminLimitReached1" />
                {adminLimit}
                <Message id="handleUser.allowedAdminLimitReached2" />
              </Typography>
            )}
            {adminLimit && !hasReachedAdminLimit() && (
              <Typography>
                <Message id="handleUser.allowedAdminLimit" />
                {getAdminUsers().length + ' '}
                <Message id="handleUser.of" />
                {' ' + adminLimit}.
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          className={`${classes.invite} ${
            hasReachedUserLimit() && classes.disabledBackground
          }`}
        >
          <form onSubmit={handleSubmit}>
            <Box mb={4} mt={2}>
              <InputLabel required id="users-email-label">
                <Message id="handleUser.email" />
              </InputLabel>
              <TextField
                required
                type="email"
                onChange={handleEmail}
                className={classes.inviteInput}
                value={userEmail}
                disabled={hasReachedUserLimit()}
              />
            </Box>
            <Box mb={4}>
              <InputLabel required id="users-name-label">
                <Message id="handleUser.name" />
              </InputLabel>
              <TextField
                required
                className={classes.inviteInput}
                onChange={handleName}
                value={userName}
                disabled={hasReachedUserLimit()}
              />
            </Box>
            <Box mb={4}>
              <InputLabel id="users-roles-label">
                <Message id="handleUser.roles" />
              </InputLabel>
              {/*IF YOU ARE SUPER ADMIN*/}
              {user && isSuperAdmin(user) && (
                <Select
                  labelId="users-roles-label"
                  id="users-roles"
                  multiple
                  value={userRoles}
                  onChange={handleRoles}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  className={classes.inviteRoles}
                  disabled={hasReachedUserLimit()}
                >
                  {roles?.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      style={{
                        cursor:
                          role === 'Admin' && hasReachedAdminLimit()
                            ? 'default'
                            : 'pointer',
                      }}
                    >
                      <Checkbox
                        checked={userRoles.indexOf(role) > -1}
                        disabled={role === 'Admin' && hasReachedAdminLimit()}
                      />
                      <ListItemText primary={role} color="inherit" />
                    </MenuItem>
                  ))}
                </Select>
              )}
              {/*IF YOU ARE NOT SUER ADMIN*/}
              {user && !isSuperAdmin(user) && (
                <Select
                  labelId="users-roles-label"
                  id="users-roles"
                  multiple
                  value={userRoles}
                  onChange={handleRoles}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  className={classes.inviteRoles}
                  disabled={hasReachedUserLimit()}
                >
                  {nonSuperAdminRoles?.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      style={{
                        cursor:
                          role === 'Admin' && hasReachedAdminLimit()
                            ? 'default'
                            : 'pointer',
                      }}
                    >
                      <Checkbox
                        checked={userRoles.indexOf(role) > -1}
                        disabled={role === 'Admin' && hasReachedAdminLimit()}
                      />
                      <ListItemText primary={role} color="inherit" />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>

            <Box className={classes.inviteButton}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={inviteUserLoading || hasReachedUserLimit()}
                >
                  <Message id="handleUser.sendInvite" />
                </Button>
                {inviteUserLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </form>
        </Box>
        {inviteUserError && (
          <Box>
            <Typography
              variant="body2"
              component="p"
              color="error"
              gutterBottom
            >
              {inviteUserError}
            </Typography>
          </Box>
        )}
      </Box>
      <Box pt={4}>
        <PageOrSectionHeader>
          <Message id="handleUser.resendInvitationsHeading" />
        </PageOrSectionHeader>
        <Box mb={2}>
          <Typography>
            <Message id="handleUser.resendInvitationsDesc" />
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleResendInvitations}
        >
          <Message id="handleUser.resendInvitationsBtn" />
        </Button>
      </Box>
      {userToUpdate && (
        <Dialog
          aria-labelledby="confirmation-dialog-title"
          open={open}
          onClose={handleCancelUpdate}
        >
          <DialogTitle id="confirmation-dialog-title">
            <Box mt={1}>
              <Message id="handleUser.changeRoles" />
              {` ${userToUpdate.name}`}
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box mb={4}>
              <Box mb={2}>
                {hasReachedAdminLimit() && (
                  <Typography color="error">
                    <Message id="handleUser.allowedAdminLimitReached1" />
                    {adminLimit}
                    <Message id="handleUser.allowedAdminLimitReached2" />
                  </Typography>
                )}
                {adminLimit && !hasReachedAdminLimit() && (
                  <Typography>
                    <Message id="handleUser.allowedAdminLimit" />
                    {getAdminUsers().length + ' '}
                    <Message id="handleUser.of" />
                    {' ' + adminLimit}.
                  </Typography>
                )}
              </Box>
              <InputLabel id="users-roles-label">
                <Typography variant="subtitle2">
                  <Message id="handleUser.roles" />
                </Typography>
              </InputLabel>
              {/*IF YOU ARE SUPER ADMIN*/}
              {user && isSuperAdmin(user) && (
                <Select
                  labelId="users-roles-label"
                  id="users-roles"
                  multiple
                  value={userToUpdate.roles}
                  onChange={handleChangeRoles}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  className={classes.inviteRoles}
                >
                  {roles?.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      style={{
                        cursor:
                          role === 'Admin' && hasReachedAdminLimit()
                            ? 'default'
                            : 'pointer',
                      }}
                    >
                      <Checkbox
                        checked={userToUpdate.roles.indexOf(role) > -1}
                        disabled={role === 'Admin' && hasReachedAdminLimit()}
                      />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              )}
              {/*IF YOU ARE NOT SUPER ADMIN*/}
              {user && !isSuperAdmin(user) && (
                <Select
                  labelId="users-roles-label"
                  id="users-roles"
                  multiple
                  value={userToUpdate.roles}
                  onChange={handleChangeRoles}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  className={classes.inviteRoles}
                >
                  {nonSuperAdminRoles?.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      style={{
                        cursor:
                          role === 'Admin' && hasReachedAdminLimit()
                            ? 'default'
                            : 'pointer',
                      }}
                    >
                      <Checkbox
                        checked={userToUpdate.roles.indexOf(role) > -1}
                        disabled={role === 'Admin' && hasReachedAdminLimit()}
                      />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={2} display="flex">
              <Button onClick={handleCancelUpdate}>
                <Message id="handleUser.cancel" />
              </Button>
              <Box mr={1} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateUser}
              >
                <Message id="handleUser.confirm" />
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      {chosenUserProfile && (
        <UserProfile
          user={chosenUserProfile}
          changeable={true}
          open={openProfile}
          onClose={() => {
            setOpenProfile(false);
            if (!openProfile) {
              dispatch(setCurrentUserProfile(null));
            }
          }}
          userProfileData={currentUserProfile}
          updateProfile={(value) =>
            dispatch(updateProfile(value, chosenUserProfile.id))
          }
          isLoading={currentUserProfileLoading}
          emailConfirmed={chosenUserProfile.emailConfirmed}
        />
      )}
    </Box>
  );
};

export default HandleUsers;
