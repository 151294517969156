import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  User,
  InstanceUser,
  TermsAndConditions,
  InviteUser,
  InstanceRole,
  MyUserProfile,
  Statistic,
  StatisticParams,
} from '../types';
import { AppThunk } from './index';
import { RootState } from './rootReducer';
import {
  getUser,
  getTermsAndConditions,
  approveTermsAndConditions as approveTermsAndConditionsAPI,
  getUsers,
  getRoles,
  inviteUser as inviteUserAPI,
  getHonorifics,
  getUserProfileRoles,
  updateProfile as updateProfileAPI,
  deleteUser as deleteUserAPI,
  getUserProfil as getUserProfilAPI,
  getStatistics as getStatisticsAPI,
  resendUserInvite as resendUserInviteAPI,
} from '../api/user-api';

export interface UserState {
  user: User | null;
  users: InstanceUser[] | null;
  usersLoading: boolean;
  usersError: string | null;
  userStatisticsLoading: string[];
  userStatistics: { [userId: string]: Statistic[] } | null;
  termsAndConditions?: TermsAndConditions;
  isLoading: boolean;
  termsAndConditionsLoading: boolean;
  error: string | null;
  roles: InstanceRole[] | null;
  rolesLoading: boolean;
  inviteUser: InviteUser | null;
  inviteUserError: string | null;
  inviteUserLoading: boolean;
  resendInviteLoading: boolean;
  openProfile: boolean;
  currentUserProfile: MyUserProfile | null;
  currentUserProfileLoading: boolean;
  profileData: { honorifics: string[] | []; roles: string[] | [] };
}

const initialState = {
  user: null,
  isLoading: false,
  termsAndConditionsLoading: false,
  users: null,
  usersLoading: false,
  usersError: null,
  error: null,
  roles: null,
  userStatisticsLoading: [],
  userStatistics: null,
  rolesLoading: false,
  inviteUser: null,
  inviteUserError: null,
  inviteUserLoading: false,
  resendInviteLoading: false,
  openProfile: false,
  currentUserProfile: null,
  currentUserProfileLoading: false,
  profileData: { honorifics: [], roles: [] },
} as UserState;

function startLoading(state: UserState) {
  state.isLoading = true;
}

function loadingFailed(state: UserState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getUserStart: startLoading,
    getUserSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getUserFailure: loadingFailed,
    getTermsAndConditionsStart(state) {
      state.termsAndConditionsLoading = true;
    },
    setTermsAndConditions(state, action: PayloadAction<TermsAndConditions>) {
      state.termsAndConditions = action.payload;
      state.termsAndConditionsLoading = false;
    },
    getUsersStart(state) {
      state.usersLoading = true;
    },
    getUsersSuccess(state, action: PayloadAction<InstanceUser[]>) {
      state.users = action.payload;
      state.usersLoading = false;
      state.usersError = null;
    },
    getUsersFailure(state, action: PayloadAction<string>) {
      state.usersLoading = false;
      state.usersError = action.payload;
    },
    getRolesStart(state) {
      state.rolesLoading = true;
    },
    getRolesSuccess(state, action: PayloadAction<InstanceRole[]>) {
      state.roles = action.payload;
      state.rolesLoading = false;
    },
    setInviteUserFailure(state, action: PayloadAction<string>) {
      state.inviteUserError = action.payload;
    },
    setInviteUserLoading(state, action: PayloadAction<boolean>) {
      state.inviteUserLoading = action.payload;
      state.inviteUserError = null;
    },
    addUserStatisticsLoading(state, action: PayloadAction<string>) {
      if (!state.userStatisticsLoading.includes(action.payload)) {
        state.userStatisticsLoading.push(action.payload);
      }
    },
    removeUserStatisticsLoading(state, action: PayloadAction<string>) {
      if (state.userStatisticsLoading.includes(action.payload)) {
        state.userStatisticsLoading = state.userStatisticsLoading.filter(
          (userId) => userId !== action.payload
        );
      }
    },
    setUserStatistic(
      state,
      action: PayloadAction<{ userId: string; stats: Statistic[] }>
    ) {
      state.userStatistics = {
        ...state.userStatistics,
        [action.payload.userId]: action.payload.stats,
      };
    },
    addUserToUsers(state, action: PayloadAction<InstanceUser>) {
      state.users!.push(action.payload);
    },
    updateUserRoles(state, action: PayloadAction<InstanceUser>) {
      const user = action.payload;
      if (state.users) {
        state.users = state.users?.map((u) => (u.id === user.id ? user : u));
      }
    },
    showProfile(state, action: PayloadAction<boolean>) {
      state.openProfile = action.payload;
    },
    addProfileData(
      state,
      action: PayloadAction<{ honorifics: string[]; roles: string[] }>
    ) {
      state.profileData = action.payload;
    },
    setUserProfileData(state, action: PayloadAction<MyUserProfile>) {
      if (!state.user) {
        return;
      }
      state.user.userProfile = action.payload;
    },
    updateDeletedUser(state, action: PayloadAction<string>) {
      if (state.users)
        state.users = state.users.filter((user) => user.id !== action.payload);
    },
    setCurrentUserProfile(state, action: PayloadAction<MyUserProfile | null>) {
      state.currentUserProfile = action.payload;
    },
    setCurrentUserProfileLoading(state, action: PayloadAction<boolean>) {
      state.currentUserProfileLoading = action.payload;
    },
    resendUserInviteStart(state) {
      state.resendInviteLoading = true;
    },
    resendUserInviteSuccess(state) {
      state.resendInviteLoading = false;
    },
    resendUserInviteFail(state) {
      state.inviteUserError = 'Could not send invite';
      state.resendInviteLoading = false;
    },
  },
});

export const {
  getUserStart,
  getUserSuccess,
  getUserFailure,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure,
  getTermsAndConditionsStart,
  setTermsAndConditions,
  getRolesStart,
  getRolesSuccess,
  setInviteUserFailure,
  setInviteUserLoading,
  addUserToUsers,
  updateUserRoles,
  showProfile,
  addProfileData,
  setUserProfileData,
  updateDeletedUser,
  setCurrentUserProfile,
  setCurrentUserProfileLoading,
  addUserStatisticsLoading,
  removeUserStatisticsLoading,
  setUserStatistic,
  resendUserInviteStart,
  resendUserInviteSuccess,
  resendUserInviteFail,
} = userSlice.actions;

export default userSlice.reducer;

export const fetchUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserStart());
    const user = await getUser();
    dispatch(getUserSuccess(user));

    if (!user.hasApprovedTermsAndConditions) {
      const termsAndConditions = await getTermsAndConditions();
      dispatch(setTermsAndConditions(termsAndConditions));
    }
  } catch (err: any) {
    dispatch(getUserFailure(err.toString()));
  }
};

export const fetchUsers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getUsersStart());
    const users = await getUsers();
    dispatch(getUsersSuccess(users));
  } catch (err: any) {
    if (err?.response?.status === 500) {
      dispatch(getUsersFailure('Something went wrong'));
    } else {
      dispatch(getUsersFailure(err.toString()));
    }
  }
};

export const fetchRoles = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getRolesStart);
    const roles = await getRoles();
    dispatch(getRolesSuccess(roles));
  } catch (err: any) {}
};

export const fetchUserStatistics =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addUserStatisticsLoading(userId));
      const statistics = await getStatisticsAPI({ userInstanceId: userId });
      dispatch(setUserStatistic({ userId, stats: statistics }));
      dispatch(removeUserStatisticsLoading(userId));
    } catch (err: any) {}
  };

// Dates: YYYY-MM-DD
export const fetchStatistics =
  (
    userId: string,
    { toDate, fromDate, events, userInstanceId }: StatisticParams
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addUserStatisticsLoading(userId));
      const statistics = await getStatisticsAPI({
        toDate,
        fromDate,
        events,
        userInstanceId: userId,
      });
      dispatch(setUserStatistic({ userId, stats: statistics }));
      dispatch(removeUserStatisticsLoading(userId));
    } catch (err: any) {}
  };

export const fetchTermsAndConditions = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getTermsAndConditionsStart());
    const termsAndConditions = await getTermsAndConditions();
    dispatch(setTermsAndConditions(termsAndConditions));
  } catch (err: any) {
    dispatch(getUserFailure(err.toString()));
  }
};

export const approveTermsAndConditions =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const termsAndConditions = getState().user
        .termsAndConditions as TermsAndConditions;
      await approveTermsAndConditionsAPI(termsAndConditions.id);
      // Refresh page to fetch data normally
      window.location.href = window.location.href + '';
    } catch (err: any) {
      dispatch(getUserFailure(err.toString()));
    }
  };

export const inviteUser =
  (
    userToInvite: InviteUser,
    onComplete: (success: boolean) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setInviteUserLoading(true));
      const invitedUser = await inviteUserAPI(userToInvite);
      // Fix while backend returns inconsitent case for roles
      invitedUser.roles = invitedUser.roles.map((role) =>
        role.toLowerCase()
      ) as InstanceRole[];

      dispatch(setInviteUserLoading(false));
      dispatch(addUserToUsers(invitedUser));
      onComplete(true);
    } catch (err: any) {
      dispatch(setInviteUserFailure(err.toString()));
      onComplete(false);
    }
  };

export const resendInviteUser =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(resendUserInviteStart());
      await resendUserInviteAPI(userId);
      dispatch(resendUserInviteSuccess());
    } catch (err: any) {
      dispatch(resendUserInviteFail());
    }
  };

export const updateUser =
  (userToUpdate: InviteUser): AppThunk =>
  async (dispatch) => {
    try {
      const updateUser = await inviteUserAPI(userToUpdate);
      // Fix while backend returns inconsitent case for roles
      updateUser.roles = updateUser.roles.map((role) =>
        role.toLowerCase()
      ) as InstanceRole[];
      dispatch(updateUserRoles(updateUser));
    } catch (err: any) {
      console.log(err);
    }
  };

export const deleteUser =
  (userExternalId: string): AppThunk =>
  async (dispatch) => {
    try {
      await deleteUserAPI(userExternalId);
      dispatch(updateDeletedUser(userExternalId));
    } catch (err: any) {
      console.log(err);
    }
  };

export const getProfileData = (): AppThunk => async (dispatch) => {
  try {
    const honorifics = await getHonorifics();
    const roles = await getUserProfileRoles();
    const profileData = { honorifics, roles };
    dispatch(addProfileData(profileData));
  } catch (err: any) {
    console.error(err);
  }
};

export const updateProfile =
  (profileData: MyUserProfile, externalId: string = ''): AppThunk =>
  async (dispatch) => {
    try {
      const newProfileData = await updateProfileAPI(profileData, externalId);
      externalId
        ? dispatch(setCurrentUserProfile(newProfileData))
        : dispatch(setUserProfileData(newProfileData));
    } catch (err: any) {
      console.error(err);
    }
  };

export const fetchUserProfile =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setCurrentUserProfileLoading(true));
      const userProfile = await getUserProfilAPI(id);
      dispatch(setCurrentUserProfile(userProfile));
      const fetchIsDone = userProfile === getState().user.currentUserProfile;

      if (fetchIsDone) {
        dispatch(setCurrentUserProfileLoading(false));
      }
      //setTimeout(function(){dispatch(setCurrentUserProfileLoading(false)); }, 1000);
    } catch (err: any) {
      if (err.response?.status === 404) {
        dispatch(setCurrentUserProfile(null));
        dispatch(setCurrentUserProfileLoading(false));
      }
    }
  };

const selectUserState = (rootState: RootState) => rootState.user;

export const profileDataHonorificsSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.profileData.honorifics
);

export const profileDataRolesSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.profileData.roles
);

export const userSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.user
);

export const usersSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.users
);

export const usersIsLoadingSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.usersLoading
);

export const usersErrorSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.usersError
);

export const termsAndConditionsSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.termsAndConditions
);

export const termsAndConditionsIsLoadingSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.termsAndConditionsLoading
);

export const rolesSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.roles
);

export const rolesIsLoadingSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.rolesLoading
);

export const inviteUserIsLoadingSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.inviteUserLoading
);

export const resendInviteLoadingSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.resendInviteLoading
);

export const inviteUserErrorSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.inviteUserError
);

export const showProfileSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.openProfile
);

export const myUserProfileSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.user?.userProfile
);

export const currentUserProfileSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.currentUserProfile
);

export const currentUserProfileLoadingSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.currentUserProfileLoading
);

export const userStatsLoadingSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.userStatisticsLoading
);

export const userStatisticsSelector = createSelector(
  selectUserState,
  (userState: UserState) => userState.userStatistics
);
