import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import CourseIcon from '../icons/CourseIcon';
import ToWatchCourseIcon from '../icons/ToWatchCourseIcon';
import { generatePath, Link, useHistory } from 'react-router-dom';
import {
  courseResultsSelector,
  courseTypesSelector,
  currentCourseSelector,
  resultStateSelector,
  sessionResultsSelector,
} from '../../store/selectors';
import { useSelector } from 'react-redux';
import {
  getCourseUrl,
  getSessionUrl,
  getUserAreaPageUrl,
  homeUrl,
} from '../../routes/paths';
import Message from '../translation/Message';
import MaterialIcon from '../icons/MaterialIcon';
import BackButton from '../BackButton';
import DrawerLogo from '../layouts/DrawerLogo';
import { userAreaSelector } from '../../store/userAreaSlice';
import { currentGroupSelector } from '../../store/groupSlice';
import { useEffect, useMemo } from 'react';
import { SimplifiedSession } from '../../types';
import WatchedCourseIcon from '../icons/WatchedCourseIcon';
import { userSelector } from '../../store/userSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBackArea: {
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      whiteSpace: 'normal',
      backgroundColor: theme.palette.grey[100],
    },
    drawerHeader: {
      paddingTop: '8px',
      display: 'block',
      '& > *': {
        padding: 'unset',
      },
    },
    drawerIcon: {
      minWidth: 'unset',
      paddingRight: theme.spacing(1),
    },
    drawerSessionIcon: {
      alignSelf: 'flex-start',
      minWidth: 'unset',
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
  })
);

const getCourseTypeIcon = (iconName: string) => {
  if (iconName === 'Default') {
    return <CourseIcon color="primary" />;
  } else {
    return (
      <MaterialIcon
        fontSize="small"
        iconName={iconName.split('Icon')[0]}
        color="primary"
      />
    );
  }
};

type SessionListItemInfo = {
  session: SimplifiedSession;
  disabled: boolean;
  passed: boolean;
  answerMandatory?: boolean;
};

const CourseDrawer = () => {
  const classes = useStyles();
  const course = useSelector(currentCourseSelector);
  const courseTypes = useSelector(courseTypesSelector);
  const userArea = useSelector(userAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const courseResults = useSelector(courseResultsSelector);
  const sessionResults = useSelector(sessionResultsSelector);
  const resultState = useSelector(resultStateSelector);
  const user = useSelector(userSelector);
  const history = useHistory();
  const theme = useTheme();
  const localStorage = window.localStorage;
  const BACK_PATH_KEY = 'backPath';

  let currentSession = course
    ? course.sessions.find((s) => window.location.pathname.includes(s.id))
    : null;

  let courseType = courseTypes
    ? courseTypes.find((ct) => ct.courseType.id === course?.courseType)
    : null;

  useEffect(() => {
    if (userArea) {
      localStorage.setItem(BACK_PATH_KEY, `${getUserAreaPageUrl(userArea.id)}`);
    } else if (currentGroup) {
      localStorage.setItem(
        BACK_PATH_KEY,
        `${getUserAreaPageUrl(currentGroup.id)}`
      );
    } else if (courseType) {
      localStorage.setItem(
        BACK_PATH_KEY,
        `/masterclasses/${courseType.courseType.name}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, userArea, currentGroup]);

  const sessionList = useMemo(() => {
    const orderSessionPassed = (
      simplifiedSession: SimplifiedSession | undefined
    ) => {
      if (course && courseResults[course.id] && simplifiedSession?.id) {
        const session = course.sessions.find(
          (s) => s.id === simplifiedSession.id
        );
        if (session) {
          const result = courseResults[course.id].sessionResults.find(
            (res) => res.sessionId === session.id
          );
          if (result) {
            const filterFreeText = result.answerResults.filter(
              (res) => res.answerType === 'FreeText'
            );
            const onlyFreeText =
              filterFreeText.length === result.answerResults.length;

            let passed = false;
            if (result.pass) {
              passed = result.pass;
            } else if (filterFreeText.length !== 0 && onlyFreeText) {
              passed = true;
            } else {
              const filterPoints = filterFreeText
                .map((res) => res.maxPoints)
                .reduce((sum, curr) => sum + curr, 0);

              const minPoints = session.minimumPoints
                ? session.minimumPoints
                : result.maxPoints;

              const pointsCheck =
                minPoints - filterPoints <= result.totalPoints - filterPoints;

              passed = pointsCheck;
            }
            return passed;
          }
        }
      }
    };
    if (!course || !courseResults[course.id]) {
      return null;
    }
    const grouped = course.sessionOrder?.map((simplifiedSession, index) => {
      let passed = false;
      let disabled = true;

      const prevPassed = orderSessionPassed(course.sessionOrder?.[index - 1]);
      const currPassed = orderSessionPassed(course.sessionOrder?.[index]);

      if (prevPassed !== undefined) {
        disabled = !prevPassed;
        passed = !disabled && (currPassed ?? false);
      } else {
        disabled = false;
        passed = currPassed ?? false;
      }

      const session = course.sessions.find(
        (s) => s.id === simplifiedSession.id
      );

      let mandatoryToAnswer = session?.mandatoryToAnswer ?? true;

      return {
        disabled,
        passed,
        session: simplifiedSession,
        mandatoryToAnswer,
      };
    });
    return grouped ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, courseResults, sessionResults, user]);

  const onBackButton = () => {
    const backPath = localStorage.getItem(BACK_PATH_KEY);
    if (backPath) {
      localStorage.removeItem(BACK_PATH_KEY);
      // history.push(backPath);
      history.goBack();
    } else {
      history.push(generatePath(homeUrl));
    }
  };

  return (
    <nav className={classes.drawerContent}>
      <div>
        <DrawerLogo drawerOpen />
        <Box className={classes.drawerBackArea}>
          <BackButton onClick={onBackButton} messageId={'course.back'} />
        </Box>
        <Divider />
        <List disablePadding>
          <ListItem
            button
            component={Link}
            to={`${getCourseUrl(course?.slug || '')}`}
            className={classes.drawerHeader}
            style={
              currentSession
                ? {}
                : { backgroundColor: theme.palette.secondary.main }
            }
          >
            <ListItem>
              <ListItemIcon className={classes.drawerIcon} color="primary">
                {getCourseTypeIcon(courseType?.courseType.icon || 'Default')}
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={
                  <Typography variant="button" color="primary">
                    {courseType?.courseType.name || (
                      <Message id="course.course" />
                    )}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle2">{course?.name}</Typography>
                }
              />
            </ListItem>
          </ListItem>
          <Divider />

          {course &&
            sessionList?.map(
              ({
                disabled,
                session,
                passed,
                answerMandatory,
              }: SessionListItemInfo) => {
                return (
                  <ListItem
                    button
                    disabled={disabled && answerMandatory}
                    component={Link}
                    to={`${getSessionUrl(course.slug, session.id)}`}
                    key={session.id}
                    style={
                      currentSession && session.id === currentSession.id
                        ? { backgroundColor: theme.palette.secondary.main }
                        : {}
                    }
                  >
                    <ListItemIcon className={classes.drawerSessionIcon}>
                      {passed ? (
                        <WatchedCourseIcon color="primary" />
                      ) : (
                        <ToWatchCourseIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={session.name} />
                  </ListItem>
                );
              }
            )}
          {course && course?.sessions.length === 1 && !sessionList && (
            <ListItem
              button
              disabled={false}
              component={Link}
              to={`${getSessionUrl(course.slug, course.sessions[0].id)}`}
              key={course.sessions[0].id}
              style={
                currentSession && course.sessions[0].id === currentSession.id
                  ? { backgroundColor: theme.palette.secondary.main }
                  : {}
              }
            >
              <ListItemIcon className={classes.drawerSessionIcon}>
                <WatchedCourseIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={course.sessions[0].name} />
            </ListItem>
          )}
        </List>
      </div>
    </nav>
  );
};

export default CourseDrawer;
