import React, { useState, useContext } from 'react';
import {
  Box,
  Tabs,
  AppBar,
  Tab,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridEditCellValueParams,
  GridPageChangeParams,
} from '@material-ui/data-grid';
import {
  WhitelabelContext,
  defaultMessagess,
  defaultMessagessSwe,
  getMessage,
} from '../whitelabel-config/WhitelabelProvider';
import { addStaticContentMessage } from './../api/instance-api';
import Message from './translation/Message';
import PageOrSectionHeader from './typography/PageOrSectionHeader';
import DataGridHeader from './DataGridHeader';
import { useSelector } from 'react-redux';
import { instanceSelector } from '../store/instanceSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saveBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    messageGrid: {
      height: 630,
    },
  })
);

type MessageTable = {
  id: string;
  value: string;
  customMessage: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const MessageConfig = () => {
  const { messages, mergeStaticContent } = useContext(WhitelabelContext);
  const [tabValue, setTabValue] = useState<number>(0);
  const [editState, setEditState] = useState<Record<string, string>>({});
  const [page, setPage] = useState<number>(0);
  const instance = useSelector(instanceSelector);

  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handlePageChange = (params: GridPageChangeParams) => {
    setPage(params.page);
  };

  const handleEditCellChange = ({
    id,
    field,
    value,
  }: GridEditCellValueParams) => {
    if (field === 'customMessage') {
      setEditState({ ...editState, [id + '']: value + '' });
    }
  };

  const handleSaveNewMessages = async () => {
    Promise.all(
      Object.entries(editState).map(([key, value]: [string, any]) =>
        addStaticContentMessage(key, value).then((allResponses) => {
          mergeStaticContent(
            allResponses,
            instance?.instanceLanguage ?? 'English'
          );
        })
      )
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Key',
      renderHeader: () => <DataGridHeader text="Key" />,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Default Message',
      renderHeader: () => <DataGridHeader text="Default Message" />,
      flex: 1,
    },
    {
      field: 'customMessage',
      headerName: 'Custom Message',
      renderHeader: () => <DataGridHeader text="Custom Message" />,
      flex: 1,
      editable: true,
    },
  ];

  const reducer = (
    accumulator: MessageTable[],
    [key, value]: [string, any]
  ): MessageTable[] => {
    if (typeof value === 'string') {
      accumulator.push({
        id: key,
        value: value,
        customMessage: '',
      });
      return accumulator;
    }

    const nestedMessage = Object.entries(value).reduce<MessageTable[]>(
      reducer,
      [] as MessageTable[]
    );

    const nestedMessagesWithPrefix = nestedMessage.map((obj) => {
      obj.id = `${key}.${obj.id}`;
      return obj;
    });

    return accumulator.concat(nestedMessagesWithPrefix);
  };

  const defMessage =
    instance && instance.instanceLanguage === 'Svenska'
      ? defaultMessagessSwe
      : defaultMessagess;

  const rows = Object.entries(defMessage)
    .reduce<MessageTable[]>(reducer, [] as MessageTable[])
    .map((row) => {
      if (editState.hasOwnProperty(row.id)) {
        row.customMessage = editState[row.id];
      } else {
        row.customMessage =
          row.value !== getMessage(row.id) ? getMessage(row.id) : '';
      }
      return row;
    });

  return (
    <Box maxWidth={1300}>
      <PageOrSectionHeader>
        <Message id="siteSettings.messages" />
      </PageOrSectionHeader>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          variant="scrollable"
          onChange={handleTabChange}
          aria-label="message config"
        >
          {/* TODO: Kolla varför sendbird kommer in till messages, filtrerar bort för tillfället */}
          {Object.keys(messages)
            .filter((value) => value !== 'sendBird')
            .map((key, index) => (
              <Tab label={key} id={`tab-${index}`} key={index} />
            ))}
        </Tabs>
      </AppBar>
      {/* BELOW ARE ALL THE TABS */}
      <TabPanel value={tabValue} index={0}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('course.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('forum.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('userArea.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('profile.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('handleUser.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('handleActors.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('handleUserCourses.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('siteSettings.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={8}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('statistics.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={9}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('nav.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={10}>
        <DataGrid
          rows={rows.filter((obj) => obj.id.startsWith('chat.'))}
          columns={columns}
          pageSize={10}
          page={page}
          onPageChange={(params) => handlePageChange(params)}
          onCellValueChange={handleEditCellChange}
          className={classes.messageGrid}
        />
      </TabPanel>
      <Box className={classes.saveBtn}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveNewMessages}
        >
          <Message id="siteSettings.save" />
        </Button>
      </Box>
    </Box>
  );
};

export default MessageConfig;
