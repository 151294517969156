import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    makeStyles,
    createStyles,
    Theme,
    Typography,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editGroupAdmins } from "../../../api/user-api";
import ActorUserListWithSearchAndFilter from "../../../components/actors/ActorUserListWithSearchAndFilter";
import TextEllipsis from "../../../components/TextEllipsis";
import Message from "../../../components/translation/Message";
import { currentGroupMembersSelector, currentGroupSelector, fetchGroupMembers } from "../../../store/groupSlice";
import { checkedAddedMembersIdsSelector, setCheckedAddedMemberIds, toggleCheckedAddedMemberId } from "../../../store/userAreaSlice";
import { GroupMember } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        member: {
            backgroundColor: theme.palette.grey[100],
            borderRadius: theme.shape.borderRadius
        }
    })
);

type AddGroupAdminDialogProps = {
    isOpen: boolean,
    onClose: () => void,
}
const AddGroupAdminDialog = ({
    isOpen,
    onClose,
}: AddGroupAdminDialogProps) => {
    const [filteredGroupMembers, setFilteredGroupMembers] = useState<GroupMember[]>([]);

    const groupMembers = useSelector(currentGroupMembersSelector);
    const currentGroup = useSelector(currentGroupSelector);
    const checkedAddedMembersIds = useSelector(checkedAddedMembersIdsSelector);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (groupMembers) {
            setFilteredGroupMembers(groupMembers);
            const groupAdminIds = groupMembers.filter(m => m.isGroupAdmin).map(m => m.id);
            dispatch(setCheckedAddedMemberIds(groupAdminIds));
        }
    }, [dispatch, groupMembers]);

    const noChanges = (): boolean => {
        if (!groupMembers) return true;
        const groupAdmins = groupMembers.filter(m => m.isGroupAdmin);
        return groupAdmins.length === checkedAddedMembersIds.length
            && groupAdmins.every(ga => checkedAddedMembersIds.includes(ga.id));
    }

    const handleSetSearchAndFilter = (searchString: string, actorFilter: string | null) => {
        if (!groupMembers) return;
        const filteredByActor = actorFilter
            ? groupMembers.filter(member => member.actors.find(a => a.id === actorFilter))
            : groupMembers;

        const filteredBySearchAndActor = searchString
            ? filteredByActor.filter(member =>
                member.name.toLowerCase().includes(searchString.toLowerCase()) ||
                member.email.toLowerCase().includes(searchString.toLowerCase()))
            : filteredByActor;

        setFilteredGroupMembers(filteredBySearchAndActor);
    }

    const handleSave = () => {
        if (currentGroup && groupMembers) {
            editGroupAdmins(currentGroup.id, checkedAddedMembersIds)
                .then(() => {
                    enqueueSnackbar(<Message id="userArea.settings.addGroupAdminsSuccess" />, {
                        variant: 'success',
                    });
                    dispatch(fetchGroupMembers(currentGroup.id));
                })
                .catch((error) => {
                    enqueueSnackbar(<Message id="userArea.settings.addGroupAdminsFailed" />, {
                        variant: 'error',
                    });
                });
        }

        setFilteredGroupMembers(groupMembers || []);
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='md'
            fullWidth
        >
            <Box height='90vh' display='flex' flexDirection='column'>
                <DialogTitle>
                    <Message id='userArea.settings.addGroupAdminDialogTitle' />
                </DialogTitle>
                <DialogContent>
                    <Box pt={1}>
                        <ActorUserListWithSearchAndFilter
                            listComponent={ListItem}
                            listHeaderComponent={AddGroupAdminsListHeader}
                            data={filteredGroupMembers}
                            onSetSearchAndFilter={
                                (searchString: string, actorFilter: string | null) =>
                                    handleSetSearchAndFilter(searchString, actorFilter)
                            }
                            searchFieldOptions={groupMembers?.map(m => m.name)}
                            listItemsPerPage={20}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box p={2} display='flex'>
                        <Button
                            variant='contained'
                            onClick={onClose}
                        >
                            <Message id='handleActors.cancel' />
                        </Button>
                        <Box mr={2} />
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleSave}
                            disabled={noChanges()}
                        >
                            <Message id='userArea.save' />
                        </Button>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

type ListItemProps = {
    id: string,
}
const ListItem = ({
    id
}: ListItemProps) => {
    const groupMembers = useSelector(currentGroupMembersSelector);
    const checkedIds = useSelector(checkedAddedMembersIdsSelector);

    const classes = useStyles();
    const dispatch = useDispatch();

    const getGroupMember = () => {
        if (!groupMembers) return null;
        return groupMembers.find(gm => gm.id === id);
    }
    const groupMember = getGroupMember();

    const getIsChecked = () => {
        return checkedIds.includes(groupMember ? groupMember.id : '');
    }

    if (groupMember) {
        return (
            <Box>
                <Box
                    className={classes.member} width='100%' mb={1} p={1} px={2}
                    display='flex' alignItems='center'
                >
                    <TextEllipsis width={280}>
                        <Typography>{groupMember.name}</Typography>
                    </TextEllipsis>

                    <TextEllipsis width={336}>
                        <Typography>{groupMember.email}</Typography>
                    </TextEllipsis>

                    <Box width={224}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={getIsChecked()}
                                    onChange={(event, checked) => dispatch(toggleCheckedAddedMemberId({ id, checked }))}
                                    name='switch-is-group-admin'
                                    color='primary'
                                />
                            }
                            label={
                                <Typography>
                                    {getIsChecked()
                                        ? <Message id='userArea.admin' />
                                        : <Message id='userArea.notAdmin' />
                                    }
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    return <Box />;

}

const AddGroupAdminsListHeader = () => {
    return (
        <Box
            width='100%' px={2} mt={2} mb={1}
            display='flex' alignItems='center'
        >
            <Box width={280}>
                <Typography variant='subtitle2'>
                    <Message id='userArea.members.name' />
                </Typography>
            </Box>

            <Box width={336}>
                <Typography variant='subtitle2'>
                    <Message id='userArea.members.email' />
                </Typography>
            </Box>

            <Box width={224}>
                <Typography variant='subtitle2'>
                    <Message id='userArea.admin' />
                </Typography>
            </Box>
            <Divider />
        </Box>
    )
}

export default AddGroupAdminDialog;