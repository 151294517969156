import { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    makeStyles,
    createStyles,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    Theme,
    FormControl,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import BackIcon from '../../components/icons/BackIcon';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Message from '../translation/Message';
import PageOrSectionHeader from '../typography/PageOrSectionHeader';
import { forumCategoriesSelector } from '../../store/forumCategoriesSlice';
import { ForumCategory } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            whiteSpace: 'nowrap',
            marginRight: theme.spacing(2)
        }
    })
);

type ForumTopicHeaderProps = {
    headerText: string,
    linkTo: string,
    canEdit?: boolean,
    category?: ForumCategory,
    onSaveEdit?: ({ updatedSubject, updatedCategoryId }: { updatedSubject?: string, updatedCategoryId?: string }) => void
}
const ForumTopicHeader = ({
    headerText,
    linkTo,
    canEdit = false,
    category,
    onSaveEdit,
}: ForumTopicHeaderProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editedSubject, setEditedSubject] = useState<string>(headerText);
    const [editedCategory, setEditedCategory] = useState<string | undefined>(category?.id);
    const [subjectHelperText, setSubjectHelperText] = useState('');
    const [categoryHelperText, setCategoryHelperText] = useState('');

    const categories = useSelector(forumCategoriesSelector);
    const classes = useStyles();

    const isCategoryEmpty = () => {
        return (!editedCategory || editedCategory === '00000000-0000-0000-0000-000000000000');
    }

    const handleSave = () => {
        if (!onSaveEdit) return;

        if (editedSubject.length < 5) {
            setSubjectHelperText(getMessage('forum.createTopicPage.subjectHelper'));
        } else {
            setSubjectHelperText('');
        }

        if (isCategoryEmpty()) {
            setCategoryHelperText(getMessage('forum.createTopicPage.categoryHelper'));
        } else {
            setCategoryHelperText('');
        }

        if (
            editedSubject.length >= 5
            && !isCategoryEmpty()
        ) {
            onSaveEdit({
                updatedSubject: editedSubject,
                updatedCategoryId: editedCategory
            });
            setIsEditing(false);
        }
    }

    const handleReset = () => {
        setIsEditing(false);
        setEditedSubject(headerText);
        setEditedCategory(undefined);
        setSubjectHelperText('');
        setCategoryHelperText('');
    }

    const handleEditCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditedCategory(event.target.value as string);
    };

    return (
        <Box mb={2} width='100%' display="flex" alignItems="center">
            {!isEditing &&
                <Box display='flex' justifyContent='space-between' width='100%'>
                    <Box width='100%' display='flex' flexDirection='column'>
                        <Box width='100%' display='flex' alignItems='center'>
                            <IconButton component={Link} to={linkTo}>
                                <BackIcon />
                            </IconButton>
                            <PageOrSectionHeader noGutter>
                                {headerText}
                            </PageOrSectionHeader>
                        </Box>
                        {category &&
                            <Box ml={6}>
                                <Typography>{category.name}</Typography>
                            </Box>
                        }
                    </Box>
                    {canEdit && onSaveEdit &&
                        <Box display='flex' justifyContent='end' height='100%' mr={2}>
                            <IconButton onClick={() => setIsEditing(true)}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
            }

            {isEditing &&
                <Box width='100%'>
                    <PageOrSectionHeader>
                        <Message id='forum.topicPage.edit' />
                    </PageOrSectionHeader>
                    <Box pt={2} width='100%'>
                        <TextField
                            variant='outlined'
                            label={<Message id='forum.overviewPage.titleSubject' />}
                            required
                            fullWidth
                            value={editedSubject}
                            onChange={(event) => setEditedSubject(event.target.value)}
                        />
                        <Typography color='error' variant='caption'>
                            {subjectHelperText || ' '}
                        </Typography>

                        {categories && categories.length >= 2 && (
                            <Box pt={3}>
                                <FormControl variant="outlined" required>
                                    <InputLabel>
                                        <Message id="forum.overviewPage.titleCategory" />
                                    </InputLabel>
                                    <Box width='280px'>
                                        <Select
                                            label={getMessage("forum.overviewPage.titleCategory")}
                                            value={editedCategory}
                                            onChange={handleEditCategory}
                                            displayEmpty
                                            fullWidth
                                        >
                                            {categories?.map((category) => (
                                                < MenuItem key={category.id} value={category.id} >
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    <Typography color='error' variant='caption'>
                                        {categoryHelperText || ' '}
                                    </Typography>
                                </FormControl>
                            </Box>
                        )}

                        <Box pt={2} display='flex' width='100%' justifyContent='end'>
                            <Button
                                variant='contained'
                                className={classes.button}
                                onClick={handleReset}
                            >
                                <Message id='forum.topicPage.cancelEdit' />
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                onClick={handleSave}
                            >
                                <Message id="forum.topicPage.saveSubjectEdit" />
                            </Button>
                        </Box>
                    </Box>
                </Box >

            }
        </Box >
    )
}

export default ForumTopicHeader;
