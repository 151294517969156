import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CoursePreview,
  Course,
  CourseType,
  CourseTypes,
  VideoData,
  AnswerResult,
  UserAnswer,
  SimplifiedCourse,
  SessionResultDict,
  CourseResult,
  CourseResultDict,
} from '../types';
import { AppThunk } from './index';
import {
  getCoursePreviews,
  getPrivateCoursePreviews as getPrivateCoursePreviewsAPI,
  getCoursePreviewsByIds as getCoursePreviewsByIdsAPI,
  getCourse,
  getCourseFromSlug,
  getFeaturedCourses as getFeaturedCoursesAPI,
  getCourseTypes as getCourseTypesAPI,
  assignCourses as assignCoursesAPI,
  getTabVideos as getTabVideosAPI,
  addTabVideo as addTabVideoAPI,
  deleteTabVideo as deleteTabVideoAPI,
  publishTabVideo as publishTabVideoAPI,
  submitAnswers as submitAnswersAPI,
  fetchResultsBySession as fetchResultsBySessionAPI,
  fetchResultsByCourse as fetchResultsByCourseAPI,
  getAllExamCourses as getAllExamCoursesAPI,
  getAllCourses as getAllCoursesAPI,
} from '../api/course-api';

export interface CourseState {
  currentCourse: Course | null;
  featuredCourses: CoursePreview[];
  isLoading: boolean;
  error: string | null;
  courseTypes: CourseTypes[] | null;
  privateCoursePreviews: CoursePreview[] | null;
  privateVideos: VideoData[] | null;
  sessionResults: SessionResultDict;
  courseResults: CourseResultDict;
  simplifiedExamCourses: SimplifiedCourse[] | null;
  simplifiedCourses: SimplifiedCourse[] | null;
}

const initialState = {
  currentCourse: null,
  featuredCourses: [],
  isLoading: false,
  error: null,
  courseTypes: null,
  privateCoursePreviews: null,
  privateVideos: null,
  sessionResults: {},
  courseResults: {},
  simplifiedExamCourses: null,
  simplifiedCourses: null,
} as CourseState;

function startLoading(state: CourseState) {
  state.isLoading = true;
}

function stopLoading(state: CourseState) {
  state.isLoading = false;
}

function loadingFailed(state: CourseState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const coursesSlice = createSlice({
  name: 'course',
  initialState: initialState,
  reducers: {
    startImageUploading: startLoading,
    stopImageUploading: stopLoading,
    getCourseStart: startLoading,
    getPrivateCoursePreviewsStart: startLoading,
    getFeaturedCoursesStart: startLoading,
    assignCoursesStart: startLoading,
    getPrivateVideosStart: startLoading,
    submitAnswersStart: startLoading,
    fetchSessionResultsStart: startLoading,
    fetchAllExamCoursesStart: startLoading,
    fetchAllCoursesStart: startLoading,
    submitAnswers(state, action: PayloadAction<AnswerResult>) {
      const { sessionId } = action.payload;
      state.sessionResults[sessionId] = action.payload;
      state.isLoading = false;
    },
    deleteSessionResultStart: startLoading,
    deleteSessionResult(state, action: PayloadAction<string>) {
      delete state.sessionResults[action.payload];
      state.isLoading = false;
    },
    getCourseSuccess(state, action: PayloadAction<Course>) {
      state.currentCourse = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getPrivateCoursePreviewsSuccess(
      state,
      action: PayloadAction<CoursePreview[]>
    ) {
      state.privateCoursePreviews = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    resetPrivateCoursePreviewsSuccess(state) {
      state.privateCoursePreviews = null;
      state.error = null;
      state.isLoading = false;
    },
    getFeaturedCoursesSuccess(state, action: PayloadAction<CoursePreview[]>) {
      state.featuredCourses = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getPrivateVideosSuccess(state, action: PayloadAction<VideoData[]>) {
      state.privateVideos = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchSessionResults(state, action: PayloadAction<AnswerResult>) {
      const { sessionId } = action.payload;
      state.sessionResults[sessionId] = action.payload;
      state.isLoading = false;
    },
    fetchSessionResultsByCourse(state, action: PayloadAction<CourseResult>) {
      const { courseId } = action.payload;
      state.isLoading = false;
      state.courseResults[courseId] = action.payload;
    },
    fetchAllExamCourses(state, action: PayloadAction<SimplifiedCourse[]>) {
      state.isLoading = false;
      state.simplifiedExamCourses = action.payload;
    },
    fetchAllCourses(state, action: PayloadAction<SimplifiedCourse[]>) {
      state.isLoading = false;
      state.simplifiedCourses = action.payload;
    },
    fetchAllCoursesFailure: loadingFailed,
    fetchAllExamCoursesFailure: loadingFailed,
    getCoursePreviewsFailure: loadingFailed,
    getCourseFailure: loadingFailed,
    getFeaturedCoursesFailure: loadingFailed,
    getPrivateCoursePreviewsFailure: loadingFailed,
    assignCoursesFailure: loadingFailed,
    getVideosFailure: loadingFailed,
    submitAnswersFailed: loadingFailed,
    fetchSessionResultsFailed: loadingFailed,
    updateCurrentCourse(state, action: PayloadAction<Course>) {
      state.currentCourse = action.payload;
    },
    setFeaturedCourses(state, action: PayloadAction<CoursePreview[]>) {
      state.featuredCourses = action.payload;
    },
    setCourseTypes(state, action: PayloadAction<CourseType[]>) {
      const types = action.payload;
      if (!types) {
        return;
      }
      state.courseTypes = types.map((type) => {
        return {
          courseType: type,
          coursePreview: [],
          hasBeenFetched: false,
          isLoading: false,
        };
      });
    },
    getCourseTypesPreviews(
      state,
      action: PayloadAction<{
        coursePreview: CoursePreview[];
        courseTypeId: string;
      }>
    ) {
      const { coursePreview, courseTypeId } = action.payload;
      if (!state.courseTypes) {
        return;
      }
      state.courseTypes = state.courseTypes.map((type) => {
        if (type.courseType.id === courseTypeId) {
          type.coursePreview = coursePreview;
          type.hasBeenFetched = true;
          type.isLoading = false;
        }
        return type;
      });
    },
    setCourseTypesLoading(
      state,
      action: PayloadAction<{ isLoading: boolean; courseTypeId: string }>
    ) {
      const { isLoading, courseTypeId } = action.payload;
      if (!state.courseTypes) {
        return;
      }
      state.courseTypes = state.courseTypes.map((type) => {
        if (type.courseType.id === courseTypeId) {
          type.isLoading = isLoading;
        }
        return type;
      });
    },
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

export const {
  getCourseStart,
  getCourseSuccess,
  getCoursePreviewsFailure,
  getCourseFailure,
  getFeaturedCoursesStart,
  getFeaturedCoursesSuccess,
  getFeaturedCoursesFailure,
  getPrivateCoursePreviewsStart,
  getPrivateCoursePreviewsSuccess,
  getPrivateCoursePreviewsFailure,
  resetPrivateCoursePreviewsSuccess,
  getPrivateVideosSuccess,
  getPrivateVideosStart,
  getVideosFailure,
  assignCoursesStart,
  assignCoursesFailure,
  updateCurrentCourse,
  setFeaturedCourses,
  setCourseTypes,
  getCourseTypesPreviews,
  setCourseTypesLoading,
  submitAnswersStart,
  submitAnswers,
  submitAnswersFailed,
  deleteSessionResultStart,
  deleteSessionResult,
  fetchSessionResultsStart,
  fetchSessionResults,
  fetchSessionResultsFailed,
  startImageUploading,
  stopImageUploading,
  fetchAllExamCoursesStart,
  fetchAllExamCoursesFailure,
  fetchAllExamCourses,
  fetchAllCoursesStart,
  fetchAllCoursesFailure,
  fetchAllCourses,
  fetchSessionResultsByCourse,
} = coursesSlice.actions;

export default coursesSlice.reducer;

export const fetchCoursePreviews =
  (courseTypeId: string = ''): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (courseTypeId) {
        setCourseTypesLoading({ isLoading: true, courseTypeId });
        const subCoursePreviews = await getCoursePreviews(courseTypeId);
        dispatch(
          getCourseTypesPreviews({
            coursePreview: subCoursePreviews,
            courseTypeId,
          })
        );

        const currentFeatured = getState().courses.featuredCourses;
        let resultFeatured = [...currentFeatured];
        subCoursePreviews.forEach((preview) => {
          if (
            preview.featured &&
            !currentFeatured.find((p) => p.id === preview.id)
          ) {
            resultFeatured.push(preview);
          } else if (
            !preview.featured &&
            currentFeatured.find((p) => p.id === preview.id)
          ) {
            resultFeatured.filter((p) => p !== preview);
          }
        });
        dispatch(setFeaturedCourses(resultFeatured));
      }
    } catch (err: any) {
      dispatch(getCoursePreviewsFailure(err.toString()));
    }
  };

export const fetchFeaturedCourses = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getFeaturedCoursesStart());
    const featuredCourses = await getFeaturedCoursesAPI();
    dispatch(getFeaturedCoursesSuccess(featuredCourses));
    // dispatch(setFeaturedCourses(featuredCourses));
  } catch (err: any) {
    dispatch(getPrivateCoursePreviewsFailure(err.toString()));
  }
};

export const fetchPrivateCoursePreviews =
  (privateCourseUserId: string = ''): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPrivateCoursePreviewsStart());
      const coursePreviews =
        await getPrivateCoursePreviewsAPI(privateCourseUserId);
      dispatch(getPrivateCoursePreviewsSuccess(coursePreviews));
    } catch (err: any) {
      dispatch(getPrivateCoursePreviewsFailure(err.toString()));
    }
  };

export const fetchPrivateCoursePreviewsByIds =
  (courseIds: string[], groupId?: string): AppThunk =>
  async (dispatch) => {
    try {
      if (courseIds.length > 0) {
        dispatch(getPrivateCoursePreviewsStart());
        dispatch(resetPrivateCoursePreviewsSuccess());
        const coursePreviews = await getCoursePreviewsByIdsAPI(courseIds);
        dispatch(getPrivateCoursePreviewsSuccess(coursePreviews));
      } else {
        dispatch(getPrivateCoursePreviewsSuccess([]));
      }
    } catch (err: any) {
      dispatch(getPrivateCoursePreviewsFailure(err.toString()));
    }
  };

export const fetchTabVideos =
  (tabId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPrivateVideosStart());
      const videoData = await getTabVideosAPI(tabId);
      dispatch(getPrivateVideosSuccess(videoData));
    } catch (err: any) {
      dispatch(getVideosFailure(err.toString()));
    }
  };

export const addTabVideo =
  (video: File, groupOrPrivateUserId: string, tabId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPrivateVideosStart());
      await addTabVideoAPI(video, tabId, groupOrPrivateUserId);
      dispatch(fetchTabVideos(tabId));
    } catch (err: any) {
      dispatch(getVideosFailure(err.toString()));
    }
  };

export const deleteTabVideo =
  (videoId: string, privateUserId: string, tabId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPrivateVideosStart());
      await deleteTabVideoAPI(videoId, tabId);
      dispatch(fetchTabVideos(tabId));
    } catch (err: any) {
      dispatch(getVideosFailure(err.toString()));
    }
  };

export const publishTabVideo =
  (videoId: string, tabId: string, publish: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPrivateVideosStart());
      await publishTabVideoAPI({
        videoId,
        tabId,
        publish,
      });
      dispatch(fetchTabVideos(tabId));
    } catch (err: any) {
      dispatch(getVideosFailure(err.toString()));
    }
  };

export const resetPrivateCoursePreviews = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getPrivateCoursePreviewsStart());
    dispatch(resetPrivateCoursePreviewsSuccess());
  } catch (err: any) {
    dispatch(getPrivateCoursePreviewsFailure(err.toString()));
  }
};

export const fetchCourse =
  (courseId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCourseStart());
      const course = await getCourse(courseId);
      dispatch(getCourseSuccess(course));
    } catch (err: any) {
      dispatch(getCourseFailure(err.toString()));
    }
  };

export const fetchAllExamCoursesSimplified =
  (): AppThunk => async (dispatch) => {
    try {
      dispatch(fetchAllExamCoursesStart());
      const simplifiedCourses = await getAllExamCoursesAPI();
      dispatch(fetchAllExamCourses(simplifiedCourses));
    } catch (err: any) {
      dispatch(fetchAllExamCoursesFailure(err.toString()));
    }
  };

export const fetchAllCoursesSimplified = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchAllCoursesStart());
    const simplifiedCourses = await getAllCoursesAPI();
    dispatch(fetchAllCourses(simplifiedCourses));
  } catch (err: any) {
    dispatch(fetchAllCoursesFailure(err.toString()));
  }
};

export const fetchCourseFromSlug =
  (slug: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCourseStart());
      const course = await getCourseFromSlug(slug);
      dispatch(getCourseSuccess(course));
    } catch (err: any) {
      dispatch(getCourseFailure(err.toString()));
    }
  };

export const fetchCourseTypes = (): AppThunk => async (dispatch, getState) => {
  const activeModules = getState().instance.instance?.activeModules;
  const instanceIsLoading = getState().instance.updateInstanceLoading;
  if (
    !instanceIsLoading &&
    activeModules &&
    activeModules?.includes('Course')
  ) {
    try {
      const courseTypes = await getCourseTypesAPI();
      dispatch(setCourseTypes(courseTypes));
    } catch (err: any) {
      console.error(err);
    }
  }
};

export const assignCourses =
  (courseIds: string[], privateCourseUserId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(assignCoursesStart());
      await assignCoursesAPI(courseIds, privateCourseUserId);
      const coursePreviews =
        await getPrivateCoursePreviewsAPI(privateCourseUserId);
      dispatch(getPrivateCoursePreviewsSuccess(coursePreviews));
    } catch (err: any) {
      dispatch(assignCoursesFailure(err.toString()));
    }
  };

export const publishAnswers =
  (sessionId: string, userAnswers: UserAnswer[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(submitAnswersStart());
      const results = await submitAnswersAPI(sessionId, userAnswers);
      dispatch(submitAnswers(results));
    } catch (err: any) {
      dispatch(submitAnswersFailed(err.toString()));
    }
  };

export const deleteResult =
  (sessionId: string): AppThunk =>
  (dispatch) => {
    dispatch(deleteSessionResultStart());
    dispatch(deleteSessionResult(sessionId));
  };

export const fetchResultsByCourse =
  (courseId: string): AppThunk =>
  async (dispatch) => {
    try {
      const results = await fetchResultsByCourseAPI(courseId);
      if (results !== null) {
        dispatch(fetchSessionResultsByCourse(results));
      } else {
        dispatch(fetchSessionResultsFailed(''));
      }
    } catch (err: any) {
      dispatch(fetchSessionResultsFailed(err.toString()));
    }
  };

export const fetchResults =
  (sessionId: string): AppThunk =>
  async (dispatch) => {
    try {
      //dispatch(fetchSessionResultsStart());
      const results = await fetchResultsBySessionAPI(sessionId);
      if (results !== null) {
        dispatch(fetchSessionResults(results));
      } else {
        dispatch(
          fetchSessionResults({
            sessionId: sessionId,
            answerResults: [],
            sessionName: '',
            maxPoints: 0,
            totalPoints: 0,
          })
        );
      }
    } catch (err: any) {
      if (err instanceof Error) {
        if (err.message.includes('code 404')) {
          dispatch(
            fetchSessionResults({
              sessionId: sessionId,
              answerResults: [],
              sessionName: '',
              maxPoints: 0,
              totalPoints: 0,
            })
          );
          return;
        }
      }
      dispatch(fetchSessionResultsFailed(err.toString()));
    }
  };
