import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@material-ui/core";
import { useState } from "react";
import Message from "../../../components/translation/Message";

type AddTabDialogProps = {
    isOpen: boolean,
    onClose: () => void,
    onSave: (name: string) => void
}
const AddTabDialog = ({
    isOpen,
    onClose,
    onSave
}: AddTabDialogProps) => {
    const [newTabName, setNewTabName] = useState<string>('');

    const handleNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setNewTabName((event.target as HTMLInputElement).value);
    };

    const handleSave = () => {
        onSave(newTabName);
        setNewTabName('');
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='xs'
            fullWidth
        >
            <Box pt={1} />
            <DialogTitle>
                <Message id='userArea.settings.addTab' />
            </DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Typography variant='subtitle2'>
                        <Message id='handleActors.name' />
                    </Typography>
                    <TextField
                        autoFocus
                        margin='dense'
                        fullWidth
                        required
                        value={newTabName}
                        onChange={handleNameChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box p={2} display='flex'>
                    <Button
                        variant='contained'
                        onClick={onClose}
                    >
                        <Message id='handleActors.cancel' />
                    </Button>
                    <Box mr={2} />
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSave}
                    // disabled={!newActorName || noChanges()}
                    >
                        <Message id='handleActors.save' />
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default AddTabDialog;