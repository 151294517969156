import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { AnswerOption, QuestionAnswer } from '../../types';
import Message from '../translation/Message';
import EditIcon from '@material-ui/icons/Edit';
import { EditQuestionComponent } from './EditQuestionComponent';
import { QuestionComponent } from './QuestionComponent';
import { Add, DeleteForever } from '@material-ui/icons';
import { deleteUsersAnswer } from '../../api/course-api';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      width: '95%',
    },
    flexColumnCenter: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    documentDivider: {
      margin: theme.spacing(1, 0),
    },
  })
);

const emptyQuestion = (index: number): QuestionAnswer => {
  return {
    question: '',
    answerOptions: [],
    answerType: 'FreeText',
    index: index,
  };
};

type EditQuestionsProp = {
  questionsAnswers: QuestionAnswer[];
  sessionId: string;
  minimumPoints?: number;
  maximumPoints: number;
  handleQuestionsChange: (
    questions: QuestionAnswer[],
    minimumPoints: number
  ) => void;
};

export const EditQuestions = ({
  questionsAnswers,
  sessionId,
  minimumPoints,
  maximumPoints,
  handleQuestionsChange,
}: EditQuestionsProp) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [questions, setQuestions] =
    useState<QuestionAnswer[]>(questionsAnswers);
  const [minPoints, setMinPoints] = useState(minimumPoints);
  const [maxPoints, setMaxPoints] = useState(maximumPoints);

  const startEditing = () => setIsEditing(true);
  const saveEdit = () => {
    // Check empty question
    const filteredQuestions = questions.filter((q) => q.question !== '');
    // Check for empty answers
    const filteredAnswers = filteredQuestions.filter((q) => {
      if (q.answerType === 'FreeText') {
        return q.answerOptions.length === 0;
      }
      if (q.answerType === 'SingleChoice') {
        return q.answerOptions.filter((a) => hasAnswerCorrect(a)).length === 1;
      }
      if (q.answerType === 'MultipleChoice') {
        return q.answerOptions.filter((a) => hasAnswerCorrect(a)).length > 1;
      }
      return false;
    });
    setQuestions(filteredAnswers);
    setIsEditing(false);
    const sumFiltered = filteredAnswers
      .map(
        (ansOpt) => ansOpt.answerOptions.filter((opt) => opt.isCorrect).length
      )
      .reduce((sum, curr) => sum + curr, 0);

    const freeTextCount = filteredQuestions.filter(
      (qa) => qa.answerType === 'FreeText'
    ).length;
    setMaxPoints(sumFiltered + freeTextCount);
  };

  const hasAnswerCorrect = (option: AnswerOption) => {
    const hasText = option.text !== undefined && option.text !== '';
    const hasImage = option.image !== undefined && option.image !== '';
    return (hasText || hasImage) && option.isCorrect;
  };

  const handleSaveQuestions = (qAnswers: QuestionAnswer, index: number) => {
    let qs: QuestionAnswer[] = [];
    for (let i = 0; i < questions.length; i++) {
      if (i === index) {
        qs.push(qAnswers);
      } else {
        const obj = questions[i];
        qs.push(obj);
      }
    }
    setQuestions(qs);
  };

  useEffect(() => {
    if (!isEditing && mounted) {
      const mp =
        questions.length === 0 ? 0 : minPoints ? minPoints : questions.length;
      handleQuestionsChange(questions, mp);
    } else if (!mounted) {
      setMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    const points = questions
      .map((q) => getPoints(q))
      .reduce((sum, curr) => sum + curr, 0);
    if (minPoints && minPoints > questions.length) {
      setMinPoints(points);
    } else if ((!minPoints || minPoints === 0) && questions.length > 0) {
      setMinPoints(points);
    }
  }, [minPoints, questions]);

  const getPoints = (qa: QuestionAnswer) => {
    if (qa.answerType === 'Unknown') {
      return 0;
    }
    if (qa.answerType === 'MultipleChoice') {
      const p = qa.answerOptions.filter((opt) => opt.isCorrect === true).length;
      return p;
    }
    return 1;
  };

  const getMaxPoints = () => {
    return questions
      .map((qa) => getPoints(qa))
      .reduce((sum, curr) => sum + curr, 0);
  };

  const createNewQuestion = () => {
    if (!questions || questions.length === 0) {
      setQuestions([emptyQuestion(0)]);
      return;
    }
    let newQuestions = questions.map((q) => q);
    newQuestions.push(emptyQuestion(newQuestions.length));
    setQuestions(newQuestions);
  };

  const removeQuestion = async (index: number) => {
    const removed = questions.map((q) => q);
    removed.splice(index, 1);
    setQuestions(removed);
    await deleteUsersAnswer(sessionId, index);
  };

  const addQuestionButton = () => {
    return (
      <Typography
        className={classes.flexCenter}
        style={{ marginTop: 10 }}
        variant="subtitle1"
      >
        <IconButton onClick={createNewQuestion}>
          <Add fontSize="large" color="primary" />
        </IconButton>
        <Message id="course.courseAdmin.editCoursePage.addQuestion" />
      </Typography>
    );
  };

  const handleMinimumPointsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = Number.parseInt(event.target.value);
    if (val !== undefined && val >= 0 && val <= getMaxPoints()) {
      setMinPoints(val);
    }
  };

  const renderQuestions = () => {
    if (isEditing) {
      if (questions && questions.length > 0) {
        const questionButton = addQuestionButton();
        return (
          <div>
            {questions.map((q, i) => (
              <Box key={`eq_box_${i}`} className={classes.flexCenter}>
                <EditQuestionComponent
                  key={`eq_edit_{i}`}
                  index={i}
                  sessionId={sessionId}
                  saveQuestions={handleSaveQuestions}
                  questionAnswer={q}
                  addMaxPoints={(val) => setMaxPoints(maxPoints + val)}
                />
                <IconButton
                  key={`eq_iconbutton_${i}`}
                  onClick={() => {
                    removeQuestion(i);
                  }}
                >
                  <DeleteForever
                    key={`eq_deleticon_${i}`}
                    fontSize="large"
                    color="error"
                  />
                </IconButton>
              </Box>
            ))}
            {questionButton}
          </div>
        );
      } else {
        return addQuestionButton();
      }
    } else {
      if (questions && questions.length > 0) {
        return questions.map((q, i) => (
          <QuestionComponent
            key={i}
            questionAnswer={q}
            handleAnswer={() => {}}
            editable={true}
          />
        ));
      } else {
        return <div></div>;
      }
    }
  };

  const renderMinimumPoints = () => {
    if (isEditing) {
      if (questions.length === 0) {
        return (
          <Box mt={2} mb={2}>
            <Typography variant="subtitle2">
              {getMessage('course.courseAdmin.editCoursePage.minimumPoints')}
            </Typography>
            <Typography>
              {getMessage(
                'course.courseAdmin.editCoursePage.minimumPointsAddPrompt'
              )}
            </Typography>
          </Box>
        );
      } else {
        const mp = minPoints ? minPoints : 0;
        return (
          <Box mt={2} mb={2}>
            <Typography variant="subtitle2">
              {getMessage('course.courseAdmin.editCoursePage.minimumPoints')}
            </Typography>
            <Box mt={4} style={{ display: 'flex' }}>
              <TextField
                type="number"
                value={mp}
                style={{ width: 75 }}
                size="small"
                inputProps={{
                  style: { textAlign: 'center' },
                }}
                onChange={handleMinimumPointsChange}
              />
              <Typography variant="subtitle1" style={{ marginLeft: 10 }}>
                <Message id="course.courseAdmin.editCoursePage.points" />
              </Typography>
            </Box>
          </Box>
        );
      }
    } else {
      if (questions.length > 0) {
        const mp = minPoints ? minPoints : 0;
        return (
          <Box mt={2} mb={2}>
            <Typography variant="subtitle2">
              {getMessage('course.courseAdmin.editCoursePage.minimumPoints')}
            </Typography>
            <Typography variant="subtitle1">
              {mp} / {getMaxPoints()} (
              {((mp / getMaxPoints()) * 100).toFixed(1)}
              %)
            </Typography>
          </Box>
        );
      } else {
        <></>;
      }
    }
  };

  return (
    <Box mt={2}>
      <Typography variant="subtitle2">
        <Message id="course.courseAdmin.editCoursePage.questions" />
      </Typography>
      {renderQuestions()}
      {renderMinimumPoints()}
      <Box mt={4}>
        {isEditing ? (
          <Button variant="contained" color="primary" onClick={saveEdit}>
            <Message id="course.courseAdmin.editCoursePage.done" />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={startEditing}
          >
            <Message id="course.courseAdmin.editCoursePage.edit" />
          </Button>
        )}
      </Box>
    </Box>
  );
};
